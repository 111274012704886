body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

.mainGrid {
  margin-bottom: 0 !important;
}

div.dark-grey-bg {
  background-color: #d9d9d9;
}

div.medium-grey-bg {
  background-color: #efefef;
}

div.light-grey-bg {
  background-color: #f7f7f7;
}

div.light-blue-bg {
  background-color: #1ca6d3 !important;
}

div.light-red-bg {
  background-color: #dd6071 !important;
}

div.light-green-bg {
  background-color: #6ed992 !important;
}

button.care-green {
  background-color: #6ed992 !important;
}

.field.care-blue button,
button.care-blue,
a.ui.button.care-blue {
  background-color: #04a5d5 !important;
  color: #31435e !important;
  font-weight: 400 !important;
  letter-spacing: 0.1em;
}

.disabled.button.care-blue.save {
  box-shadow: inset 0px 0px 0px 1px #c9c9c9 !important;
  background-color: transparent !important;
  color: #9b9b9b !important;
  opacity: 1 !important;
}

.field.care-green button,
button.care-green {
  background-color: #69db8f !important;
  color: #417505 !important;
  font-weight: 400 !important;
  letter-spacing: 0.1em;
}

.ui.care-green.basic.button {
  color: #69db8f !important;
  font-weight: 400 !important;
  letter-spacing: 0.1em;
  border-color: #69db8f !important;
  box-shadow: 0 0 0 1px #69db8f inset!important
}

.disabled.button.care-green {
  /* background-color: #d9d9d9 !important;*/
  background-color: #e3e3e3 !important;
  /* color: #9b9b9b !important; */
  color: #4a4a4a !important;
  opacity: 1 !important;
  font-size: 14px;
}

.field.care-green button,
button.care-red {
  background-color: #dd6071 !important;
  color: #31435e !important;
  font-weight: 400 !important;
}

.disabled .field.care-green button,
.disabled button {
  color: rgba(40, 40, 40, 0.3) !important;
}

.overlay-pref-unchecked {
  background-color: #f16778 !important;
  color: #731924 !important;
  box-shadow: none !important;
}
.overlay-pref-checked {
  background-color: #69db8f !important;
  color: #417505 !important;
  box-shadow: none !important;
}

.icon-green {
  color: #69db8f !important;
}
.icon-red {
  color: #f16778 !important;
}
.icon-blue {
  color: #04a5d5 !important;
}
.icon-gray {
  color: #c8cbd3 !important;
}
.icon-pink {
  color: #fad1d6 !important;
}
.icon-orange {
  color: #f5a623 !important;
}
.icon-empty {
  color: #c8cbd3 !important;
}

.icon-charcoal {
  color: #4a4a4a !important;
}
.icon-right {
  float: right;
}

.bg-green {
  background-color: #69db8f !important;
}
.bg-red {
  background-color: #f16778 !important;
}
.bg-blue {
  background-color: #04a5d5 !important;
}
.bg-gray {
  background-color: #c8cbd3 !important;
}
.bg-pink {
  background-color: #fad1d6 !important;
}
.bg-orange {
  background-color: #f5a623 !important;
}

.bg-cloud {
  background-color: #f2f2f2 !important;
}
.border-blue {
  border-color: #04a5d5 !important;
}
.border-orange {
  border-color: #f5a623 !important;
}
.border-green {
  border-color: #69db8f !important;
}

.overlayLink {
  color: #04a5d5;
  font-weight: bold;
}
.ui.basic.segment {
  color: #4a4a4a;
  font-size: 12px;
}
.backTotext {
  font-weight: bold;
  font-size: 14px;
}

.disabled .overlayLink {
  color: rgba(40, 40, 40, 0.3) !important;
}
.overlayLink:hover {
  cursor: pointer;
}

.assigned-caregiver-shift {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 0.2fr;
  justify-content: space-between;
  align-items: center;
  justify-items: flex-start;
}

.overlayDirect .name {
  float: left;
  display: table;
  /* font-weight: bold; */
}

.overlayDirect .name .nameBlock {
  vertical-align: middle;
  display: table-cell;
  font-size: 12px;
}

.overlayDirect .name .nameBlock .online {
  font-size: 11px;
  font-family: Lato;
  color: #a9adb4;
  line-height: 1.4em;
  display: inline-block;
  position: relative;
  top: -0.5em;
}

.overlayDirect .name .nameBlock .now {
  border-radius: 1em;
  /* font-size: 0.85em; */
  font-size: 11px;
  font-weight: bold;
  padding: 0.1em 0.5em;
  background-color: #69db8f;
  color: white;
  top: -0.1em;
  text-align: center;
}
.overlayDirect .name .nameBlock .months {
  border-radius: 1em;
  padding: 0;
  color: #f16778;
  font-size: 11px;
  font-family: inherit;
  top: -0.4em;
}
.overlayDirect .name .nameBlock .hours {
  border-radius: 1em;
  padding: 0;
  color: #69db8f;
  font-size: 11px;
  font-family: inherit;
  top: -0.4em;
}
.overlayDirect .name .nameBlock .days {
  border-radius: 1em;
  padding: 0;
  color: #a9adb4;
  font-size: 11px;
  top: -0.4em;
}
.overlayDirect .name .nameBlock .longtime {
  border-radius: 1em;
  padding: 0;
  color: #a9adb4;
  font-size: 11px;
  top: -0.4em;
}
.overlayDirect .name img {
  margin-right: 1rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 2rem !important;
  height: 2rem !important;
  object-fit: cover;
}

.overlayDirect .icons {
  width: 100px;
  float: right;
}

.overlayDirect .icons div {
  float: right;
  width: 20px;
}

.overlayDirect .icons .badge {
  position: relative;
}

.overlayDirect .icons .badge i {
  position: absolute;
  top: -7px;
  left: -7px;
  z-index: 10;
}

.overlayDirect .icons div img {
  width: 16px;
}
.overlayDirect .icons div img:hover {
  cursor: pointer;
}
.overlayDirect .icons .tinyTopMargin {
  margin-top: 3px;
}
.overlayDirect .icons div i:hover {
  cursor: pointer;
}

.ui.item.menu > .item.text-align-left {
  text-align: left;
}

.ui.secondary.vertical.menu .item:hover {
  background: transparent !important;
}

.ui.secondary.vertical.menu .active.item {
  background: transparent !important;
}

.ui.item.menu > .item > .side-menu-item {
  font-weight: bold;
  font-size: 1em;
  color: #4a4a4a;
  text-align: left;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.8em;
  margin-top: 0.3em;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.side-menu-title {
  font-weight: normal;
  font-size: 0.83em;
  text-align: left;
  color: #a9adb4;
  margin-top: 1.5em;
  letter-spacing: 0.1em;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.side-menu-text {
  font-weight: normal;
  font-size: 1em;
  text-align: left;
  color: #a9adb4;
  margin-top: 1em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.menu-input-with-button {
  margin: 0 !important;
}

.menu-input-with-button .button {
  background-color: #04a5d5 !important;
  color: #01475c !important;
  padding-left: 0.5em;
  padding-right: 0.5em;
  width: 30px;
  height: 30px;
}

.menu-input-with-button .button:hover{
  background-color: #1293b9 !important;
  color: #073f50 !important;
}

.side-menu-slider-title {
  font-weight: bold;
  font-size: 0.8em;
  text-align: center;
  color: #4a4a4a;
  margin-top: 1em;
  margin-bottom: 1em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.round {
  position: relative;
  width: 100%;
  margin-left: 0.1em;
  cursor: pointer;
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.round label {
  background-color: rgba(123, 123, 123, 0.08);
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 20px;
  padding: 0 !important;
}

.round label:before {
  content: none !important;
}
.round label:after {
  border: 2px solid cadetblue;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px !important;
  left: 5px !important;
  opacity: 0;
  position: absolute;
  top: 6px !important;
  transform: rotate(-45deg);
  width: 10px !important;
  content: '';
}

.round input[type='checkbox'] {
  visibility: hidden !important;
}

.round input[type='checkbox']:checked + label {
  background-color: #69db8f;
  border-color: #69db8f;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1 !important;
  content: '' !important;
  color: #4a4a4a;
}

.round .dlabel {
  float: left;
  color: #9b9b9b;
  width: 80%;
  font-weight: normal;
  font-size: 0.8em;
}

.round .dbox {
  float: right;
}

.round .field {
  margin: 0.3em 0 !important;
  color: #9b9b9b;
  font-weight: bold;
}

.round .checked-field {
  margin: 0.3em 0 !important;
  color: #4a4a4a;
  font-weight: bold;
}

.filterMenu input[type='text'] {
  margin: 0.7em 0 !important;
}

.filterMenu .ui.dropdown {
  margin: 0.7em 0 !important;
}

.filterMenu .ui.dropdown > .text {
  margin-left: -1px;
}

.filterItem .ui.item.menu > .item {
  margin-left: -1px !important;
}

.filterMenu .react-datepicker-wrapper input {
  width: 100% !important;
}

.filterMenu .input-range {
  margin: 1em !important;
  width: auto !important;
}

.modalHeaderImages {
  position: relative;
  height: 110px !important;
}

.modalHeaderGrid {
  margin-bottom: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.modalHeaderGrid .header {
  font-size: 0.9em !important;
  font-weight: normal;
  color: #364866;
  letter-spacing: 0.2em;
  margin-bottom: 0em;
  text-transform: uppercase;
}

.modalHeaderGrid .id-text {
  font-weight: normal;
  font-size: 0.7em !important;
  color: #a9adb4;
}

.modalHeaderImages img:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
}

.modalHeaderGrid .headerPic {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.modalHeaderImages img:nth-child(2) {
  object-fit: cover;
  height: 80px !important;
  width: 80px !important;
  bottom: 10px;
}

.emptyStateRow {
  width: 100%;
  background-color: white;
  margin-top: -1rem;
  margin-right: 25px;
}

.emptyStateRow .ui.segment {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.emptyStateRow .ui.segment img {
  margin: 3rem auto;
  max-width: 50%;
}

.loadMoreRow {
  position: relative;
  width: 100%;
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  float: right;
}

.loadMoreRow button {
  position: absolute;
  left: 0;
  width: 100%;
  color: #04a5d5 !important;
  background-color: transparent !important;
  font-weight: normal !important;
  padding: 0 !important;
  z-index: 2;
}

.contentGrid {
  flex-wrap: nowrap !important;
  padding-left: 0 !important;
}

.contentGrid .menuCol {
  padding: 20px !important;
  padding-right: 0 !important;
  flex: 0 0 260px;
  min-width: 265px;
}

.contentGrid .menuCol .item {
  padding-top: 0 !important;
}

.contentGrid .leftColGrid {
  padding-right: 1.5em !important;
}

.contentGrid .leftColGrid .grid > .row {
  margin-bottom: 1em !important;
}

.contentGrid .rightColGrid {
  padding-left: 1.5em !important;
}

.headerWithCount div {
  padding-top: 0.2em !important;
  padding-right: 0 !important;
  float: left;
  font-size: 0.9em;
  color: grey;
}

.headerWithCount h3 {
  margin-right: 0.3em !important;
  margin-bottom: 0 !important;
  color: #364967 !important;
}

.textBoldTeal {
  color: #04a5d5;
  font-weight: 400;
}

.ui.accordion .title > .dropdown.icon::before {
  font-family: 'Icons';
  /* content: '\f067' !important; */
  margin-bottom: 0.3em !important;
  content: '+' !important;
  font-size: 18px;
}

.ui.accordion .active.title > .dropdown.icon::before {
  font-family: 'Icons';
  /* content: '\2759' !important; */
  margin-bottom: 0.3em !important;
  content: '|' !important;
  font-size: 12px;
  font-weight: bold;
}

.ui.dropdown > .dropdown.icon::before {
  font-family: 'Icons';
  content: '\f078' !important;
  color: #a9adb4;
}

.ui.dropdown .menu > .item {
  margin-left: -2px !important;
  font-size: 12px !important;
}

.ui.unselected.small.left.floated.header {
  color: #a9adb4;
}

.ui.selected.small.left.floated.header {
  color: #4a4a4a;
}

.input-range__slider {
  background: #04a5d5;
  border: 2px solid #f7f7f7;
  box-shadow: 1px 1px 1px #9b9b9b;
}

.input-range__track--active {
  background: #04a5d5;
}

.input-range__label-container {
  text-transform: uppercase;
}

.filterMenu .react-datepicker-wrapper input {
  background-image: url('../images/icon_calendar.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 5px top 5px !important;
}

.travelImage {
  height: 25px !important;
}

.ui.disabled.multiple.dropdown {
  opacity: 1;
}

.ui.disabled.multiple.dropdown .delete.icon {
  color: transparent;
}

.ui.label>.close.icon,
.ui.label>.delete.icon {
  color: #4a4a4a !important;
  opacity: 1;
  width: 9px !important;
  height: 9px !important;
  font-size: 10px !important;
  background-color: transparent !important;
  background-image: url('../images/close-icon.svg');
  border-radius: 50%;
  line-height: 0.8;
}

.ui.label>.close.icon:before,
.ui.label>.delete.icon:before {
  content: none !important;
} 

.client-preferences > .ui.label > .delete.icon {
  display: none !important;
}

.client-preferences > .ui.label {
  max-width: 380px;
}

.contentGrid .filterBar .filterTitle {
  color: #a6a6a6;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
}

.contentGrid .filterBar .filterTitle .image {
  padding: 0 7px 5px 0;
}

.filterItem .ui.item.menu > .item.text-align-left {
  padding: 5px 0 10px 0 !important;
}

.filterItem .ui.item.menu > .item > .side-menu-item {
  border: none !important;
  color: #9b9b9b;
}

.filterItem .ui.accordion.menu .item .active.title {
  color: #4a4a4a !important;
}

.filterItem .ui.item.menu > .item.text-align-left .ui.fluid.dropdown {
  margin: 0px 10px 20px 10px !important;
  width: auto;
  min-width: 230px !important ;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
}

.filterItem .ui.item.menu > .item.text-align-left .ui.fluid.dropdown .dropdown-suspended-employee {
  color: #DADADA!important;
  font-size: 17px;
}

.filterItem .ui.item.menu > .item.text-align-left .ui.fluid.dropdown >.default.text {
  margin-top: 5px !important;
}

.filterItem .ui.multiple.dropdown > .default.text {
  color: #9b9b9b !important;
  font-weight: normal !important;
  font-size: 12px !important;
}

.filterItem .ui.multiple.dropdown {
  background-color: #f7f7f7;
  font-size: 12px;
  color: #4a4a4a;
  margin: 0 10px 20px 10px !important;
  width: 100%;
}

.filterItem .ui.multiple.dropdown > .label {
  width: auto;
  max-width: 90%;
}

.filterItem .ui.dropdown .menu {
  background-color: #f7f7f7;
}

.filterItem .ui.dropdown .menu > .item,
.filterItem .ui.dropdown .menu > .selected.item {
  word-wrap: break-word;
}

.filterItem .ui.fluid.dropdown > .dropdown.icon {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 14% -10%);
}

.ui.form .field>.selection.dropdown>.dropdown.icon {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 14% -10%);
}

.ui.form .field>.disabled.selection.dropdown>.dropdown.icon {
  box-shadow: none;
}

.ui.fluid.dropdown {
  min-height: 33px !important;
}

.field.care-grey button,
.button.care-grey {
  background-color: #e3e3e3 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #4a4a4a !important;
  padding: 12px 35px !important;
  border-radius: 8px;
}

.field.care-grey-active button,
.button.care-grey-active {
  background-color: #04a5d5 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #01475c !important;
  padding: 12px 35px !important;
  border-radius: 8px;
}

.field.care-grey-active button:hover,
.button.care-grey-active:hover {
  background-color: #1293b9 !important;
  color: #073f50 !important;
}

.filterMenu .round input[type='checkbox']:checked + label {
  height: 15px !important;
  width: 15px !important;
}

.filterMenu .round label {
  height: 15px !important;
  width: 15px !important;
}

.filterMenu label::after {
  position: absolute !important;
  width: 8px !important;
  height: 4px !important;
  top: 0.35em !important;
  left: 0.25em !important;
}

.filterMenu .round .field,
.filterMenu .round .checked-field {
  margin: 0.3em !important;
}

.filterSideMenu {
  margin-top: 39px !important;
}

#shiftsSearchDateStart,
#shiftsSearchDateEnd {
  font-size: 11px;
  box-shadow: 0px 1px 0px #c9c9c9 !important;
  border: none;
}

.ui.mini.button.care-green {
  font-size: 14px;
  font-weight: normal !important;
  color: #4a4a4a !important;
  padding: 12px 35px !important;
}
