
body {
    letter-spacing: 0.05em;
}

body.scrolling.dimmable.dimmed,
body.dimmable.dimmed {
    overflow: hidden !important;
}

table {
  transform-style: preserve-3d;
  margin-top: 0!important;
  font-size: 0.8em!important;
  border-top: 2px!important;
}

thead {
    background-color: #f7f7f7;
    z-index: 2;
}

.noPadding {
  padding: 0!important;
  margin: 0!important;
}

.noVerticalPadding {
    padding-top: 0!important;
    margin-top: 0!important;
    padding-bottom: 0!important;
    margin-bottom: 0!important;
}

.scrollLoader {
    width: 100%;
    height: 1px;
    overflow: visible;
    position: relative;
}

.tableContainer {
    width: 100%;
    clip-path: inset(0 0 0 0 round 6px 0 0 0);
}

.horizontal-scrolling::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

.horizontal-scrolling::-webkit-scrollbar-thumb {
    border: 0px solid rgba(0, 0, 0, 0.222);
}

.client-agency-avatarWithName {
    display: table;
    width: 250px;
    min-height: 40px;
    margin: 10px auto;
    font-size: 12px;
    color: #464247;
    font-weight: normal;
    box-shadow: 0px 0px 2px #9b9b9b;
    text-align: left !important;
}

.client-agency-avatarWithName .ui.image {
    margin-top: 5px !important;
}

.avatarWithName {
    display: table;
}

.avatarWithName img {
    margin: 0!important;
    margin-right: 1em!important;
    width: 2.5em!important;
}

.avatarWithName span {
  vertical-align: middle;
  display: table-cell;
}

.ui.table thead th {
    background-color: #efefef;
    font-size:12px;
    color:#4A4A4A; 
    font-weight: normal;
}


.ui.multiple.dropdown > .label {
    margin-right: 2px !important;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ui.dropdown:not(.button)>.default.text {
    margin-right: 20px !important;
    margin-bottom: 0 !important;
    margin-top: 2px !important;
}

.ui .dropdown > .text {
    margin-right: 20px !important;
}

.ui.table tr {
    height: 50px; 
}

.ui.sortable.table thead th.sorted {
    background-color: #d9d9d9;
    font-weight: bold
}


.blueLargeCircle {
    width: 100px;
    height: 100px;
    padding: 0!important;
    border: 0!important;
    background-color: #CDEDF7!important;
    display: block!important;
    box-shadow: none !important;
}

.blueLargeCircleText {
    vertical-align: middle;
    line-height: 100px;
    font-weight: bold;
    font-size: 1.5em;
    color:#04A5D5;
}

.redLargeCircle {
    width: 100px;
    height: 100px;
    padding: 0!important;
    border: 0!important;
    background-color: #FCE1E4!important;
    display: block!important;
    box-shadow: none !important;
}

.dashboard-welcome{
    opacity: 0;
    animation: fadeIn 500ms 50ms ease-out forwards;
}

.dashboard-add-button-with-label-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 100ms ease-out forwards;
}

.menuCol-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 200ms ease-out forwards;
}

.circleContainer-fade-in,
.caregiverStatistic-header-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 300ms ease-out forwards;
}

.dashboard-shifts-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 400ms ease-out forwards;
}

.dashboard-on-hold-shifts-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 450ms ease-out forwards;
}


.caregivers-overview-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 500ms ease-out forwards;
}

.hr-dashboard-circlefirst{
    opacity: 0;
    animation: fadeIn 1s 700ms ease-out forwards;
}

.hr-dashboard-circlesecond{
    opacity: 0;
    animation: fadeIn 1s 800ms ease-out forwards;
}

.hr-dashboard-circlethird{
    opacity: 0;
    animation: fadeIn 1s 900ms ease-out forwards;
}

.blueLargeCircle-fade-in{
    opacity: 0;
    animation: fadeIn 1s 900ms ease-out forwards;
}

.redLargeCircle-fade-in{
    opacity: 0;
    animation: fadeIn 1s 1000ms ease-out forwards;
}

.hr-dashboard-circlefourth{
    opacity: 0;
    animation: fadeIn 1s 1000ms ease-out forwards;
}

.calendar-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 1100ms ease-out forwards;
}

.activation-list-fade-in{
    opacity: 0;
    animation: fadeIn 500ms 1200ms ease-out forwards;
}


@keyframes fadeIn {
    0%      {transform: translate(2px, 20px); opacity: 0;}
    100%    {transform: translate(0, 0); opacity: 1;}
}

.redLargeCircleText {
    vertical-align: middle;
    line-height: 100px;
    font-weight: bold;
    font-size: 1.5em;
    color:#F16778;
}

.greenLargeCircle {
    width: 100px;
    height: 100px;
    padding: 0!important;
    border: 0!important;
    background-color: rgb(225, 252, 225)!important;
    display: block!important;
    box-shadow: none !important;
}

.greenLargeCircleText {
    vertical-align: middle;
    line-height: 100px;
    font-weight: bold;
    font-size: 1.5em;
    color:rgb(103, 241, 103);
}

.ui.active.button.careTab {
    background: #04A5D5 !important;
    color: #364967;
    border-radius: 20px !important;
  }

.ui.button.careTab {
    background:#f2f2f2 !important;
    color: #A9ADB4;
    border-radius: 20px !important;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.12rem;
}

.ui.tiny.buttons.careTabs {
    background:#f2f2f2 !important;
    border-radius: 20px !important;
    margin-bottom: 1rem;
}

.ui.table {
    font-size: 0.8em;
    border-radius: 5px !important;
}

.birthdayInput {
    font-size: 1.0rem!important;
}

.field.timeInput {
    width: 80px;
}

.search-input-with-button .button {
  background-color: #04A5D5;
  color: #31435E!important;
  padding-inline-start: 0.5em;
  padding-inline-end: 0.5em;
  width: 30px;
  height: 30px;
}

.search-input-with-button .button:disabled {
    background-color: #D8D8D8;
    color: #9B9B9B!important;
  }

#caregiversSearchName, #shiftsSearchName, #clientsSearchName {
    border-radius: 50px;
}

.stickyHeader th {
    position: sticky;
    top: 0;
    z-index: 10;
  }

.stickyHeader th:hover {
    background-color: #9B9B9B!important;
    background-image: none!important;
}

.add-button-with-label {
    padding-right: 15px;
}

.add-button-with-label span:first-child {
    box-sizing: border-box;
    color: #9B9B9B;
    padding-right: 0.8rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.add-button-with-label div {
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 2px;
    display: inline-block;
}

.care-round-button {
    display:inline-block;
    width:30px;
    height:30px;
    line-height:30px;
    border-radius: 50%;
    color:#364866;
    text-align:center;
    text-decoration:none;
    background-color: #04A5D5;
    font-size:20px;
    font-weight:bold;
    cursor: pointer;
    vertical-align: sub;
   
}

.careEditorTabContainer {
    z-index: 1!important;
}

.care-editor-header-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 15px;
}

.care-editor-header-container .ui.tiny.header.care-editor-header {
    align-self:flex-start;
    width: calc(100% - 15px);
    margin-top: 0;
    margin-right: 15px;
}

.ui.tiny.header.care-editor-header {
    color: #364866;
}

#TileGrid-column-left {
    max-width: 560px;
    padding-right: 10px;
}

#TileGrid-column-right {
    max-width: 560px;;
    padding-left: 10px;
}


.react-datepicker {
    border-radius: 6px;
    border: 1px solid #364866;
}

.react-datepicker__header {
    background-color: #364866;
    border-bottom: 1px solid #364866;
    border-radius: 5px;
}

.react-datepicker__current-month {
    font-family: Lato, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode',
    Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 500;
}

.react-datepicker__day-name {
    color: #364866;
    font-weight: normal;
}

.react-datepicker__day-name:nth-of-type(1)::after{
    position: relative;
    content: 'S';
    color: #617593;
    right: 7px;
}

.react-datepicker__day-name:nth-of-type(2)::after{
    position: relative;
    content: 'M';
    color: #617593;
    right: 10px;
}

.react-datepicker__day-name:nth-of-type(3)::after{
    position: relative;
    content: 'T';
    color: #617593;
    right: 5px;
}

.react-datepicker__day-name:nth-of-type(4)::after{
    position: relative;
    content: 'W';
    color: #617593;
    right: 10px;
}

.react-datepicker__day-name:nth-of-type(5)::after{
    position: relative;
    content: 'T';
    color: #617593;
    right: 5px;
}

.react-datepicker__day-name:nth-of-type(6)::after{
    position: relative;
    content: 'F';
    color: #617593;
    right: 5px;
}

.react-datepicker__day-name:nth-of-type(7)::after{
    position: relative;
    content: 'S';
    color: #617593;
    right: 7px;
}

.react-datepicker__month-container,
.react-datepicker__month {
    background-color: #364866;
}

.react-datepicker__month-container {
    border-radius: 5px;
}

.react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #04a5d5;
}

.react-datepicker__day {
    font-family: Lato, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode',
    Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: normal;
}

.react-datepicker__navigation--previous { 
    transform: rotate(-45deg);
    border: 1px solid #ffffff;
    clip-path: circle(50% at 50% 50%);
    box-shadow: inset 1.5px 1.5px  #ffffff, inset 3px 3px #9b9b9b, inset 9px 9px #ffffff;
}

.react-datepicker__navigation--previous:hover {
    transform: rotate(-45deg);
    border: 1px solid #9b9b9b;
    clip-path: circle(50% at 50% 50%);
    box-shadow: inset 1.5px 1.5px  #9b9b9b, inset 3px 3px #3e3e3e, inset 9px 9px #9b9b9b;
}

.react-datepicker__navigation--next {
    transform: rotate(135deg);
    border: 1px solid #ffffff;
    clip-path: circle(50% at 50% 50%);
    box-shadow: inset 1.5px 1.5px  #ffffff, inset 3px 3px #9b9b9b, inset 9px 9px #ffffff;
}

.react-datepicker__navigation--next:hover {
    transform: rotate(135deg);
    border: 1px solid #9b9b9b;
    clip-path: circle(50% at 50% 50%);
    box-shadow: inset 1.5px 1.5px  #9b9b9b, inset 3px 3px #3e3e3e, inset 9px 9px #9b9b9b;
}

.react-datepicker__day:hover {
    background-color: #25334d;
    border-radius: 50%;
}

.react-datepicker__day--outside-month {
    color: #617593;
    font-weight: normal;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #364866;
    border-top-color:#364866;
}
.react-datepicker-popper {
    z-index: 100;
}

.modals.dimmer .ui.scrolling.modal {
    margin: auto !important;
}

.ui.fullscreen.modal {
    top: 10vh;
}
