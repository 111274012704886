.fullPageContentGrid {
  padding: 15px 0 0 2vw  !important;
  margin: 0 !important;
}

.fullPageContentGrid .headerColumn {
  width: 15em !important;
}

.fullPageContentGrid .tabHeader {
  font-size: 0.9em;
  font-weight: normal;
  color: gray;
  padding-top: 0.9em;
  padding-bottom: 1.7em;
  width: 100%;
}

.fullPageContentGrid .searchForm {
  padding-top: 0.2em;
  padding-bottom: 0.9em;
  width: 100%;
}

.fullPageContentGrid .infoText {
  font-size: 1em;
  font-weight: normal;
  color: gray;
}

.fullPageContentGrid .infoTextBold {
  font-size: 1em;
  font-weight: bold;
  color: #364967;
}

.table.candidatesResults td {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.table.candidatesResults {
  min-width: 100em;
  width: 94.5vw;
}

.table.candidatesResults thead tr:first-child > th:first-child {
  text-align: left !important;
}

.fullPageContentGrid .greenText {
  color: #69db8f;
}

.fullPageContentGrid .redText {
  color: #f16778;
}

.fullPageContentGrid .noResults {
  padding-top: 2em;
  justify-content: center;
}

.ui.grid.hr-table.noResults {
  width: auto;
}

.ui.grid.hr-table.noResults > div {
  margin-left: 1rem;
  margin-top: 2px;
  padding: 2rem;
  width: fit-content;
  justify-content: center;
  position: relative;
}

.fullPageContentGrid .loadMore {
  position: absolute;
  left: 0;
  width: 100%;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

#hr-caregivers-tab{
  position: relative !important;
  right: 1.5vw !important;
}

#hr-caregivers-tab .ui.bottom.attached.segment.tab{
  width: 100% !important;
  margin: 0 !important;
}

#hr-caregivers-tab .ui.tabular.menu {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#hr-caregivers-tab .ui.segment,
#hr-caregivers-tab .ui.attached.tabular.menu {
  border: none !important;
}

#hr-caregivers-tab .ui.tabular.menu .item{
  border-radius: 0 !important;
  padding: 21.5px 19.5px !important;
  border-top: none !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

#hr-caregivers-tab .ui.tabular.menu .item:first-of-type {
  border-top-left-radius: 5px !important;
  border-left: none !important;
}

#HR-caregiver-head {
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.5fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-items: center;
}

#HR-caregiver-head > h3 {
  position: relative;
  justify-self: flex-start;
  top: 23px;
}

#HR-caregiver-head > span {
  position: relative;
  justify-self: flex-end;
  top: 20px;
}

#HR-caregiver-head > form > div {
  top: 25px;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-items: center;
}

#HR-caregiver-head #clientsSearchPrimaryContactsManagerDropdown .default.text,
#HR-caregiver-head #clientsSearchPrimaryContactsDropdown .default.text {
  font-size: 10px;
  position: relative;
  top: 3px;
  margin: 0 0 2px 0 !important;
}