.import-header-container {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 20px;
  max-width: 920px;
}

.file-type-container {
  justify-self: flex-end;
  margin-right: 20px;
}

.import-disabled-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  color: #6e86b3be;
  text-align: center;
  text-decoration: none;
  background-color: #46badda9;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  vertical-align: sub;
  pointer-events: none;
}

.imports-empty-state {
  width: 100%;
  height: 300px;
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-content: center;
}

.import-footer {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}

.import-footer-results {
  display: grid;
  grid-template-columns: auto 360px 20px 360px;
  padding: 10px;
  background-color: #e3e3e3;
}

.import-footer-results > span {
  width: 1px;
  height: 25px;
  background-color: #9b9b9b;
  justify-self: center;
  align-self: center;
}

.import-footer-results > h4 {
  font-size: 12px;
  margin-right: 10px;
  align-self: center;
  margin: 0;
}

.import-footer-results > p,
.import-footer-continue > p {
  font-size: 10px;
  margin: 0;
  align-self: center;
  justify-self: center;
}

.import-footer-continue {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 10px;
  background-color: #9b9b9b;
  padding: 10px;
  max-width: 300px;
}

.imports-invalid-records-text {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.imports-invalid-records-text > p {
  color: #f16778;
  margin: 0 10px 0 0;
}
