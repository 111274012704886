.careEditorTabs ul {
    background-color:#00000000;
    padding-left: 0;
    margin-bottom: 0;
    z-index: 1;
    white-space: nowrap;
}

.careEditorTabs span {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 0.688em;
    position: absolute;
    top: -25px;
    display: block;
    width: 7rem;
    left: -3rem;
}

.careEditorTabs li {
    width: 0.688em;
    height: 0.688em;
    text-align: center;
    line-height: 0.688em;
    border-radius: 0.344em;
    background: #06a5d5;
    margin: 0 3.625em;
    display: inline-block;
    color: #06a5d5;
    position: relative;
  }
  
  .careEditorTabs li::before{
    content: '';
    position: absolute;
    top: 0.282em;
    left: -7.250em;
    width: 7.250em;
    height: .124em;
    background: #06a5d5;
  }
  
  .careEditorTabs li:first-child::before {
    display: none;
  }
  
  .careEditorTabs .active {
    background: #06a5d5;
    color: #06a5d5;
  }
  
  .careEditorTabs  .active ~ li {
    background: #d9d9d9;
    color: #d9d9d9;
  }
  
  .careEditorTabs  .active ~ li::before {
    background: #d9d9d9;
    color: #d9d9d9;
  }