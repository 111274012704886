.table-horizontal-scroll-container {
  width: 90vw;
  border-radius: 5px;
}

.table-horizontal-scroll-container::-webkit-scrollbar-thumb {
  border: 8px solid rgba(0, 0, 0, 0.222);
}

.ui.fluid.selection.dropdown.elearning-reports-user-search-dropdown,
.ui.fluid.search.selection.dropdown.elearning-reports-user-search-dropdown {
  border-radius: 15px;
  min-height: 30px;
}

.ui.fluid.selection.dropdown.elearning-reports-user-search-dropdown {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.ui.fluid.selection.dropdown.elearning-reports-user-search-dropdown > i.delete.icon.clear {
  color: #a9adb4;
  position: relative;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 1em;
  height: 1em;
  place-self: center;
}

.certificate-date-picker-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
}

.certificate-exports-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  align-items: flex-end;
  float: right;
}

.certificate-date-container {
  width: 150px;
  text-align: center;
}

.certificate-date-container > p {
  color: #4a4a4a;
  font-size: 12px;
}

.certificate-form-container {
  width: 150px;
  text-align: center;
}

.certificate-button-container {
  width: 50px;
  text-align: center;
  display: grid;
  align-content: flex-end;
}

.e-learning-user-filter-button {
  display: initial;
}