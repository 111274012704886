.dashboard .menu {
}

.dashboard .title.side-menu-item {
  color: #4a4a4a !important;
  padding-top: 2em !important;
}

.dashboard .title.side-menu-item:first-child {
  padding-top: 0em !important;
}

.dashboard .welcomeRow {
  max-height: 82px;
  font-weight: bold;
  font-size: 1.1em;
  border-bottom: 1px #d9d9d9 solid;
  color: #a9adb4 !important;
  padding: 2em 1em !important;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.dashboard .welcomeRow .name {
  font-weight: bold;
  font-size: 1.1em;
  color: #364967 !important;
  text-transform: uppercase;
}

.dashboard .subHeaderCols .ui.header {
  font-weight: normal !important;
  font-size: 16px !important;
  color: #4a4a4a !important;
  padding-left: 1em;
  padding-top: 1em;
}

.dashboard .subHeaderCols .ui.header .sub.header {
  padding: 0.3em;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 12px !important;
  color: #a9adb4;
  font-weight: normal;
}

.dashboard .subHeaderCols .ui.header .sub.header .ui.horizontal.list .item {
  font-size: 12px;
}
.statisticGrid {
  background-color: #fff;
  margin-left: 1em !important;
  margin-bottom: 1em !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px #00000022;
}

.statisticGrid .ui.header {
  font-weight: bold !important;
  font-size: 14px !important;
  color: #364967 !important;
}

.statisticGrid .column {
  padding: 2em !important;
}

.statisticGrid .column p {
  color: #4a4a4a;
  font-weight: 'regular';
  font-size: 12px;
}

.shiftList {
  background-color: #fff;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 0.5em !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px #00000022;
}

.shiftList .headerRow {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.shiftList .headerRow .ui.header {
  color: #364967;
  padding-top: 1em;
  padding-left: 1em;
  font-size: 14px !important;
  font-weight: normal;
}

.shiftList .headerRow .sub.header {
  padding-top: 0.5em;
  padding-bottom: 1em;
  font-size: 12px !important;
  color: #9b9b9b;
}

.shiftList .headerRow.highUrgency .ui.header {
  color: #f16778 !important;
}

.shiftList .highUrgency {
  border-top: 2px solid #f16778 !important;
}

.shiftList .corner.label {
  border-top-color: #f16778 !important;
}

.shiftList .itemRow {
  border-bottom: 1px solid #f5f5ef !important;
}

.shiftList .itemRow .icon {
  margin-left: 1em;
}

.shiftList .itemRow .date-time-divider {
  color: grey;
}

.shiftList .itemRow:last-child {
  border-bottom: none !important;
}

.shiftList .footerRow {
  font-size: 0.75em;
  color: #9b9b9b;
}

.shiftList .footerRow button {
  margin-left: 1em;
}

.shiftList .ui.left.corner.label:after {
  border-top-width: 2em !important;
  border-right-width: 2em !important;
}

.shiftList .corner:after {
  border-top: 4em solid transparent;
  border-right: 4em solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 0 solid transparent;
  border-top-color: inherit;
}

.dashboardEmptyStateRow {
  margin-top: 0 !important;
}

.dashboardEmptyStateRow .ui.segment {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.dashboardEmptyStateRow .ui.segment .ui.header {
  color: #a9adb4;
  font-size: 12px;
  font-weight: normal;
}

.dashboardEmptyStateRow .ui.segment .ui.header.coffee {
  color: #a9adb4;
  font-size: 14px;
  font-weight: bold;
}

.filterWithGo .menu-input-with-button {
  flex-wrap: nowrap !important;
}

.filterWithGo input[type='text'] {
  width: 100% !important;
}

.filterWithGo {
  margin-bottom: 30px;
}

.filterWithGo .field {
  padding-right: 0.5em !important;
}

.filterWithGo .field:last-child {
  padding-right: 0 !important;
  margin-left: 0.1em !important;
}

.menu-input-with-button .button {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
  background-color: #04a5d5 !important;
  color: #01475c !important;
  width: 30px;
  height: 30px;
}

.menu-input-with-button .button:hover {
  background-color: #1293b9 !important;
  color: #073f50 !important;
}

.menu-input-with-button .ui.input input {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px !important;
  height: 30px;
}

#dashboardSearchCaregiverSubmit {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}

#dashboardSearchClientSubmit {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}

#Dashboard-on-hold-icon {
  margin-left: 10px;
}

#dashboard-column {
  box-shadow: 0px 28px #f8f8f8, 0px -14px #f8f8f8, 1px -15px #e3e3e3, 1px 28px #e3e3e3 !important;
}

#activation-list-divider {
  position: relative;
  left: -24px;
  width: calc(100% + 48px) !important;
  min-width: 333px;
}

.add-button-with-label {
  padding-right: 15px;
}

.dashboard-add-button-with-label span:first-child {
  position: relative;
  top: 2px;
  text-transform: capitalize !important;
  font-weight: normal;
  font-size: 14px !important;
  box-sizing: border-box !important;
  color: #9b9b9b !important;
  padding-right: 0.8rem !important;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.dashboard-add-button-with-label div {
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 2px;
  display: inline-block;
}

.app-update-available-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #4a4a4a;
  padding: 1rem;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10000;
}

.app-update-available-title {
  font-family: Lato !important;
  font-weight: bold;
  font-size: 0.875em;
  margin-left: 0.5rem;
  margin-top: auto !important;
  margin-bottom: auto !important;
  color: #ffffff;
  cursor: default;
}

.app-update-available-refresh {
  font-family: Lato !important;
  font-size: 0.875em;
  color: #ffffff;
  text-decoration: underline;
  text-underline-position: under;
  margin-left: 1.5rem;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.app-update-available-refresh:hover {
  cursor: pointer;
  opacity: 0.5;
}

.dashboard .hr-disabled-dimmer {
  width: 80%;
  height: 480px;
  background: #f7f7f7;
  position: fixed;
  top: 195px;
}

.dashboard .hr-disabled-dimmer p {
  color: #4a4a4a;
}
