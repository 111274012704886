.eSignTextCenter {
  text-align: center;
}

.eSignatureGrid {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.templateContainer {
  width: 600px;
  height: 180px;
  overflow: auto;
  background-color: #F7F7F7;
  border-radius: 5px;
}

.signContainer {
  padding: 25px 20px 0px 25px;
  margin: 10px 0 10px 0;
  background-color: #F7F7F7;
}

.gridTwoRowsOneColumn {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
}

.signatureImage {
  height: 140px;
  justify-self: center;
  margin: 0; 
}

.signatureLine {
  width: 100%;
  background-color: #c4c4c4;
  height: 1px;
  position: relative;
  top: -20px;
}

.signatureButtonsContainer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 250px;
  place-content: center;
  margin: 25px;
}

.drawSignatureButtonsContainer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 50px;
}
