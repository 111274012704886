body > div > div.login-form {
  height: 100vh;
  margin-bottom: 0 !important;

  & > div {
    height: 100%;
  }

  .ui.segment.contentBox {
    border: 0;
    box-shadow: 0 2px 4px grey !important;
    border-radius: 8px;
    overflow: hidden;
    min-height: 360px;
  }

  .ui.segment.termsBox {
    border: 0;
    box-shadow: 0 2px 4px grey !important;
    border-radius: 8px;
    overflow: hidden;
    min-height: 540px;
    height: 100%;
  }

  #wizardParent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    & > div {
      position: relative;
      z-index: 2;
    }
  }

  .grid {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .ui.basic.segment.error {
    padding: 0 !important;
    color: red;
  }

  .actionArea {
    min-height: 200px;
    max-width: 400px;
    margin: auto;
  }

  .ui.header {
    letter-spacing: 0.2em;
    margin-top: 0.8em !important;
    color: #364866 !important;
    margin-bottom: 0px;
  }

  .prompt {
    color: #364866 !important;
  }

  .ui.header img {
    width: 100% !important;
  }

  .ui.segment {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    button {
      padding: 1.2em 4em !important;
    }

    button.react-datepicker__navigation {
      padding: 0px !important;
    }

    img {
      width: 100% !important;
      margin-bottom: -1em !important;
      border-radius: 8px;
      z-index: 1;
    }

    .recoverySent {
      width: 150px !important;
      margin-left: auto;
      margin-right: auto;
    }

    img.contentFooter {
      position: absolute;
      bottom: 0px;
      margin-bottom: 0px !important;
    }
  }

  .ui.form input {
    letter-spacing: 0.12em !important;
    border: 0 !important;
    background-color: #f9f9f9 !important;
    text-align: center !important;
    box-shadow: 0 1px #d8d8d8 !important;
    border-radius: 4px !important;
  }

  #userBirthDate {
    text-align: center;
  }

  .forgetPassword:hover {
    cursor: pointer;
  }

  .forgetPassword {
    margin: 0;
  }

  .footer-page,
  .footer {
    padding: 0 !important;
  }

  .footer {
    position: absolute;
    bottom: 0;
    max-height: 100px;
  }

  .ui.selection.dropdown .menu {
    max-height: 400% !important;
  }
}

.footer-page-image {
  min-width: 100vw;
  position: absolute;
  bottom: 0;
  z-index: -100;
}
.magicLinkBox {
  border: 0;
  box-shadow: 0 2px 4px grey;
  border-radius: 8px;
  overflow: hidden;
  min-height: 360px;
  display: grid;
  grid-template-rows: repeat(5, auto);
  place-items: center;
}

.magicLinkImageFooter {
  margin-bottom: 0px !important;
  align-self: flex-end;
}

.info-emphasis-header {
  color: #1f2b41;
  font-size: 24px;
}

#login-tab .ui.tabular.menu {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#login-tab .ui.segment,
#login-tab .ui.attached.tabular.menu {
  border: none !important;
}

#login-tab .ui.tabular.menu .item {
  border-radius: 0 !important;
  padding: 21.5px 10px !important;
  width: 50%;
  display: block;
  border-top: none !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

#login-tab .ui.tabular.menu .item:first-of-type {
  border-top-left-radius: 5px !important;
  border-left: none !important;
}

#login-tab .ui.tabular.menu .active.item {
  color: #364967 !important;
}
