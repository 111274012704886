.confirmDialog .header {
  height: 65px;
  border: none !important;
}

.confirmDialog .ui.divider {
  margin: 0 20px;
}

.confirmDialog .ui.basic.segment {
  font-size: 14px;
  text-align: center;
  padding: 0 14px;
  font-weight: bold;
  margin-top: 20px;
}
.confirmDialog .actions {
  border: none !important;
}

.confirmDialog .ui.form textarea {
  background: #ededed;
  height: 110px !important;
  border: none;
  color: #4a4a4a;
  font-size: 14px;
}

.confirmHeader h2.ui.header {
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  margin-top: 5px;
}

.confirmDialog button.cancelBlue {
  background: none;
  font-weight: normal;
  color: #04a5d5;
  margin-top: 20px;
  margin-bottom: 10px;
}

.confirmDialog button.cancelBlue:hover {
  background: none;
}

.confirmDialog button.care-green:hover {
  color: #000008 !important;
}

.document-name-container {
  max-width: 150px;
}

.document-name {
  word-break: break-word;
}
