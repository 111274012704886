.agency-tab-container {
  width: 345px;
  position: relative;
  right: 45px;
  display: grid;
  grid-template-rows: repeat(auto-fit, auto);
  grid-template-columns: 149px 1fr;
  grid-template-areas:
    'prCont prCont'
    'actDate date'
    'dateDivider dateDivider'
    'exclusionH cgExclusion'
    'exclDivider exclDivider'
    'cgHistory cgHistory'
    'cgHText cgHText'
    'caregivers caregivers';
}

.agency-tab-container > .agency-tab-primary-contact {
  grid-area: prCont;
}

.agency-tab-container > .date-header {
  grid-area: actDate;
  align-self: center;
  justify-self: flex-start;
}

.agency-tab-container > .date {
  grid-area: date;
  align-self: center;
  justify-self: flex-start;
}

.agency-tab-container > .divider-date {
  grid-area: dateDivider;
}

.agency-tab-container > .exclusion-header {
  grid-area: exclusionH;
  align-self: flex-start;
  justify-self: flex-start;
  margin: 0;
}

.agency-tab-container > .exclusions {
  margin: 1rem 0 1rem 0;
  grid-area: cgExclusion;
  align-self: center;
  justify-self: flex-start;
  margin: 0;
}

.agency-tab-container > .divider-exclusion {
  grid-area: exclDivider;
}

.agency-tab-container > .big-header {
  font-size: 14px;
  color: #4a4a4a;
  margin-left: 1rem;
  grid-area: cgHistory;
}

.agency-tab-container > .history-text {
  padding-bottom: 1.5em;
  font-size: 12px;
  margin-left: 1rem;
  color: #a9adb4;
  grid-area: cgHText;
}

.agency-tab-container > .caregiverHistory {
  grid-area: caregivers;
}

.agency-tab-container > .tiny-header {
  font-weight: normal;
  font-size: 10px;
  color: #9b9b9b;
  margin: 1rem 0 0 1rem;
}

.agency-tab-container > .agency-tab-text {
  font-weight: normal;
  font-size: 12px;
  color: #4a4a4a;
  margin: 1rem 0 0 1rem;
}

.agency-tab-divider {
  width: 345px;
  background-color: #9b9b9b33;
  grid-area: division;
  margin: 1rem 0 0 0 !important;
  border: none !important;
  height: 1px;
}
