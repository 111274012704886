.shiftBuilder .contentGrid .menuCol {
  padding-top: 1em !important;
}

.shiftBuilder h3.ui.header {
  margin: 0 0 2em 2em;
  font-size: 16px;
  font-weight: bold;
}

.shiftBuilder .ui.header {
  color: #364967 !important;
}
.shiftBuilder .ui.header .h3 .title {
  font-size: 16px;
  font-weight: bold;
}

.shiftBuilder h4.ui.header {
  font-size: 14px;
  font-weight: bold;
}

.shiftBuilder .eight.wide.widescreen.column.client {
  margin-bottom: 3em;
}

.shiftBuilder .clientColumn {
  background-color: white;
  margin-left: 0em;
  margin-right: 0em;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
}

.shiftBuilder .shiftColumn {
  background-color: white;
  margin-left: 0em;
  margin-right: 0em;
  /* margin-left: 4em;
  margin-right: 4em; */
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
}

.shiftBuilder .headerRow {
   /* margin: 1em 2em; */
   margin: 0.3em 2em;
}

.shiftBuilder .shiftColumn .headerRow {
  /* margin: 1em 2em; */
  max-height: 53px;
  margin-bottom: 0;
}

.shiftBuilder .headerRow .column {
  padding: 0 !important;
}

.shiftBuilder .clientColumn .headerRow {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.shiftBuilder .clientSearchResults .avatar {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
}

.shiftBuilder .clientSearchResults {
  color: #9b9b9b;
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.shiftBuilder .clientSearchResults .subHeader {
  color: #4a4a4a;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 12px;
}

.shiftBuilder .clientSearchResults .clientName {
  font-size: 1.4em;
  color: #4a4a4a;
  letter-spacing: 0.1em;
  font-weight: 700;
}

.shiftBuilder .clientSearchResults .clearClient {
  position: absolute;
  right: 1rem;
}

.shiftBuilder .clientSearchResults .mapPin .img {
  height: 20px;
}

.shiftBuilder .add-button-with-label {
  padding-right: 0;
  vertical-align: middle;
}
.shiftBuilder .clientsearch {
  font-size: 12px;
  font-weight: bold;
}
.shiftBuilder .search {
  margin-left: -5px;
}
.shiftBuilder .shiftInformationEditor {
  margin: auto;
  margin-bottom: 1em !important;
  width: 500px !important;
  background: #f7f7f7;
  color: #4a4a4a;
  border-radius: 10px;
  justify-items: center;
  position: relative;
  bottom: 4px;
}

.shiftBuilder .shiftInformationEditor .ui.form {
  width: 100%;
}

.shiftBuilder .shiftInformationEditor label {
  color: #4a4a4a !important;
}

.ui.form .field > label {
  font-size: 12px;
  font-weight: bold;
}

#shiftStartDate {
  color: #4a4a4a;
  font-size: 11px;
}

#shiftHours {
  color: #4a4a4a;
  font-size: 12px;
}

.shiftBuilder .shiftInformationEditor .react-datepicker-wrapper {
  width: 100px !important;
}

.shiftBuilder .shiftInformationEditor .shiftHours {
  width: 80px !important;
  font-size: 12px !important;
  color: #417505;
}

.shiftBuilder .shiftInformationEditor .dropdown {
  /* font-size: 0.83em !important; */
  font-size: 12px !important;
}

.shiftBuilder .shiftInformationEditor .dropdown .menu {
  min-width: 140px !important;
}

.shiftBuilder .shiftInformationEditor .dropdown > .dropdown.icon {
  border-left: 1px solid #9b9b9b !important;
  margin-top: -5px !important;
  padding-top: 5px !important;
  padding-bottom: 4px !important;
  position: absolute;
}



/*
.ui.fluid.dropdown > .dropdown::selection {
  color: #69db8f;
}

.shiftBuilder .shiftInformationEditor .dropdown ::selection {
  color: #69db8f;
} */
.shiftBuilder .shiftInformationEditor span.hours {
  vertical-align: middle;
  font-size: 12px;
  color: #4a4a4a;
}

.shiftBuilder .shiftInformationEditor .fields.timeGroup {
  margin: auto;
  width: 300px !important;
  height: 50px;
}
.shiftBuilder .shiftInformationEditor .fields.timeGroup > .ui.fluid.search.selection.dropdown {
  max-height: 33px !important;
  height: 33px;
}

.shiftBuilder .shiftInformationEditor .row.checkbox {
  padding-top: 0;
}

.shiftBuilder .shiftInformationEditor .checkbox div {
  height: 30px;
  width: 30px;
  margin: auto;
}

.shiftBuilder .shiftInformationEditor .checkbox .button {
  background-color: #69db8f;
  color: #417505;
  text-align: center;
  position: relative;
  left: -5px;
}

.shiftBuilder .shiftInformationEditor .checkbox .ui.circular.button>.icon {
  position: relative;
  bottom: 3px;
  left: 12px;
}

.shiftBuilder .shiftInformationEditor .checkbox .disabled.button {
  background-color: #d9d9d9 !important;
  color: #9b9b9b !important;
  opacity: 1 !important;
  text-align: center;
}

.shiftBuilder .shiftInformationEditor .timeSeparator {
  margin: 2em 0.5em 0 0.5em;
}

.shiftBuilder .shiftInformationEditor .floatTopRightShift {
  position: absolute;
  top: -10px;
  right: -24px;
}

.shiftBuilder .shiftInformationEditor .ui.image.tinyCloseButton:hover {
  cursor: pointer;
}

.shiftBuilder .shiftEditorCollapsed {
  width: 100%;
  margin: 0;
}

.shiftBuilder .shiftEditorCollapsed .collapsedShift {
  margin: 0;
  color: #9b9b9b;
}

.shiftBuilder .shiftEditorCollapsed .collapsedShift .column {
  margin: 0;
  padding: 0;
}

.shiftBuilder .shiftEditorCollapsed .subHeader {
  color: #4a4a4a;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.shiftBuilder .shiftEditorCollapsed .editButton {
  color: #9b9b9b;
  margin-top: 0.5em;
}

.shiftBuilder .shiftEditorCollapsed .editButton:hover {
  cursor: pointer;
}

.shiftBuilder .grid.shiftEditorCollapsed {
  margin-top: 0;
}

.shiftBuilder .shiftEditorCollapsed .divider {
  margin-top: 0;
  margin-bottom: 0;
}

.shiftBuilder .react-datepicker-wrapper input {
  background-image: url('../images/icon_calendar.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 2px top 5px !important;
}

.shiftBuilder .row.add div {
  height: 30px;
  width: 30px;
  margin: auto;
}

.shiftBuilder .headerRow + .ui.divider {
  color: #d9d9d9;
  width: 100%;
  margin: 0 0 2em 0 !important;
}

.shiftBuilder .shiftColumn .headerRow  + .ui.divider {
  position: relative;
  top: 4px;
}

.shiftBuilder .ui.divider {
  width: 100%;
}

.shiftBuilder i.icon.checkmark:before {
  content: '';
}
.shiftBuilder i.checkmark.icon:before {
  /* display: block;*/
  width: 0.6em;
  height: 1.1em;
  border: solid #4a4a4a;
  border-width: 0 0.135em 0.135em 0;
  position: absolute;
  left: 53%;
  top: 25%;
  text-align: center;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left:-15px;
}

.shiftBuilder .ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.shiftBuilder .ui.form input[type='text'] {
  border: 0.2px solid rgba(34, 36, 38, 0.15);
}
.shiftBuilder .ui.form input[type='number'] {
  border: 0.2px solid rgba(34, 36, 38, 0.15);
}
.shiftBuilder .ui.fluid.dropdown > .dropdown.icon {
border-left: 0.8px solid #9b9b9b;
  padding-bottom: 0;
}
.shiftBuilder .footer {
  color: #9b9b9b;
  /* font-size: 0.8em;*/
  font-size: 10px;
}

.shiftBuilder .footer.warning {
  color: #f16778;
}

 /*
.shiftBuilder .button {
 margin-left: 1em !important;
} */

.shiftBuilder .ui.labeled.input > .ui.dropdown > .dropdown.icon  {
  float: left;
  margin-left: 0px;
  bottom: 15px;
  padding: 2px;
  border-left: none !important;
}
