.your-courses-container {
  width: 100vw;
  display: grid;
  margin-bottom: 100px;
  grid-template-columns: 220px auto;
  grid-template-rows: 90px auto;
  justify-content: stretch;
  grid-template-areas:
    'help yourCourse'
    'help content';
}

.your-courses-side-help-container {
  grid-area: help;
  width: 220px;
  padding-left: 14px;
  padding-top: 100px;
  align-self: flex-start;
}

.e-learning-help-container {
  grid-area: help;
  width: 220px;
  padding-left: 14px;
  padding-top: 20px;
  align-self: flex-start;
}

.course-details-main-content .your-courses-side-help-container {
  grid-area: help;
  width: 220px;
  padding-left: 14px;
  padding-top: 50px;
  align-self: flex-start;
}

.e-learning-report-header,
.course-details-name-header,
.your-courses-header {
  grid-area: yourCourse;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  align-self: center;
  margin-left: 23px;
  text-transform: uppercase;
}

.your-courses-tab {
  width: calc(100% - 56px);
  min-width: 450px;
  height: 100%;
}

.your-courses-tab .ui.bottom.attached.segment.active.tab,
.your-courses-tab .ui.attached.tabular.menu {
  border: none !important;
}

.your-courses-tab .ui.bottom.attached.segment.active.tab {
  width: 100%;
  height: 100%;
}

.your-courses-tab .ui.tabular.menu .active.item {
  border: none !important;
  background: none #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #364967;
  font-size: 14px;
}

.your-courses-tab .ui.tabular.menu .item {
  height: 60px;
  background: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #adb1b8;
  font-size: 14px;
}

.empty-course-view-container {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto 65px 200px;
  width: 100%;
  height: 100%;
  min-height: 636px;
}

.empty-course-view-image {
  margin-top: 207px;
}

.course-record-name,
.empty-course-view-main-text {
  margin-top: 35px;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.empty-course-view-sub-text {
  align-self: flex-start;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #868686;
}

.courses-list {
  margin-left: 6px;
  margin-right: 3px;
}

.courses-list-header {
  font-size: 14px;
  text-transform: capitalize;
  margin: 33px 0;
}

.courses-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 350px));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.course-List-container {
  display: grid;
  width: 350px;
  grid-template-rows: auto 30px 40px 1fr 78px;
  min-height: 190px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.course-List-record-container {
  display: grid;
  width: 350px;
  grid-template-rows: auto auto 1fr 78px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.course-record-name {
  width: 300px;
  margin: 25px 10px 20px 25px;
  align-self: center;
}

.course-record-due-date {
  margin: 0 0 0 25px;
  padding-bottom: 10px;
  color: #9b9b9b;
  font-size: 12px;
  align-self: center;
}

.in-progress-bra-container {
  width: 300px;
  margin: 0 0 0 25px;
  box-shadow: -25px 6px 0 #ffffff, -25px 5px 0 #f0f0f0, 23px 6px 0 #ffffff, 23px 5px 0 #f0f0f0;
}

.ui.tiny.progress.in-progress-course-progress-bar > .bar {
  background-color: #69db8f;
  height: 10px !important;
}

.ui.tiny.progress.in-progress-course-progress-bar.hide-progress-bar > .bar {
  background-color: transparent;
}

.quiz-instructions-text,
.in-progress-course-description {
  font-size: 14px;
  color: #4a4a4a;
  margin: 0 25px;
  align-self: center;
}

.course-record-buttons-section {
  width: 100%;
  height: 78px;
  align-self: flex-end;
}

.course-record-view-certificate {
  display: inline-block;
  position: relative;
  top: -4px;
  margin: 0 25px 23px 0;
  border-radius: 10px;
}

.course-record-view-button,
.course-record-resume-button {
  width: 125px !important;
  height: 30px !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  line-height: 20px !important;
  padding: 0 !important;
}

.course-record-view-button {
  margin: 25px 35px 23px 25px !important;
}

.course-record-view-certificate-dropdown {
  display: inline-block;
  background-color: #69db8f;
  color: #417505;
  margin: 0;
  width: 135px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 20px;
  cursor: pointer;
}

.certificate-dropdown-closed {
  border-radius: 10px;
}

.certificate-dropdown-open {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.course-record-view-certificate-dropdown-text {
  position: relative;
  top: 5px;
}

.agencies-dropdown-text {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 4px;
}

.course-record-view-certificate-button:focus,
.course-record-view-certificate-dropdown:focus {
  outline: none;
}

.course-record-view-certificate-button {
  cursor: pointer;
  padding: 6px 4px;
  border-top: 1px solid #d9d9d9;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 500;
}

.view-certificate-agencies-dropdown {
  position: absolute;
  background-color: #fefefe;
  width: 135px;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #69db8f;
}

.agencies-dropdown-closed {
  display: none;
}

.courses-list-in-progress {
  padding-bottom: 48px;
  padding-top: 19px;
  box-shadow: 0 1px 0 #e5e5e5;
}

.courses-list-pending {
  padding-top: 34px;
}

.course-record-description {
  border-top: 1px solid #f0f0f0;
  font-size: 14px;
  color: #4a4a4a;
  padding: 23px 25px 25px 25px;
}

.completed-courses-container {
  padding: 28px 0;
}

.course-details-main-content {
  width: 100vw;
  display: grid;
  margin-bottom: 100px;
  grid-template-columns: 220px minmax(400px, 800px) 300px 400px;
  grid-template-rows: 90px auto;
  grid-gap: 15px;
  justify-content: stretch;
  grid-template-areas:
    'back name viewButton .'
    'help content content  modules'
    'help content content next';
}

.course-details-back-button {
  grid-area: back;
  color: #04a5d5;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin: 42px 0 42px 25px;
  padding-bottom: 42px;
  width: 210px;
  height: 49px;
}

.course-details-name-header {
  grid-area: name;
}

.course-details-view-certificate-button {
  grid-area: viewButton;
  justify-self: center;
  align-self: center;
  min-width: 115px;
  max-width: 300px;
  height: 30px !important;
  font-size: 12px !important;
  text-align: center !important;
  justify-self: flex-end !important;
  color: #426b6f !important;
  letter-spacing: 0.5px !important;
  padding: 0 10 0 10 !important;
  margin-top: 25px;
}

.reset-button {
  color: #9b9b9b !important;
  background-color: #f2f2f2 !important;
  border: 0.5px solid #d4d4d4 !important;
}

.course-details-view-certificate-button.disabled {
  color: #9b9b9b !important;
}

.next-course-details-modules-container {
  grid-area: next;
  width: 100%;
  min-width: 120px;
  max-width: 350px;
  min-height: 190px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.next-course-suggested-header {
  font-size: 14px;
  color: #4a4a4a;
  margin-left: 25px;
}

.course-details-little-header,
.next-course-suggested-description {
  font-size: 12px;
  color: #4a4a4a;
  padding: 25px;
}

.course-details-agency-name-header {
  font-size: 14px;
  color: #4a4a4a;
  padding: 25px;
  font-weight: 900;
  margin: 25px 0 0 0;
  padding: 0;
}

.next-course-suggested-language-container {
  width: 100%;
  min-width: 100px;
  max-width: 196px;
  justify-self: center;
}

.course-details-container {
  padding: 25px;
  grid-area: content;
  width: 100%;
  min-width: 380px;
  min-height: 190px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.course-details-little-header {
  margin: 25px 0 0 0;
  padding: 0;
}

.course-details-language-container {
  width: 196px;
  margin: 6px 0 25px 0;
}

.course-details-modules-container {
  grid-area: modules;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  min-height: 190px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.course-details-module-list {
  display: grid;
  grid-template-rows: repeat(auto-fit minmax(25px, 1fr));
  align-items: center;
  border-top: 1px solid #d8d8d8;
}

.course-details-module-header {
  margin: 25px 0 25px 25px;
  padding-top: 25px;
}

.course-details-module {
  display: grid;
  grid-template-columns: minmax(25px, 1fr) 90px;
  grid-template-rows: 1fr;
  align-items: center;
}

.course-details-module-name {
  margin: 0;
  color: #04a5d5;
  font-size: 12px;
  line-height: 20px;
  white-space: wrap;
  word-wrap: break-word;
}

.course-details-module-container {
  padding: 10px 2px 10px 25px;
  max-width: 258px;
}

.course-details-module-complete {
  font-size: 12px;
  color: #69db8f;
}

.quiz-modal-loader-image {
  position: relative !important;
  margin: 0 !important;
  left: 50% !important;
  transform: translate(-50%, 0);
}

.quiz-modal-body {
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  height: 100%;
  min-height: 520px;
  max-height: 100vh;
  border-radius: 10px;
}

.ui.tiny.modal.transition.visible.active.quiz-modal {
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  border-radius: 10px !important;
}


.quiz-modal-header {
  background: #f7f7f7;
  border-radius: 10px;
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  height: 70px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
}

.quiz-modal-start-tab-text {
  padding: 35px;
  align-self: center !important;
}

.quiz-modal-header-text {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin: 18px 0 5px 0;
  padding-top: 18px;
  font-size: 14px;
  color: #4a4a4a;
}

.quiz-modal-header-progress {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  margin-bottom: 13px;
}

.quiz-modal-header-attempt {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  font-size: 12px;
  font-style: italic;
  color: #adb1b8;
}

.quiz-modal-progress-bar {
  position: relative;
  bottom: 7px;
  display: inline-block;
  height: 1px;
}

.quiz-modal-progress-bar-first-dot,
.quiz-modal-progress-bar-dot {
  position: relative;
  bottom: 5px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  float: right;
}

.quiz-modal-progress-bar-first-dot {
  bottom: -1px;
  left: 1px;
  z-index: 2;
}

.not-started-dot {
  background-color: #ffffff;
  border: 1px solid #69db8f;
}

.started-dot {
  background-color: #69db8f;
  border: 1px solid #69db8f;
}

.quiz-modal-progress-bar-first-dot {
  float: left;
}

.quiz-modal-end-tab,
.quiz-modal-start-tab {
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  height: 100%;
  min-height: 450px;
  display: grid;
  justify-items: center;
}

.quiz-modal-text {
  letter-spacing: 0.6px;
  line-height: 17px;
  margin: 25px;
  font-size: 14px;
  color: #4a4a4a;
  align-self: flex-end;
}

.Change-language-confirmation-bottom-no,
.Change-language-confirmation-bottom-yes,
.quiz-not-send-answers-bottom,
.quiz-send-answers-bottom,
.quiz-review-button,
.quiz-prev-button,
.quiz-next-button,
.quiz-start-bottom {
  width: 120px !important;
  height: 30px !important;
  justify-self: center;
  margin-right: 0px !important;
  font-size: 12px !important;
  text-align: center !important;
  color: #1c5e3f !important;
  letter-spacing: 0.5px !important;
  padding: 0 !important;
  align-self: flex-start;
  border-radius: 8px !important;
}

.quiz-next-button {
  width: 65px !important;
  align-self: center;
  justify-self: flex-end;
  margin-right: 29px !important;
  grid-column: 3 / 4;
}

.quiz-next-button.disabled {
  color: #9b9b9b !important;
}

.quiz-prev-button {
  width: 77px !important;
  justify-self: flex-start;
  align-self: center;
  color: #9b9b9b !important;
  margin-left: 29px !important;
  grid-column: 1 / 2;
}

.quiz-modal-questions-tab {
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  height: 100%;
  min-height: 450px;
  display: grid;
  justify-items: center;
}

.ui.form.quiz-modal-answers-form {
  display: grid;
  justify-items: flex-start;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
}

.ui.form.quiz-modal-answers-form > .field {
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  margin: 0 !important;
}

.ui.fitted.checkbox.round.quiz-modal-check-box {
  justify-self: flex-end;
  margin-right: 10px !important;
}

.quiz-modal-answer-selected {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #4a4a4a !important;
  display: inline-block;
  justify-self: flex-start;
}

.quiz-modal-answer-unselected {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #adb1b8 !important;
  display: inline-block;
  justify-self: flex-start;
}

i.tiny.icon.Quiz-info-icon {
  line-height: 1;
  font-size: 12px;
}

.quiz-modal-questions-info-footer {
  font-style: italic;
  color: #adb1b8;
  justify-self: center;
  align-self: center;
  grid-column: 2 / 3;
  cursor: pointer;
}

.quiz-modal-questions-info-footer:focus {
  outline: none;
}

.quiz-modal-questions-tab-bottoms-footer {
  display: grid;
  background: #f7f7f7;
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  height: 70px;
  grid-template-columns: repeat(3, 1fr);
  align-self: flex-end;
  border-radius: 10px;
}

.quiz-modal-end-resolution {
  display: grid;
  width: 100%;
  height: 225px;
  justify-items: center;
  align-items: center;
}

.quiz-start-bottom {
  align-self: flex-end !important;
  margin-bottom: 50px !important;
}

.quiz-review-button {
  width: 80px !important;
  color: #9b9b9b !important;
  margin: 0 35px !important;
}

.quiz-modal-send-answer-modal {
  min-width: 450px;
  height: 100%;
  padding: 50px;
  margin-top: 100px !important;
  display: grid;
  margin: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.send-answer-modal-text {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.quiz-send-answers-bottom {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-self: flex-end;
  margin-right: 25px !important;
}

.quiz-not-send-answers-bottom {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-color: #f16778 !important;
  color: #4d1f24 !important;
  justify-self: flex-start;
  margin-left: 25px !important;
}

.Change-language-confirmation-container {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 25px;
  width: 100%;
  background-color: #ffffff;
}

.Change-language-confirmation-bottom-no {
  background-color: #f16778 !important;
  color: #4d1f24 !important;
  justify-self: flex-start;
  margin-left: 25px !important;
}

.Change-language-confirmation-bottom-yes {
  margin-right: 25px !important;
}

.Change-language-confirmation-text {
  font-size: 14px;
  color: #4a4a4a;
}

.ui.image.quiz-modal-end-resolution-image {
  justify-self: center;
  align-self: center;
  top: 0 !important;
  right: 0 !important;
}

.ui.checked.fitted.checkbox.round.quiz-modal-review-answers {
  pointer-events: none;
}

.ui.fitted.checkbox.round.quiz-modal-review-answers {
  pointer-events: none;
}

.quiz-modal-review-correct {
  display: inline-block;
  width: 16px;
  height: 12px;
  background-color: #69db8f;
  clip-path: polygon(13% 37%, 0 60%, 45% 100%, 100% 23%, 84% 4%, 43% 62%);
}

.quiz-modal-review-correct-text {
  color: #69db8f;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  margin-left: 4px;
}

.quiz-modal-review-wrong {
  background-color: #f16778;
  display: inline-block;
  width: 12px;
  height: 12px;
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
}

.wrong-answer-label {
  background-color: #f16778;
  display: grid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-self: flex-end;
}

.wrong-answer-cross {
  background-color: #46030b7c;
  width: 12px;
  height: 12px;
  align-self: center;
  justify-self: center;
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
}

.ui.checked.checkbox.round.quiz-modal-check-box.quiz-modal-review-answers {
  justify-self: flex-end;
  margin-right: 10px;
}

.quiz-start-error,
.quiz-modal-review-wrong-text {
  display: inline-block;
  color: #f16778 !important;
  font-weight: bold;
  font-size: 14px;
  margin-left: 4px;
}

.quiz-instructions-container {
  width: 100%;
  min-width: 450px;
  max-width: 750px;
  padding: 25px 0;
}

.certificate-modal-container {
  height: 950px;
  width: 750px;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  background-color: #505558;
}

@media (min-width: 601px) and (max-width: 1025px) {
  .course-details-main-content {
    grid-template-areas:
      'back name viewButton'
      'help content content'
      'help modules next';
  }
  .course-details-container {
    margin-right: 25px;
  }

  .quiz-modal-send-answer-modal {
    align-self: center;
    min-width: 200px;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .send-answer-modal-text {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .quiz-send-answers-bottom {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-top: 25px !important;
  }

  .quiz-not-send-answers-bottom {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin-top: 25px !important;
  }
}

@media only screen and (max-width: 600px) {
  .your-courses-container {
    grid-template-areas:
      'yourCourse yourCourse'
      'content content'
      'help help';
  }

  .course-details-main-content {
    grid-template-areas:
      'back back back'
      'viewButton viewButton viewButton'
      'name name name'
      'content content content'
      'modules modules modules'
      'next next next'
      'help help help';
  }

  .course-details-container {
    margin-right: 25px;
  }

  .quiz-modal-send-answer-modal {
    min-width: 200px;
    height: 250px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .send-answer-modal-text {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .quiz-send-answers-bottom {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-top: 25px !important;
  }

  .quiz-not-send-answers-bottom {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin-top: 25px !important;
  }

  @media only screen and (min-width: 320px) {

    .quiz-modal-questions-tab-bottoms-footer {
      max-width: 450px;
    }
  }
}
