.caregiver-records-e-learning-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 550px));
  grid-template-rows: auto;
  justify-items: flex-start;
  column-gap: 20px;
}

.caregiver-records-e-learning-header {
  margin-left: 1rem;
  margin-top: 17px !important;
  margin-bottom: 28px;
  color: #364967;
  font-size: 16px;
  grid-area: header;
}

.e-learning-programs-content {
  padding-bottom: 25px;
}

.caregiver-records-e-learning-programs-container {
  width: 100%;
  max-width: 555px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 16px #00000022;
  margin-top: -1px;
  margin-bottom: 25px;
  margin-right: 25px;
}

.e-learning-programs-head {
  display: grid;
  grid-template-columns: 1fr 300px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 25px;
  height: 60px;
  align-items: center;
}

.e-learning-programs-header {
  margin-left: 1rem;
  margin-top: 17px !important;
  margin-bottom: 28px;
  color: #364967;
  font-size: 14px;
  margin: 0 0 0 25px !important;
}

.add-user-to-program-container {
  display: grid;
  grid-template-columns: 1fr 60px;
  align-items: center;
}

.add-user-text {
  font-size: 12px;
  color: #04a5d5;
  margin: 0 10px 0 0;
  justify-self: flex-end;
}

.program-name {
  width: 100%;
  font-size: 14px;
  color: #4a4a4a;
  text-transform: capitalize;
  display: inline-block;
  margin: 0 0 0 25px;
}

.program-divider-decoration {
  width: calc(100% - 60px);
  margin: 0 25px 0 25px;
  height: 1px;
  background-color: #e9e9e9;
}

.program-name-with-divider {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.curriculum-container {
  width: 100%;
  max-width: 480px;
  border-radius: 10px;
  margin: 10px 25px 10px 25px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
}

.curriculum-head {
  display: grid;
  min-height: 35px;
  grid-template-columns: 1fr 100px 30px;
  padding: 0 10px;
  align-items: center;
}

.curriculum-name {
  font-size: 12px;
  font-weight: 600;
  color: #4a4a4a;
  margin: 0 10px 0 0;
}

.curriculum-courses-count {
  font-size: 11px;
  color: #adb1b8;
  margin: 0;
}

.curriculum-expand-courses {
  font-size: 23px;
  font-weight: 700;
  color: #adb1b8;
  margin: 0;
  width: 35px;
  height: 100%;
  text-align: center;
  justify-self: flex-end;
  border-left: 1px solid #e9e9e9;
}

.curriculum-expand-courses-sign {
  vertical-align: middle;
  margin-top: 7px !important;
  margin-left: 7px !important;
  cursor: pointer;
}

.curriculum-expand-courses-sign:focus {
  outline: none;
}

.program-buttons-container {
  padding: 25px 0;
}

.program-button {
  width: 220px !important;
  font-size: 12px !important;
  margin-left: 30px !important;
  height: 35px !important;
  border: 1px solid #d9d9d9 !important;
  color: #b9b9b9 !important;
}

.curriculum-courses-hide {
  display: none;
}

.curriculum-courses-display {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  border-top: 1px solid #e9e9e9;
  padding: 10px;
}

.curriculum-courses-list-head {
  display: grid;
  grid-template-columns: 1fr 180px 60px;
  margin-left: 10px;
  width: calc(100% - 20px);
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding-right: 10px;
}

.curriculum-course-list-header {
  font-size: 10px;
  font-weight: 500;
  color: #9b9b9b;
  margin: 0 10px 5px 0;
  justify-self: flex-start;
}

.curriculum-course-list-header:last-of-type {
  justify-self: flex-end
}

.curriculum-status-reg-toggle {
  justify-self: flex-end;
}

.curriculum-course-list-header:first-of-type {
  justify-self: flex-start;
  margin: 0 0 5px 10px;
}

.curriculum-courses-list-description {
  margin-left: 20px;
  font-size: 11px;
  color: #4a4a4a;
  line-height: 16px;
}

.curriculum-courses-list {
  padding-left: 10px;
  margin-bottom: 25px;
}

.curriculum-courses-list-body {
  display: grid;
  grid-template-columns: 1fr 180px 60px;
  width: calc(100% - 10px);
  justify-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
}

.curriculum-courses-name {
  font-size: 10px;
  color: #4a4a4a;
  font-weight: 600;
  margin: 0;
  padding-top: 3px;
}

.curriculum-courses-circle-status-completed,
.curriculum-courses-circle-status-pending,
.curriculum-courses-circle-status-started {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.curriculum-courses-circle-status-completed {
  background-color: #69db8f;
}

.curriculum-courses-circle-status-started {
  background-color: #04a5d5;
}

.curriculum-courses-circle-status-pending {
  border: 1px solid #d9d9d9;
}

.curriculum-status-text-date {
  text-transform: capitalize;
  display: inline-block;
  font-size: 10px;
  color: #4a4a4a;
  font-weight: 400;
  margin: 0;
}

.curriculum-course-reset-icon {
  padding-left: 20px;
  cursor: pointer;
}

.ui.toggle.checkbox.curriculum-status-reg-toggle input:focus:checked ~ .box:before,
.ui.toggle.checkbox.curriculum-status-reg-toggle input:focus:checked ~ label:before {
  background-color: #69db8f !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='6' y='15' fill='gray' font-size='12'>YES</text></svg>");
}

.ui.toggle.checkbox.curriculum-status-reg-toggle input ~ .box:before,
.ui.toggle.checkbox.curriculum-status-reg-toggle input ~ label:before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='22' y='15' fill='gray' font-size='12'>NO</text></svg>");
}

.curriculum-button {
  margin: 0 0 15px 230px !important;
}

.ui.modal.e-learning-add-program-list {
  width: 800px;
  left: 55%;
  top: 50%;
  border-radius: 20px;
}

.ui.modal.e-learning-add-program-list > .ui.image {
  position: absolute;
  left: 780px;
  top: -18px;
}

.ui.modal.e-learning-add-to-program-container {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: inline-block;
  border-radius: 20px;
}

.e-learning-add-program-header {
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #e9e9e9;
}

.e-learning-add-program-list-container {
  padding: 1em;
  border-radius: 25px;
}

.e-learning-add-program-list-head {
  display: grid;
  grid-template-columns: repeat(4, 160px) 128px;
  background-color: #d9d9d9;
  height: 40px;
  border-bottom: 1px solid #b9b9b9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  align-items: center;
}

.e-learning-add-program-list-body {
  display: grid;
  grid-template-columns: repeat(4, 160px) 132px;
  text-align: center;
}

.e-learning-add-program-list-body > span {
  padding: 1rem;
}

.e-learning-add-program-list-body > :first-child {
  padding-left: 0;
  text-align: start;
}

.e-learning-add-program-list-head > :first-child {
  margin-left: 10px;
  text-align: start;
}
.e-learning-add-program-list-head > :last-child {
  border-top-right-radius: 10px;
}

.e-learning-add-program-body,
.e-learning-add-program-list-header {
  min-height: 30px;
  padding-top: 8px;
  padding-left: 8px;
}

.e-learning-add-program-list-empty-main-text,
.e-learning-add-program-list-header {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 800;
}

.e-learning-add-program-body {
  background-color: #f3f3f3;
  border-bottom: 1px solid #e9e9e9;
}

.e-learning-add-program-list-check-box {
  margin-left: 40px !important;
}

.e-learning-add-program-list-empty-container {
  display: grid;
  justify-items: center;
  padding: 15px;
}

.e-learning-add-program-list-button-container {
  padding: 10px;
  display: grid;
  justify-items: flex-end;
  align-items: center;
}

.ui.modal.transition.visible.active.e-learning-confirmation-modal {
  width: 530px;
  height: 196px;
  position: relative !important;
  border-radius: 10px !important;
  left: 50%;
}

.e-learning-confirmation-modal-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto auto 40px;
  padding-bottom: 20px;
}

.e-learning-confirmation-modal-text {
  grid-row: 2 / 3;
  grid-column: 1 / span 2;
  width: 100%;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 400;
  padding: 20px;
  text-align: center;
  line-height: 30px;
}

.e-learning-confirmation-modal-header {
  grid-row: 1 / 2;
  grid-column: 1 / span 2;
  background-color: #f16778;
  color: #f3f3f3;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 800;
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
}

.e-learning-confirmation-cancel-button,
.e-learning-confirmation-proceed-button {
  width: 120px !important;
  height: 30px !important;
  margin-right: 0px !important;
  font-size: 12px !important;
  text-align: center !important;
  background-color: #f16778 !important;
  color: #4d1f24 !important;
  letter-spacing: 0.5px !important;
  padding: 0 !important;
  align-self: flex-start;
  border-radius: 8px !important;
}

.e-learning-confirmation-proceed-button {
  justify-self: center;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}

.e-learning-confirmation-cancel-button {
  color: #b9b9b9 !important;
  border: 0.5px solid #b9b9b9 !important;
  background-color: transparent !important;
  justify-self: center;
  margin-left: 25px !important;
  grid-row: 3 / 4;
  grid-column: 1 / 2;
}

.curriculum-course-dirty {
  background-color: bisque;
}

.programs-tile-updates-in-progress {
  width: 100%;
  height: 80px;
}

.update-in-progress-header {
  padding: 10px;
  background-color: #04a5d5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.update-in-progress-header-text {
  color: #f9f9f9;
  font-size: 16px;
  margin: 0;
}

.update-inprogress-content {
  text-align: center;
  background-color: bisque;
  padding: 10px;
}

.update-in-progress-text {
  font-size: 12px;
  color: #4a4a4a;
}
