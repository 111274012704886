.complianceBlockForm .description{
  font-size: 12px;
  font-weight: lighter;
}

.complianceBlockForm .description.light{
  font-size: 11px;
  color: #999;
  font-weight: lighter;
}

.complianceBlockForm .nameInput Input{
  text-align: center !important;
  border-style: solid !important;
  border-width: 0px 0px 1px 0px !important;
  margin-bottom: 12px !important;
  border-radius: 0px !important;
}

.complianceBlockForm .padded{
  margin-left: 42px;
  margin-right: 42px;
}