
.transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.bot-cards-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 0 !important;
}

.bot-setting-header-container{
    display: grid;
    position: relative;
    grid-template-rows: 1fr 0.2fr;
    grid-template-columns: 0.1fr 1.2fr 0.1fr;
    justify-items: self-start;
    align-items: flex-start;
    top: -20px;
}

.check-box-field {
    grid-column: 3/4;
    grid-row: 1 / span 3;
    align-self: center;
}
.bot-setting-body > .bot-mini-header {
    font-size: 14;
    font-weight: 900 !important;
    color: #4a4a4a;
}

.remainder-body {
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #b9b9b9;
    margin-top: 10px;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: center;
    align-self: center;
}

.bot-settings-text-and-input {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    grid-template-rows: 0.5fr 1fr;
}

.bot-input-reminds-form{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: center;
    align-self: center;
}

.tale-mini-header-container {
    padding-top: 1em;
    padding-bottom: 1em;
    grid-column: 1 /  span 3;
    grid-row: 1 / 2;
}
.tale-remainder-body{
    padding-bottom: 1em;
}

.bot-headerText{
    margin-bottom: 0 !important;
}

.bot-setting-header {
    grid-column: 2/3;
    align-self: flex-start;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
}

.bot-hr {
    position: relative;
    top: -35px;
}

.bot-setting-tile {
    max-width: 38em;
    margin-top: 1.5em;
    margin-right: 1.5em;
    padding: 18px !important;
}

.bot-setting-body {
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #4a4a4a;
    margin-top: 10px;
}

.bot-table-item-updated {
    background-color: #fff1c2;
    width: 100%;
    border-radius: 5px;
    box-shadow: 5px 0px #fff1c2,
                5px 5px #fff1c2,
                -5px 0px #fff1c2,
                -5px 5px #fff1c2, 
                5px -5px #fff1c2,
                -5px -5px #fff1c2;
}

.robot-container{
    width: 5em;
    height: 5em;
    background-color: transparent;
    position: relative;
    grid-column: 1/2;
    grid-row: 1/ span 2;
    align-self: center;
    justify-self: flex-start;
    right: 1em;
}

.robot-circle{
    width: 3em;
    height: 3em;
    background-color: #f0f0f0;
    position: relative;
    border-radius: 50%;
    top: 1em;
    left: 1.2em;
}

.robot-antenna{
    width: 6px;
    height: 6px;
    background-color: #a4d512;
    box-shadow: inset -1px 1px #96c702; 
    border-radius: 50%;
    position: relative;
    left: 2.5em;
    top: -39.5px;
}

.robot-antenna-mast{
    width: 2px;
    height: 4px;
    background-color: #a4d512;
    box-shadow: inset -1px 1px #96c702; 
    position: relative;
    left: 2.65em;
    top: -40px;
}

.robot-head{
    width: 14px; 
    height: 9px; 
    background-color: #b9c0c8;
    position: relative;
    left: 2.2em;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: -40px;
}

.robot-face {
    width: 1px; 
    height: 1px; 
    background-color: #b9c0c8;
    position: relative;
    left: 1px;
    top:1px;
    box-shadow: 1px 0px #8793a1,
                1px 1px #8793a1,
                2px 0px #8793a1,
                2px 1px #8793a1,
                9px 0px #8793a1, 
                9px 1px #8793a1, 
                10px 0px #8793a1,
                10px 1px #8793a1,
                3px 5px #8793a1,
                4px 5px #8793a1,
                5px 5px #8793a1,
                6px 5px #8793a1,
                7px 5px #8793a1,
                8px 5px #8793a1;
}
.robot-body{
    width: 16px; 
    height: 16px; 
    background-color: #b9c0c8;
    position: relative;
    left: 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
    top: -39px;
    clip-path: polygon(100% 0, 100% 48%, 79% 100%, 21% 100%, 0 47%, 0 0);
}
.robot-inside-box{
    width: 10px; 
    height: 7px; 
    background-color: #8793a1;
    position: relative;
    left: 3px;
    border-radius: 2px;
    top: 4px;
}

.robot-lights{
    width: 1px; 
    height: 1px; 
    background-color: transparent;
    position: relative;
    box-shadow: 1px 1px #990234,
                2px 1px #990234,
                3px 1px #990234,
                4px 1px #990234,
                5px 1px #990234,
                8px 1px #f0ab0c,
                1px 3px #ebd10e,
                2px 3px #ebd10e,
                3px 3px #ebd10e,
                4px 3px #ebd10e,
                5px 3px #ebd10e,
                8px 3px #ffffff,
                1px 5px #582a59,
                2px 5px #582a59,
                3px 5px #582a59,
                4px 5px #582a59,
                5px 5px #582a59,
                8px 5px #a4d512
    ;
}

.robot-arms{
    width: 16px; 
    height: 11px; 
    background-color: #8793a1;
    position: relative;
    left: 30px;
    border-radius: 2px;
    top: -51px;
}
.robot-legs{
    width: 8px; 
    height: 12px; 
    background-color: #b9c0c8;
    position: relative;
    left: 34px;
    top: -51px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.robot-wheel{
    width: 2px; 
    height: 10px; 
    background-color: #1d1c18;
    position: relative;
    left: 3px;
    top: 6px;
    border-radius: 15px;
    box-shadow: -1px -1px #f0f0f0, 1px -1px #f0f0f0, 0 -1px #f0f0f0;
}