.item.logo-menu-item {
  padding: 1em 16px 1em 12px !important;
  max-width: 180px;
  max-height: 60px;
}

.logo-name-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.topMenu {
  .ui.dropdown {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;

    &.visible {
      &:after {
        content: '';
        border: 1px solid #e3e3e3;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -5px;
        top: 100%;
        transform: rotate(-45deg);
        background: #fff;
        z-index: 5;
        margin-top: 12px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        width: 35px;
        height: 20px;
        background: #fff;
        right: -15px;
        margin-top: 16px;
        z-index: 6;
      }
    }

    & + .agency-name {
      padding-right: 20px;
    }

    & > .text {
      display: none;
    }

    .icon {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      left: auto;
      margin-top: -1px;

      &:before {
        content: '\f0d7' !important;
        font-size: 13px;
      }
    }

    .menu {
      padding: 18px 30px 0 30px;
      min-width: 300px;
      max-width: 600px;
      margin-right: -35px;
      margin-top: 15px;
      border: 1px solid #e3e3e3 !important;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
      left: auto !important;
      right: 0;
      max-height: 400px;
      overflow: auto;
      z-index: 1001;

      & > .item {
        padding: 7px 9px !important;
        margin: 0;
        color: #9b9b9b !important;
        word-wrap: normal;
        white-space: pre-line;

        &:last-child {
          margin-bottom: 18px;
        }

        &:hover {
          border-radius: 2px !important;
          background: #f1f1f1 !important;
        }

        &.selected {
          background: none !important;
        }

        &.active {
          background: transparent !important;
          position: relative;

          &:after {
            content: '\f0da';
            font-family: 'Icons';
            position: absolute;
            left: -4px;
            top: 7px;
          }
        }
      }
    }
  }
}

.agency-name {
  margin: 0 0 0 8px;
  color: #7e90ac !important;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: normal !important;
  white-space: pre-line;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 12px;
}

.profile {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  margin: 0;
}

.helpCenter {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.profileItem {
  padding: 0.5em 16px !important;
  margin-right: 20px !important;
}

.helpCenter:hover {
  background-color: transparent !important;
}

.helpCenter .text {
  color: #a9adb4;
  background-color: #f5f5f5;
  padding: 5px;
  padding-right: 0.5em;
  border-radius: 20px;
}

.helpCenter .icon {
  color: #04a5d5 !important;
  background-color: white !important;
  font-weight: bold;
  margin-right: 0.5em;
  border-radius: 50%;
  padding: 0em 0.5em;
}

.profileColumn {
  display: flex;
  vertical-align: middle;
}

.employee-avatar {
  object-fit: cover;
  border: 2px solid white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.userName {
  margin: 0;
  color: #04a5d5;
  font-size: 13px;
  font-weight: normal !important;
}

.role {
  margin: 0;
  color: #7e90ac !important;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: normal !important;
}

.employee-avatar {
  width: 35px !important;
  height: 35px !important;
  clip-path: circle(50% at 50% 50%);
  margin-right: 10px;
}

.ui.menu .logoArea {
  position: absolute;
  left: 0;
  top: 15% !important;
  background: lightgrey !important;
  height: 70% !important;
  width: 1px;
}

.no-notification-connection-bar,
.no-network-connection-bar {
  width: 100%;
  height: 2rem;
  margin-bottom: 0;
  padding-top: 0.2rem;
  background-color: #f16778;
  text-align: center;
  vertical-align: middle;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.9fr 1fr;
  align-items: center;
}

.no-notifications-text,
.no-connection-text {
  position: relative;
  color: #4b4b4b;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.no-connection-text {
  justify-self: flex-start;
}

.no-notifications-text {
  justify-self: center;
}

.no-notification-connection-bar {
  grid-template-columns: 1fr;
}

.antenna-container {
  position: relative;
  top: -15px;
  left: -10px;
  height: 20px;
  justify-self: flex-end;
}

.waves {
  position: relative;
  background-color: #4b4b4b;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  left: 10px;
  top: 19px;
  z-index: 20;
}
.waves1 {
  position: relative;
  background-color: #f16778;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 9px;
  top: 14px;
  z-index: 19;
}
.waves2 {
  position: relative;
  background-color: #4b4b4b;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 8px;
  top: 7px;
  z-index: 18;
}
.waves3 {
  position: relative;
  background-color: #f16778;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 7px;
  top: -2px;
  z-index: 17;
}
.waves4 {
  position: relative;
  background-color: #4b4b4b;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 6px;
  top: -13px;
  z-index: 16;
}

.antenna {
  background-color: #4b4b4b;
  position: relative;
  width: 1px;
  height: 10px;
  left: 11px;
  top: -16px;
  border-radius: 2px;
  box-shadow: 1px 1px #f16778, -1px -1px #f16778, 1px -1px #f16778;
  z-index: 20;
}

.slash {
  background-color: #4b4b4b;
  position: relative;
  width: 1px;
  height: 18px;
  left: 12px;
  top: -38px;
  border-radius: 5px;
  transform: rotate(45deg);
  box-shadow: 0.5px 0.5px #f16778, -0.5px -0.5px #f16778, -0.5px 0.5px #f16778, 0.5px -0.5px #f16778;
  z-index: 21;
}
