

.chatContainer {
  bottom: -1px;
  right: 0px;
  position: fixed;
  z-index: 10000;
  height: 0;
}

.careChat {
  background-color: white;
  width: 250px;
  font-size: 0.9em;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(120,120,120,0.63);
  -moz-box-shadow: 0px 0px 1px 1px rgba(120,120,120,0.63);
  box-shadow: 0px 0px 1px 1px rgba(120,120,120,0.63);
  position: relative;
  top: -315px;
  margin-right: 1em;
}

.careChat.hidden {
  top: -38px
}

.careChat .messages {
  padding: 5px 3px;
  overflow: hidden;
}

.careChat .messages .messageView {
  width: 250px;
  height: 200px;
  overflow-y: auto!important;
  padding: 0.6em;
  position: relative;
}

.careChat .messages .message {
  margin-bottom: 0.6em;
  width: 100%;
  float: left;
}

.careChat .messages .message.right{
  float: right;
  text-align: right;
}


.careChat .messages .message .timeLabel {
  font-size: 0.6em;
  color: gray;
  text-align: center;
}
.careChat .messages .message .messageCtr {
  padding: 0.6em;
  font-size: 0.8em;
  font-weight: normal;
  right: 0px;
}

.careChat .messages .message .messageCtr.right {
  float: right;
}

.careChat .messages .message .messageCtr .timeLabel {
  margin-top: 0.5em;
  margin-left: 3em;
  font-size: 0.8em
}

.careChat .messages .message .messageCtr.alarm {
  background-color: #FFC0CB;
}

.careChat .writing {
  padding: 0.3rem;
  padding-bottom: 2em;
}

.careChat .writing .failure {
  font-size: 0.8em;
  margin-left: 0.5em;
  color: #A11D33;
}

.careChat .writing .msgInput {border-radius: 0!important; margin: 0.2rem!important;}
.careChat .writing button {
  margin-top: 0.2rem;
  display:block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: 0.7em;
  background-color: cadetblue;
  border: none;
  color: white;
  padding: 0
}
