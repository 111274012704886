.complianceTabs .itemContainer {
  padding: 16px;
  display: flex;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.complianceTabs .itemContainer:hover {
  color: #888;
}

.complianceTabs .itemContainer.dimmed:hover {
  color: #222;
}

.complianceTabs .itemContainer.dimmed {
  position: relative;
  top: 4px;
  left: -2px;
  background: #ddd;
  color: #666;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.complianceTabs .itemContainer.dimmed:last-child {
  width: 101.5%;
}

.complianceTabs .itemContainer .img {
  float: left;
  height: 22px;
}

.complianceTabs .itemContainer .text {
  float: none;
  margin-left: 16px;
}

.compliances-tab-header {
  display: grid;
  grid-template-columns: 35px auto 1fr;
  align-items: center;
  margin-bottom: 10px;
}

.compliance-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: stretch;
}

.compliance-complete-container {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
}

.compliance-add-completion-panel {
  background-color: #f7f7f7;
  padding: 20px;
  margin: 10px 10px 10px 10px;
}

.compliance-info-panel {
  padding: 10px;
  margin-left: 25px;
}

.compliance-hep-date-container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}

.compliance-justify-flex-end {
  justify-self: flex-end;
}

.compliance-update-container {
  display: grid;
  place-items: center;
  grid-column: 1 / span 2;
}

.hep-b-dose-dates-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.hep-b-dose-dates-column-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.hep-b-test-dates-container {
  width: 100%;
  max-width: 285px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label-input-in-line-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label-input-in-line-container > div {
  max-width: 120px;
}

.label-result-in-line-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.label-result-in-line-container > div:first-of-type {
  margin-left: 1rem;
}

.label-input-block-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 1rem 1rem 0;
}

.covid-documents-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.covid-dose-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.covid-documents-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.covid-dose-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.covid-result-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.covid-manufacture-text {
  display: inline-block;
  color: #4a4a4a;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.55px;
	line-height: 20px;
	margin: 0;
}

.compliance-results-labels {
  color: #4a4a4a;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.55px;
	line-height: 20px;
	margin: 0;
}

.covid-vaccine-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto;
  place-items: center;
  margin-bottom: 20px;
}

.multi-dose-info-container {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #4a4a4a;
  padding: 1rem;
}

.series-two-divider {
  box-shadow: -35px 0px #ffffff, -35px -1px #c8cbd3, 10px 0px #ffffff, 10px -1px #c8cbd3;
  padding-top: 20px;
}

.compliance-influenza-mask-date-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  margin: 1rem 0 20px 35px;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
}

.compliance-influenza-mask-date-container > h3 {
  margin: 0;
  font-size: 12px;
  color: #4a4a4a;
}
.compliance-influenza-mask-date-container > p {
  margin: 0;
  font-size: 11px;
  color: #9b9b9b;
}
.compliance-influenza-mask-date-container > span {
  font-size: 11px;
  color: #4a4a4a;
}

.tb-or-separator {
  display: grid;
  place-items: center;
  font-weight: bold;
  color: #9b9b9b;
  font-size: 12px;
  padding: 25px;
}

.common-compliance-container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}

.dirtyField {
  background-color: #fff1c2;
}

.compliance-field-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.compliance-fields {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  row-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.two-columns-field {
  grid-column: 1 / span 2;
}

.compliance-input-field > * {
  margin-left: 10px;
}

