.scheduleDetails {
  max-width: 500px;
  min-width: 500px;
  min-height: 500px;
}

div [class*='right floated'] {
  float: right;
  margin-right: 0.25em;
}

.scheduleDetails .top.header {
  position: relative !important;
  top: -30px !important;
}

.schedule-modal-close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 485px;
  top: -15px;
  width: 30px;
  height: 30px;
}

#schedule-sub-header {
  position: relative;
  top: 2px;
}

.scheduleDetails .scheduleHeader {
  position: relative;
  top: 5px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.167rem;
}

.scheduleDetails .scheduleHeader .date {
  margin: 0.3rem;
  color: #4a4a4a;
  font-size: 10px;
  font-weight: bold;
  text-transform: lowercase;
  letter-spacing: 0.085rem;
}

.scheduleDetails .scheduleHeader .date {
  text-transform: capitalize;
}

.scheduleDetails .scheduleHeader .l1 {
  margin-top: 0.5rem !important;
  margin-right: 0.5rem !important;

  border-radius: 5px;
  letter-spacing: 0.085rem;
}

.scheduleDetails .scheduleHeader .bg-blue-l1,
.scheduleDetails .scheduleHeader .bg-blue-l2 {
  background-color: #35b6dd;
}

.scheduleDetails .scheduleHeader .bg-green-l1,
.scheduleDetails .scheduleHeader .bg-green-l2 {
  background-color: #80e2a1;
}

.scheduleDetails .scheduleHeader .bg-orange-l1,
.scheduleDetails .scheduleHeader .bg-orange-l2 {
  background-color: #f8bb59;
}

.shift-details-tabs-column {
  max-width: 345px;
}

.scheduleDetails .scheduleHeader .ui.image {
  margin-top: 0.5rem !important;
  height: 1.5rem;
}
.scheduleDetails .ui.tiny.buttons.careTabs {
  width: 460px;
  margin-left: 14px;
}

.scheduleDetails .scheduleHeader .l2 {
  margin-top: 0.5rem !important;
  margin-left: 0.5rem !important;
  border-radius: 5px;
  letter-spacing: 0.085rem;
}

.scheduleDetails .scheduleHeader .l2 .text {
  position: relative;
  left: 1em;
  top: 1em;
}

.scheduleDetails .scheduleHeader .separator {
  line-height: 30px;
}

.schedule-details-button {
  width: 120px !important;
}

.scheduleDetails .detailGrid .row:lang(line) {
  box-shadow: 0px -1px rgba(34, 36, 38, 0.15);
}

.scheduleDetails .detailGrid .row:lang(line-top) {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.scheduleDetails .detailGrid div.six.wide.column {
  padding-top: 15px;
  padding-bottom: 0px;
}

.scheduleDetails .prefsLists .noBorderFirstRow .row {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.scheduleDetails .assignedTab .row {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.scheduleDetails .ui.tiny.header {
  font-size: 10px;
  letter-spacing: 0px;
}
.prefsLists .ui.tiny.header.headerText {
  font-weight: 'bold';
  font-size: 12px;
}
.scheduleDetails .ui.tiny.header .clientName {
  font-size: 12px;
  font-weight: bold;
}

.scheduleDetails .ui.list > .item {
  font-size: 12px;
  color: #4a4a4a;
}

.scheduleDetails .ui.grid > .row > [class*='eleven wide'].column .ui.tiny.header {
  color: #4a4a4a !important;
  font-size: 12px;
}

.scheduler-dimmer {
  min-height: 30px;
  max-height: 100vh;
  display: block;
  left: 18.5% !important;
  top: 50% !important;
  margin: 1.5rem auto !important;
  transform: translate(-50%, -50%);
}
.scheduleDetails .detailGrid .row:first-child {
  margin-top: 1em;
}

.scheduleDetails .ui.form .field > .selection.dropdown,
.scheduleDetails textarea {
  margin-left: 50px;
  width: 340px;
  height: 150px;
  font-size: 12px;
  color: #4a4a4a;
}

.scheduleDetails .ui.tiny.left.floated.header.shift-notes {
  font-size: 12px;
  font-weight: normal;
  color: #4a4a4a;
  margin-bottom: 20px;
  margin-left: 16px;
} 

.scheduleDetails .sixteen.wide.field.shift-notes {
  width: 345px;
  height: 181px;
}

.scheduleDetails .sixteen.wide.field.shift-notes > textarea {
  border-radius: 4px;
  margin-left: 0;
  width: 345px;
  height: 146px;
}

.scheduleDetails .sixteen.wide.field.shift-notes > textarea::placeholder {
  font-size: 11px;
  line-height: 16px;
  padding-left: 15px;
  padding-top: 13px;
  color: #A9ADB4;
}

.scheduleDetails .ui.modal > .actions {
  border-top: none !important;
  background-color: #f2f2f2 !important;
}

.scheduleDetails .ui.tiny.header.requests {
  color: #4A4A4A;
  font-size: 12px;
  margin-top: 1em !important;
}

.scheduleDetails .ui.tiny.header.accepted {
  color: #69DB8F;
  font-size: 12px;
  margin-top: 1em !important;
}

.scheduleDetails .ui.button.shift-details-apply-button {
  background-color: #69db8f !important;
  color: #417505;
  height: 40px;
  width: 140px !important;
  white-space: nowrap;
}

.scheduleDetails .ui.button.disabled.shift-details-apply-button {
  background-color: #e3e3e3 !important;
  color: #4a4a4a;
  height: 40px;
  width: 140px !important;
}

.input-range__label--max {
  visibility: hidden;
}

.input-range__label--min {
  visibility: hidden;
}

.react-datepicker-wrapper {
  font-size: 0.8em !important;
  width: 90% !important;
}

.ui.tiny.header {
  font-weight: normal !important;
  letter-spacing: 0.12rem !important;
  color: #9b9b9b;
  font-size: 12px;
}
.ui.tiny.right.floated.header {
  color: #4a4a4a;
}

.scheduleHeader {
  color: #ffffff !important;
}

.birthdayInput #react-datepicker-wrapper .input {
  font-size: 1rem !important;
}

.checkbox-label-left {
  padding: 0 5px;
  cursor: pointer;
  color: #4a4a4a;
}

.checkbox-label-left.disabled {
  color: #804a4a4a;
  cursor: default;
}

.shiftsSearchTimeRange .input-range__slider-container:first-of-type .input-range__label-container {
  margin-right: 1.5rem;
  font-size: 10px;
  color: #9b9b9b;
}

.shiftsSearchTimeRange .input-range__slider-container:first-of-type .input-range__label--value {
  position: absolute;
  top: 0.8rem;
  left: 0.6rem;
}

.shiftsSearchTimeRange .input-range__slider-container:last-of-type .input-range__label-container {
  margin-left: 1.5rem;
  font-size: 10px;
  color: #9b9b9b;
}

.shiftsSearchTimeRange .input-range__slider-container:last-of-type .input-range__label--value {
  position: absolute;
  top: 0.8rem;
  left: -0.6rem;
}

.shiftsSearchTimeRange .input-range__slider {
  height: 17px;
  width: 17px;
  margin-top: -0.8rem;
}

.shiftsSearchTimeRange .input-range__slider-container:first-of-type {
  margin-left: -0.7rem !important;
}

.shiftsSearchTimeRange .input-range__slider-container:last-of-type {
  margin-left: 0.5rem !important;
}

.shiftsSearchTimeRange .input-range__label-container {
  pointer-events: none !important;
}

.shiftsSearchTimeRange .input-range__label {
  pointer-events: none;
}

/*.ui.tiny.center.aligned.header.scheduleHeader {
    color: #FFFFFF;
}*/

.scheduleDetails .ui.active.button.careTab {
  font-size: 12px;
  color: #364967;
}
.scheduleDetails .ui.button.careTab {
  font-size: 12px;
  color: #a9adb4;
}
.scheduleDetails .ui.tiny.header {
  font-size: 10px;
  letter-spacing: '0px';
}
.scheduleDetails .ui.centered.grid.buttonArea {
  background-color: #f2f2f2;
}

.scheduleDetails .actions .button.ui.disabled.button {
  background-color: #f9f9f9;
  height: 40px;
  width: auto;
}

.scheduleDetails .actions .button.ui.button {
  background-color: #f9f9f9;
  height: 40px;
  width: auto;
}

.scheduleDetails i.checkmark.icon:before {
  content: '';
}
.scheduleDetails i.checkmark.icon:before {
  /* display: block;*/
  width: 0.5em;
  height: 1em;
  border: solid #417505;
  border-width: 0 0.135em 0.135em 0;
  position: absolute;
  left: 75%;
  top: 25%;
  text-align: center;
  margin-top: -0.2em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: -9px;
}

.prefsLists .detailGrid i.checkmark.small.circular.icon {
  height: 18px;
  width: 18px;
}

.prefsLists .avatarWithName img {
  width: 30px !important;
  height: 30px !important;
}
