.pre-hire-not-completed {
  font-size: 10px;
  color: #04a5d5;
  font-weight: bold;
  border: 0px;
  border-style: none;
}

.pre-hire-not-completed:hover {
  cursor: pointer;
}

div.completeIcon img.ui.image {
  position: relative;
  width: auto;
  height: 18px;
  padding-top: 2px;
}

.preHireCompleted {
  font-size: 10px;
  color: #000000;
  font-weight: bold;
}

.completeIcon {
  margin-left: 0px;
  padding-left: 0px;
}

div.pre {
  float: left;
  width: auto;
  margin-right: 0px;
  padding-right: 5px;
}

div.ui.listColumns {
  column-count: 2;
  column-gap: 20px;
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
}

.preHire .ui.bulleted.list {
  margin-left: 1.25rem;
  padding: 0px;
}
