.e-learning-manage-head {
  width: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;
}

.e-learning-manage-header {
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  align-self: center;
  margin: 0 0 0 55px;
  text-transform: uppercase;
}

.e-learning-manage-users-table-container {
  background-color: #ffffff;
  width: 100%;
  max-width: 90vw;
  padding: 25px;
  margin: 25px;
}

.e-learning-table-filter-container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 110px 1fr;
  padding-bottom: 25px;
}

.e-learning-table-filter-container-header {
  font-size: 14px;
  line-height: 19px;
  color: #4a4a4a;
  align-self: center;
  margin: 0 0 0 25px;
  text-transform: capitalize;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.e-learning-table-filter-container-label {
  font-size: 10px;
  color: #a4a4a4;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  align-self: center;
}

.ui.selection.dropdown.e-learning-programs-dropdown {
  grid-area: language;
  justify-self: flex-start;
  align-self: center;
  margin-top: 0 !important;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.ui.selection.dropdown.e-learning-programs-dropdown > .dropdown.icon {
  top: 8px !important;
  height: 33px;
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(19% 0% 11% -10%);
}

.e-learning-table-body,
.e-learning-table-headers {
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
}

.e-learning-table-body {
  height: 100% !important;
  min-height: 50px;
}

.e-learning-table-headers {
  position: sticky;
  top: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: calc(100% - 24px);
  height: 50px;
  text-align: center;
}

.e-learning-table-body-wrapper-not-scrollable {
  width: calc(100% - 24px);
}

@-moz-document url-prefix() {
  .e-learning-table-headers {
    position: sticky;
    top: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: calc(100% - 14px);
    height: 50px;
    text-align: center;
  }
  .e-learning-table-body-wrapper-not-scrollable {
    width: calc(100% - 14px);
  }
}

.selected-up::after {
  font-size: 10px;
  content: '\25B2';
  position: relative;
  left: 8px;
}

.selected-down::after {
  font-size: 10px;
  content: '\25BC';
  position: relative;
  left: 8px;
}

.e-learning-table-header {
  border-bottom: 1px solid #d9d9d9;
}

.e-learning-table-body-tile,
.e-learning-table-header {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.e-learning-table-body-tile {
  padding: 5px;
}

.e-learning-table-load-more:focus,
.e-learning-table-body-tab-name:focus,
.e-learning-table-header:focus {
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.e-learning-table-body-tab-name {
  color: #04a5d5;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}

.e-learning-table-header-tab.selected-up,
.e-learning-table-header-tab.selected-down {
  font-weight: 700;
}

.e-learning-table-header-tab {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
}

.e-learning-paginazer-table {
  padding: 0;
}

.e-learning-table-body-wrapper {
  border: 1px solid #d9d9d9;
}

.e-learning-table-body {
  border-bottom: 1px solid #d9d9d9;
  height: 50px;
}

.e-learning-table-body-wrapper > div:nth-child(2n) {
  background-color: #f6f6f6;
}

.e-learning-table-header-tab,
.e-learning-table-body-tab {
  justify-self: center;
}

.e-learning-table-body-wrapper > :nth-child(2n) {
  background-color: #00003205;
}

.e-learning-table-headers > :first-child {
  border-top-left-radius: 10px;
}

.e-learning-table-headers > :last-child {
  border-top-right-radius: 10px;
}

.e-learning-table-load-more {
  text-align: center;
  vertical-align: middle;
  height: 35px;
  padding: 25px;
  color: #04a5d5;
  font-size: 12px;
  cursor: pointer;
}

.e-learning-empty-table {
  display: grid;
  width: 100%;
  height: 50%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  place-items: center;
  padding: 25px;
}

.e-learning-empty-table > h3 {
  font-size: 14px;
  color: #4a4a4a;
}

.e-learning-empty-table > p {
  font-size: 12px;
  color: #a9adb4;
  margin-top: 10px;
}
