.caregiverDetails {
  max-width: 500px;
  min-width: 500px;
  position: relative;
  left: 50%;
  transform: translate(50%);
}

.ui.scrolling.modal.transition.visible.active.caregiverDetails {
  position: relative;
  left: 0%;
  transform: translate(0%);
}

#caregiver-details-column {
  max-height: 45vh;
}

#caregiver-details-modal > .scrolling.content {
  padding-top: 0 !important;
}

.caregiver-modal-close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 485px;
  top: -15px;
  width: 30px;
  height: 30px;
}

.caregiverDetails .modalHeaderGrid {
  height: 150px;
}

.caregiverDetails .caregiver-details-name-header {
  position: relative;
  top: -30px;
}

.caregiverDetails .caregiver-details-caregiver-name {
  color: #364866;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.caregiverDetails .modalHeaderGrid .id-text {
  font-weight: normal;
  font-size: 12px !important;
  color: #9b9b9b;
}

.caregiverDetails .headerPic {
  position: relative;
  top: -55px;
}

#caregiver-details-avatar {
  position: relative;
  top: 5px;
}

.caregiverDetails .modal-bubbles {
  position: relative;
  top: 48px;
  box-shadow: 100px 0 0 #ffffff, 90px 1px 0 0 #e3e3e3, -100px 0 0 #ffffff, -90px 1px 0 0 #e3e3e3;
}

.caregiverDetails .ui.tiny.fluid.buttons.careTabs {
  position: relative;
  top: -25px;
  left: 22px;
  max-width: 455px;
  margin: 0;
}

#caregiver-details-agency-tab-button,
#caregiver-details-details-tab-button {
  width: 137px;
}
#caregiver-details-preferences-tab-button {
  width: 181px;
}

.caregiverDetails .detailGrid {
  width: 410px;
  margin-left: -75px;
}

.caregiverDetails .caregiver-details-languages-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20px 30px minmax(30px, auto);
}

.caregiverDetails .caregiver-details-section-header {
  font-size: 12px;
  font-weight: bold;
  justify-self: flex-start;
  color: #4a4a4a;
  margin: 0 0 1rem 60px;
}

.caregiver-details-tab-main-container .ui.tiny.header,
.caregiverDetails .caregiver-details-header {
  display: inline-block;
  letter-spacing: inherit !important;
  font-weight: bold !important;
  font-size: 10px;
  color: #9b9b9b;
  justify-self: flex-start;
  align-self: center;
  margin: 0 0 0 90px;
}

.caregiverDetails .caregiver-details-info-container > span,
.caregiver-details-tab-main-container .ui.list,
.caregiverDetails .caregiver-details-text {
  display: inline-block;
  font-weight: normal !important;
  white-space: pre-wrap;
  justify-self: flex-start;
  align-self: center;
  font-size: 12px;
  color: #4a4a4a;
  overflow: hidden;
}

.caregiverDetails .caregiver-details-bold-text {
  display: inline-block;
  font-weight: bold;
  white-space: pre-wrap;
  justify-self: flex-start;
  align-self: center;
  font-size: 12px;
  color: #4a4a4a;
}

.caregiverDetails .transit-text {
  position: relative;
  bottom: 8px;
  left: 4px;
}

.caregiverDetails .caregiver-details-text-not-checked {
  display: inline-block;
  font-weight: normal !important;
  white-space: pre-wrap;
  justify-self: flex-start;
  align-self: center;
  font-size: 12px;
  color: #9b9b9b;
}

.caregiverDetails .accumulative-children-header {
  align-self: start !important;
  margin-top: 3px;
}

.caregiverDetails .caregiver-details-text.pref-text,
.caregiverDetails .caregiver-details-text-not-checked.pref-text {
  margin-left: 60px;
  white-space: nowrap;
}

.caregiverDetails .travelImage {
  justify-self: flex-start;
  align-self: center;
}

.caregiverDetails .checked-preference {
  justify-self: flex-end;
  align-self: center;
  margin-right: 30px;
}

.caregiver-details-tab-main-container,
.caregiver-details-pref-main-container {
  display: grid;
  width: 410px;
  margin-left: -75px;
  grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
  grid-template-columns: 1fr;
}

.caregiver-details-info-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 200px 1fr;
  margin-bottom: 1rem;
}

.caregiver-details-gender-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 200px 50px 1fr;
  margin-bottom: 1rem;
}

.caregiver-details-status-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 200px 20px 1fr;
  margin-bottom: 1rem;
}

.caregiver-details-tab-main-container .caregiver-details-tab-gender-image {
  display: inline-block !important;
  justify-self: flex-start;
  align-self: center;
  margin: 0 !important;
}

.caregiver-details-tab-main-container .row {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 200px 1fr;
  margin-bottom: 1rem;
}

.caregiver-details-tab-main-container .row > .eleven.wide.column > .ui.tiny.header {
  margin: 0;
}

.caregiver-details-tab-main-container .caregiver-details-separator,
.caregiverDetails .caregiver-details-separator {
  margin: 0 0 1rem 45px;
  border: 0.5px solid #e3e3e3 !important;
  width: 345px;
}

.caregiver-details-tab-main-container
  .row
  > .eleven.wide.column
  > .ui.tiny.header
  > .ui.list
  .list
  > .item:first-child,
.ui.list > .item:first-child,
ol.ui.list li:first-child,
ul.ui.list li:first-child {
  padding: 0.21428571em 0;
}

#caregiver-details-view-edit-button {
  max-width: 180px;
  white-space: nowrap;
  font-size: 12px;
}
