.system-extensions-tile{
  max-width: 38em;
  margin-top: 1.5em;
  margin-right: 1.5em;
}

.system-extension-body-text{
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px !important;
}

.system-extension-sandata-body-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #9b9b9b;
  margin-top: 10px !important;
}

.system-extension-header-Text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 1.5rem !important;
  margin-bottom: 0px;
  font-weight: 900 !important;
}

.extensions-hr {
  position: relative;
  width: 100% !important;
  height: 2px;
  background-color: #9b9b9b22;
  margin: 0.5rem 0 0.5rem 0 !important;
  border: 0 !important;
  border-radius: 10px;
}

.extensions-check-box-field{
  margin-top: 2rem;
}