.description-small {
  font-size: 12px;
  color: #595959;
}

.description {
  font-weight: 700;
}

.disabled {
  opacity: 0.5;
}

.enabled-link {
  font-size: 12px;
  color: #4183c4;
  cursor: pointer;
}

.disabled-link {
  font-size: 12px;
  display: inline-block;
  pointer-events: none;
}

.disabled-checkbox {
  display: inline-block;
  pointer-events: none;
}

.general-settings-tile {
  padding: 0px;
}

.general-settings-header {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-image {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.agency-logo {
  max-height: 80px;
  max-width: 160px;
  border-radius: 8px;
  display: block;
  margin: -45px auto auto auto;
}

.coming-soon-logo {
  max-height: 100px;
}

.docu-sign-logo {
  height: 20px;
  margin-top: 5px;
}

.add-image-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #69db8f;
  font-size: 17px;
}

.add-header-image-container {
  position: absolute;
  top: -10px;
  right: -10px;
}

.add-logo-container {
  position: absolute;
  top: -10px;
  left: 54%;
}

.modal-header {
  background-color: #e3e3e3;
  height: 130px;
  padding: 40px;
}

.modal-content {
  margin: 30px;
}

.modal-footer {
  background-color: #e3e3e3;
  padding: 20px;
  text-align: center;
}

.text-area {
  max-width: 100%;
  width: 100%;
  color: #0d0d0d;
  background-color: #ffffff;
}

.edit-modal {
  top: 10% !important;
}

.ui.modal.edit-modal>.close {
  top: 1.0535rem;
  right: 1rem;
  color: rgba(0,0,0,.87);
}

.options-cell-value {
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 16ch;
  overflow-wrap: break-word;
}

.name-cell-value {
  max-width: 16ch;
  overflow-wrap: break-word;
}

.add-custom-field-row {
  text-align: center;
}

.pending-save-field {
  background-color: #e5f6fa;
}

.action-icon {
  color: #e3e3e3;
  cursor: pointer;
}

.url-edit-link {
  margin-left: 20px;
}

.tab .Applicant-table {
	color: #808080;
}

.infoTextDate {
  font-size: 1em;
  font-weight: bold;
  color: #364967;
  margin-right: 5px;
}
