

.ui.container.multiShiftBar {
    margin-left: 0!important;
    background-color: #EFEFEF;
    padding-top: 0!important;
    padding-bottom: 0!important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
}

.multiShiftBar .row {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.multiShiftBar .cell {
    float: left;
    text-align: left;
    padding: 0.5rem;
    font-size: 0.8em;
    color: #2F4864;
    font-weight: bold;
}

.multiShiftBar .cell.divided {
    border-right: 1px solid #D9D9D9;
}

.multiShiftBar .cell label {
    font-size: 0.9em;
    color: gray;
}

.multiShiftBar .cell .clientName {
  min-height: 2.5em;
  margin-top: 6px;
}

.multiShiftBar .cell .row.value {
  margin-top: 6px;
  min-height: 2.5em;
  text-align: center;
}

.multiShiftBar .close {
    float: right;
    color: #4A4A4A;
    padding: 0.5rem;
    margin-right: 1rem;
}

.multiShiftBar .close:hover {
    cursor: pointer;
}

.multiShiftBar .header {
    padding: 1.5rem;
    background-color: #2F4864;
    color: white;
    text-align: center;
    font-size: 1em;
    line-height: 1.2;
    display: flex;
    justify-content: center;
}

.multiShiftBar .avatar {
    margin: 0!important;
    margin-top: 0.4em!important;
}

.multiShiftBar .client {
    min-width: 120px;
    max-width: 180px;
    flex-grow: 1;
}

.multiShiftBar .shifts {
    width: 240px;
    background-color: #E7E7E7;
}

.multiShiftBar .shifts .selector{
    padding-right: 0!important;
    padding-left: 0!important;
}

.multiShiftBar .shifts .round .field {
    margin: 0.2em 0!important;
}

.multiShiftBar .shifts .round .checked-field {
    margin: 0.2em 0!important;
}

.multiShiftBar .innerGrid .ui.grid {
    margin: 0!important;
    width: 400px;
}

.multiShiftBar .cell.divided.innerGrid.shifts {
    width: 400px;
}

.multiShiftBar .round {
    top: 0.5em;
    width: 20px!important;
}

.multiShiftBar .cell.saveButton {
    flex-grow: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multiShiftBar button {
    font-size: 1.0em!important;
}

.wrapper {
    position: relative;
    width: 100%;
}

.hiddenElement {
  display: none;
}

.floatTopRight {
    position: absolute;
    top: -10px;
    right: -10px;
}

.tinyCloseButton {
    width: 21px;
    height: 21px;
}

.multiShiftBar .ui.labeled.input > .ui.label{
    border-width: 1px;
    border-color: #dededf;
}

.multiShiftBar .ui.labeled.input > .ui.dropdown > .dropdown.icon{
    float: left;
    margin-left: -2px;
    bottom: 8px;
    padding: 2px;
}

.multiShiftBar .round > .dbox {
    position: relative;
    left: 50px;
    bottom: 4px;
}