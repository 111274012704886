.clientDetails {
  max-width: 500px;
  min-width: 500px;
}

.clientDetails .modalHeaderGrid {
}
.clientDetails .detailGrid {
  width: 410px;
  margin-left: -75px;
}

#client-details-column {
  max-height: 55vh;
  width: 345px;
}

.clientDetails .detailGrid .row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.clientDetails .ui.grid > .row > [class*='eleven wide'].column .ui.tiny.header {
  color: #4a4a4a !important;
}

.clientDetails .detailGrid .row:first-child {
  margin-top: 1em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.clientDetails .headerGrid {
  margin-top: -1.25rem !important;
}

.ui.modal > .header {
  border-bottom: none;
  border-top-left-radius: 5px;
}

.clientDetails > .actions {
  border-top: none !important;
  background-color: #f2f2f2 !important;
}

.modal-close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 485px;
  top: -15px;
  width: 30px;
  height: 30px;
}

.clientDetails .headerGrid .header {
  font-size: 14px !important;
  font-weight: normal;
  color: #364866;
  letter-spacing: 0.2em;
  margin-top: 1em;
  margin-bottom: 0em;
  text-transform: uppercase;
}

.clientDetails .headerGrid .id-text {
  font-weight: normal;
  font-size: 0.7em !important;
  color: #a9adb4;
}

.clientDetails .headerGrid img:nth-child(1) {
  width: 100% !important;
  margin-top: -53px;
}

.clientDetails .headerGrid .headerPic {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.clientDetails .headerGrid .headerPic .avatar {
  margin-top: -5em !important;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.clientDetails .headerGrid .column:nth-child(2) {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.prefsLists .headerText {
  color: black !important;
}

.prefsLists .infoText {
  padding-bottom: 1.5em;
  color: grey !important;
}

.caregiverHistory .ui.header {
  color: grey;
  font-weight: bold;
  padding: 1rem;
}

.assignedCaregiver {
  padding-top: 0 !important;
  width: 100%;
}

.assignedCaregiver .ctr {
  width: 70% !important;
  margin: auto;
  border: none;
  max-width: 250px;
  height: 60px;
  box-shadow: 0px 1px 3px #0000001a;
  border-radius: 5px;
}

.assignedCaregiver .image {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.assignedCaregiver .name-discipline-container {
  position: relative;
  top: 5px;
  left: 10px;
}

.assignedCaregiver .name {
  color: #464247;
  text-align: start;
  margin-left: 1rem;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignedCaregiver .discipline {
  color: #7e90ac;
  text-align: start;
  font-size: 10px;
  margin-left: 1rem;
}

.clientDetails .detailGrid .row:first-child {
  margin-top: 1em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.clientDetails .detailGrid .notesRow {
  border-top: none !important;
}

.clientDetails .detailGrid .notesRow {
  border-top: none !important;
}

.clientDetails .detailGrid .notes {
  background-color: #f1f1f1;
  min-height: 5em;
}

.clientDetails .detailGrid.lastRowWithBorder .row:last-child {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  padding-bottom: 1rem !important;
}

.clientDetails .detailGrid.noFirstBorder .row:first-child {
  border-bottom: none !important;
}

.clientDetails .ui.tiny.left.floated.header {
  color: #9b9b9b;
  font-size: 10px;
}

.clientDetails .ui.tiny.header {
  font-weight: normal !important;
  letter-spacing: 0.12rem !important;
  font-size: 10px;
}

.clientDetails .ui.tiny.header > .list .item {
  font-size: 12px;
  color: #4a4a4a;
  padding-left: 10px !important;
}
.clientDetails .ui.tiny.header > .segment {
  background-color: transparent;
}

.clientDetails .ui.tiny.header > span .icon-gray {
  color: #a9adb4 !important;
  font-size: 10px !important;
}

.dulCBu {
  font-size: 14px !important;
  color: #4a4a4a !important;
}

.byrcpk {
  font-size: 12px;
  color: #a9adb4;
  font-weight: normal;
}

.clientDetails .ui.button.care-green {
  height: 40px !important;
  font-size: 12px !important;
  color: #417505;
}

.prefsLists .noBorderFirstRow .row:first-child {
  border-top: 0 !important;
}

.prefsLists .ui.tiny.header {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.12rem;
  color: #4a4a4a;
  text-transform: uppercase;
}

.prefsLists .ui.multiple.dropdown > .label {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #4a4a4a !important;
  font-size: 12px !important;
  font-weight: normal !important;
  float: left !important;
  margin-right: 50px !important;
}

.ui.small.header {
  font-weight: normal;
  letter-spacing: 0.12rem;
}

.prefsLists .grid-left {
  background: red;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}

.clientEditor form {
  padding-right: 3em;
  padding-left: 3em;
}

.clientEditor hr {
  clear: both;
  margin-left: -2em;
  margin-right: -2em;
  border-color: red;
  background-color: #e3e3e3;
  color: #e3e3e3;
  height: 1px;
  border: 0;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.clientEditor hr:nth-of-type(0),
hr:nth-of-type(1),
hr:nth-of-type(2),
hr:nth-of-type(3),
hr:nth-of-type(4),
hr:nth-of-type(7) {
  border: 0.5px solid #d9d9d9;
  /* margin-top: 2em; */
}

.clientEditor .ui.form input[type='text'] {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  letter-spacing: 1px;
}

.clientEditor .ui.form .field > .selection.dropdown,
textarea {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  letter-spacing: 1px;
}

.clientEditor .ui.form .field > .selection.dropdown.error {
  background-color: #fff6f6;
  color: #eac3c2;
}

.clientEditor .ui.form .field > .selection.dropdown.error .text {
  background-color: #fff6f6;
  color: #eac3c2;
}

.clientEditor .form > label {
  color: #4a4a4a;
  letter-spacing: 1px;
}

.clientEditor .ui.form .field > textarea {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  letter-spacing: 1px;
}

.clientEditor .ui.form .field > label,
label,
textarea {
  color: #9b9b9b;
  letter-spacing: 1px;
}

.clientEditor .ui.form textarea {
  margin-top: 1em;
}

#notesLabel {
  margin: 1em;
  padding-bottom: 1em;
  font-family: Lato;
  font-size: 1em;
  letter-spacing: 1.5px;
}

.ui.grid.addClientAddressGrid > .row:not(:last-child) {
  margin-bottom: -2em !important;
}

.ui.grid > .row > .column.smallLeftPadding,
.ui.form > .fields > .wide.field.smallLeftPadding {
  padding-left: 0.2em;
}

.ui.grid > .row > .column.smallRightPadding,
.ui.form > .fields > .wide.field.smallRightPadding,
.smallRightPadding {
  padding-right: 0.2em;
}

.careAddForm .ui.form .hideLabel.field > label {
  width: 0;
  height: 0;
}

.careAddForm .ui.form .required.field > label:after {
  content: '';
  margin: 0;
  width: 0;
  height: 0;
}

.careAddForm label.preferencesHeaderText {
  color: #4a4a4a;
  padding-left: 3em;
  font-weight: bold;
}

.careAddForm .preferencesLine {
  margin-left: 3em;
  margin-right: 3em;
}

.clientEditor .ui.modal > .actions {
  border-top: 0px;
  background-color: #f2f2f2;
}

.clientEditor .ui.image {
  position: absolute;
  right: -1.4em;
  top: -1.2em;
}

#clientBirthDate.inputValidationError {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
  font-size: 0.83rem;
}

.clientEditor .ui.form .grouped.fields {
  padding-right: 3em;
  padding-left: 3em;
}

.clientEditor .ui.button:disabled {
  color: #9b9b9b;
  background-color: #d9d9d9;
}

.clientEditor .ui.button {
  background-color: #69db8f;
  color: #417505;
}

.clientEditor .form > label.formNoticeLabel {
  display: block;
  text-align: center;
  color: #f7a9b1;
  padding-bottom: 1em;
  font-family: Lato;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .item {
  background-color: #ffffff;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .item .text {
  background-color: #ffffff;
  color: #0d0d0d;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .item:hover {
  background-color: #f2f2f2;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .item:hover .text {
  background-color: #f2f2f2;
  color: #0d0d0d;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .selected.item {
  background-color: #f7f7f7;
}

.clientEditor .ui.form .field > .ui.dropdown > .menu > .selected.item .text {
  background-color: #f7f7f7;
  color: #0d0d0d;
}

.clientEditor .editorBottomLabel {
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
