.hrapp .underline input {
  border-width: 0 0 1px 0;
  border-radius: 0px;
  background-color: transparent;
  margin-top: -8px;
}

.hrapp .round-search-input .ui.input input {
  height: 30px;
  background: transparent;
  border: none;
  border-radius: 24px;
}

.hrapp .round-search-dropdown-border {
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  background: #fff;
  padding: 4px 2px 4px 2px;
  width: 85%;
  float: left;
  margin-bottom: 20px;
}

.hrapp .round-search .button {
  background-color: #04a5d5;
  color: #364866 !important;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.hrapp .circle {
  border-style: solid;
  border-width: 2px;
  border-radius: 1024px;
  width: 150px;
  height: 150px;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.hrapp .panel {
  padding: 24px;
  height: 700px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 16px #00000044;
}

.hrapp .covid-panel {
  padding: 14px;
  min-height: 70px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 16px #00000044;
  margin-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hrapp .covid-panel > span > h3 {
  color: #364967;
  margin: 0; 
  font-size: 14px; 
}

.hrapp .covid-panel > span > p {
  color: #9B9B9B;
  font-size: 12px;
}

.hrapp .covid-panel > span > p > bold {
  color: #F16778;
  font-size: 14px;
  font-weight: bold;
}

.hrapp .small {
  font-size: 10px;
}

.hrapp .normal {
  font-size: 12px;
}

.hrapp .medium {
  font-size: 14px;
}

.hrapp .gray {
  color: #aaa;
}

.hrapp .black {
  color: #000;
}

.hrapp .selectedTab {
  background: #555 !important;
  color: #eee !important;
}

.hrapp .selectedTab:hover {
  background: #444 !important;
  color: #eee !important;
}

.hrapp datalist {
  display: none;
}

.hrapp .trainingEvent .subTab {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.hrapp .unselectableLink {
  color: #04a5d5;
  float: left;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.hrapp .unselectableLink:hover {
  color: #3cb9df;
}

.hrapp .trainingEvent .subTab:hover {
  background-color: #fafafa;
}

.hrapp .trainingEvent .subTab.selected {
  border-style: solid;
  border-width: 0 0 4px 0;
  border-color: #09b4e7;
}

/* specifically hide the arrow on focus */
.hrapp input::-webkit-calendar-picker-indicator {
  display: none;
}

.hrapp .hr-calendar .rbc-day-slot .rbc-event {
  background-color: #ffffffcc !important;
  box-shadow: 4px 4px 14px #00000066;
  border-width: 2px !important;
}

.hrapp .hr-calendar .rbc-day-slot .rbc-event:hover {
  z-index: 100 !important;
  background-color: #ffffff !important;
  box-shadow: 6px 6px 16px #00000044;
}

.hrapp .hr-calendar .rbc-time-gutter {
  font-size: 10px;
  font-weight: bold;
  color: #9b9b9b;
  text-align: end;
  background-color: #fff;
}

.hrapp .hr-calendar .rbc-time-header-gutter {
  background-color: #fff;
}

.hrapp .panel .rbc-timeslot-group {
  height: 40px;
}

.hrapp .hr-calendar .rbc-time-header .rbc-time-header-cell {
  font-size: 18px;
  color: #9b9b9b;
  height: 33px;
  line-height: 25px;
}

.hrapp .hr-calendar .rbc-month-view .rbc-month-header {
  font-size: 18px;
  color: #9b9b9b;
  line-height: 25px;
  background-color: #fff;
}

.hrapp .hr-calendar .rbc-time-view {
  background-color: #fff;
}

.hrapp .hr-calendar .rbc-label {
  padding: 0 18px 0 15px;
}

.hrapp .hr-calendar h3 {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: normal;
  line-height: 3px;
  margin: 0;
  position: absolute;
  left: 160px;
}

.hrapp .hr-calendar .angleButton {
  color: #c8cbd3;
  background: #f7f7f7;
  padding: 5px 0 5px 5px;
}

.hrapp .hr-calendar .viewMode {
  color: #c8cbd3;
  background: #f7f7f7;
  padding: 7px 10px;
  font-weight: normal;
}

.hrapp .hr-calendar .viewMode.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hrapp .hr-calendar .viewMode:hover {
  color: #4a4a4a;
}

.hrapp .hr-calendar .dot {
  height: 6px;
  width: 6px;
  background-color: #f7f7f7;
  border-radius: 50%;
  display: inline-block;
}

.hrapp .hr-calendar .todayButton {
  font-size: 16px;
  background: #e6e6e6;
  padding: 7px 10px;
}

.hrapp .hr-calendar .angleButton:hover {
  color: #4a4a4a;
}

.hrapp .hr-calendar .ui.icon.button > .icon {
  font-size: 24px;
  font-weight: 700;
  vertical-align: text-top;
}
.hrapp .rbc-off-range {
  color: #ccc !important;
}

.hrapp .noActivation img {
  margin-top: 40px;
}

.hrapp .noActivation p {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-top: 18px;
}

.hrapp .trainingEvent .ui.items > .item > .image > img {
  border-radius: 50%;
  object-fit: cover;
  height: 35px !important;
  width: 35px !important;
}

.hrapp .ui.labeled.input > .ui.dropdown > .dropdown.icon  {
  float: left;
  margin-left: 0px;
  bottom: 11px;
  padding: 2px;
}

.ui.scrolling.modal.transition.visible.active.hrapp.create-event-modal {
  max-width: 680px;
  transform: translate(0%, 0%);
}

.ui.modal.transition.visible.active.hrapp.create-event-modal {
  max-width: 680px;
  margin: 0;
  transform: translate(-50%, -50%);
}

.ui.scrolling.modal.transition.visible.active.hrapp.create-event-modal > .close.icon,
.ui.modal.transition.visible.active.hrapp.create-event-modal > .close.icon {
  top: 1.5rem;
  right: 1rem;
  color: black;
}

.create-event-header{
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  color: #4a4a4a;
  padding: 1rem 0;
}

.create-event-sub-header{
  font-size: 12px;
  font-weight: bolder;
  color: #4a4a4a;
  text-transform: uppercase;
}

.create-event-dark-text {
  font-size: 12px;
  color: #4a4a4a;
}

.create-event-label {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: bold;
}

.virtual-text {
  display: inline-block;
}

.create-event-dates-container {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 4rem;
}

.create-event-hours-container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 4rem;
}

.ui.input.create-event-input > input {
  padding: 0.5rem;
  width: 50px;
  height: 25px;
}

.create-event-small-gray-text {
  font-size: 11px;
  color: #9b9b9b;
}

.green-complete-text {
  display: inline-block;
  font-size: 11px;
  color: #6ed992;
  text-align: center;
}

.black-status-text {
  display: inline-block;
  font-size: 11px;
  color: #4a4a4a;
  text-align: center;
}

.certificate-validation-text {
  margin: 0 0 1rem 0;
  max-width: 540px;
  font-size: 12px;
  color: #e3e3e3;
  text-align: center;
}

.certificate-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 540px;
}

.declined-info {
  display: flex;
  justify-content: space-between;
}

.declination-remove-button {
  position: relative;
  bottom: 3px;
}

.compliance-completed-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.completed-text {
  position: relative;
  color: #70DC94;
  margin: 0 0 0 35px;
  font-size: 11px;
  top: 10px;
}

.round-grey-question {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #C4C4C4;
  color: #ffffff;
  display: flex;
  place-content: center;
}

.round-grey-question > i {
  margin: 0;
}

.COVID-buttons-container {
  display: flex;
  align-content: center;
  justify-content: flex-start;
}

.declined-complete-footer {
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

.incomplete-warning {
  font-size: 14px;
  color: #f5a623;
  font-style: italic;
  margin-left: 1rem;
}

.ui[class*="middle aligned"].grid>.row>.column.go-event-button-container {
  display: flex;
  flex-direction: row;
  place-items: center;
  justify-content: space-around;
}

.attendees-info-text {
  font-size: 11px;
  color: #4a4a4a;
}

.hrapp .event-attendees-container {
  overflow: scroll;
  margin-left: 1rem;
  max-height: 370px;
}

.hrapp .event-attendees-completed-container {
  overflow: scroll;
  max-height: 320px;
}


.hrapp .event-attendees-signed-off-container {
  overflow: scroll;
  max-height: 540px;
}

.hrapp .attendees-completed-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hrapp .attendees-completed-column > p {
  margin: 1rem;
}

.hrapp .event-details {
  width: 100%;
  overflow: scroll;
  max-height: 300px;
  padding: 0 1rem 1rem 0;
}

.hrapp .event-details-signed-off {
  width: 100%;
  overflow: scroll;
  max-height: 420px;
  padding: 0 1rem 1rem 0;
}
