.fillBottomMargin {
  margin-bottom: 30em;
  border: none !important;
}
#primary-contact-id,
#primary-contact-id .menu {
  min-width: 100% !important;
}

#primary-contact-manager,
#primary-contact-manager .menu {
  min-width: 100% !important;
}

.primary-contact-container {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 149px 1fr;
  grid-template-areas:
    'pCont pContName'
    'division division'
    'pContMan pContManName';
  border-bottom: 1px solid #9b9b9b33;
  justify-content: stretch;
  padding: 1rem 0 1rem 0 !important;
}

.primary-contact-header {
  grid-area: pCont;
}

.primary-contact-name {
  grid-area: pContName;
}

.primary-contact-separator {
  width: 345px;
  background-color: #9b9b9b33;
  grid-area: division;
  margin: 1rem 0 1rem 0 !important;
  border: none !important;
  height: 1px;
}

.primary-contact-manager-header {
  grid-area: pContMan;
}

.primary-contact-manager-name {
  grid-area: pContManName;
}

.primary-contact-header,
.primary-contact-name,
.primary-contact-separator,
.primary-contact-manager-header,
.primary-contact-manager-name {
  align-self: center;
  justify-self: flex-start;
  margin: 0;
}

.primary-contact-container > .tiny-header {
  font-weight: normal;
  font-size: 10px;
  color: #9b9b9b;
  margin-left: 1rem;
}

.primary-contact-container > .primary-contact-text {
  font-weight: bold;
  font-size: 12px;
  color: #4a4a4a;
  margin-left: 1rem;
}

.ui.dropdown .menu > .dropdown-suspended-employee {
  color: #DADADA;
  font-size: 17px;
}
