.e-learning-main-container {
  width: 100vw;
  display: grid;
  margin-bottom: 100px;
  grid-template-columns: 220px auto;
  grid-template-rows: 90px auto;
  justify-content: stretch;
  grid-template-areas:
    'help welcome'
    'help content';
}

.e-learning-welcome {
  grid-area: welcome;
  height: 90px;
  width: calc(100% - 59px);
  box-shadow: 0 1px 0 #d9d9d9;
  margin-left: 23px;
  white-space: nowrap;
}

.e-learning-side-help-container {
  grid-area: help;
  width: 220px;
  padding-left: 14px;
  padding-top: 100px;
}

.e-learning-content {
  grid-area: content;
  min-width: 250px;
  width: 100%;
  height: 100%;
}

.e-learning-welcome-text {
  font-size: 16px;
  color: #adb1b8;
  padding-top: 30px;
  margin-left: 30px;
  display: inline-block;
}

.e-learning-welcome-name {
  display: inline;
  font-size: 16px;
  color: #364967;
  text-transform: uppercase;
}

.e-learning-help-header {
  font-size: 12px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.course-info-container {
  padding-bottom: 0;
}

.e-learning-side-help {
  width: 205px;
  font-size: 10px;
  color: #adb1b8;
  line-height: 20px;
}

.course-info-dark {
  width: 200px;
  font-size: 10px;
  color: #4a4a4a;
  line-height: 20px;
}

.e-learning-info-circle-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  grid-template-rows: auto;
  grid-gap: 25px;
  margin-left: 53px;
  margin-top: 39px;
  width: calc(100% - 59px);
  min-height: 250px;
  box-shadow: -30px 0 0 #f7f7f7, -30px 1px 0 #d9d9d9;
  padding-bottom: 30px;
}

.circle-info-container {
  width: 184px;
  margin-right: 86px;
}

.e-learning-dashboard-circle-circle {
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  outline: none;
  cursor: pointer;
}

.e-learning-dashboard-circle-num {
  font-size: 24px;
  line-height: 29px;
  color: #4a4a4a;
}

.e-learning-circle-header {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}

.e-learning-circle-info {
  width: 200px;
  height: 60px;
  font-size: 10px;
  color: #adb1b8;
  line-height: 20px;
}

.e-learning-activity-modules-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 25px;
  margin-left: 15px;
  padding-right: 23px;
  margin-top: 44px;
  width: 100%;
  min-width: 200px;
}

.e-learning-records-header {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  margin-left: 30px;
  margin-bottom: 45px;
}

.e-learning-records-tile {
  width: 100%;
  max-width: 540px;
  min-width: 430px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 60px;
}

.e-learning-load-more {
  width: 150px;
  margin-top: 10px !important;
  justify-self: center;
  align-self: center;
}

.e-learning-records-content {
  width: 100%;
  max-width: 540px;
  min-width: 180px;
}

.e-learning-activity {
  display: grid;
  width: 100%;
  max-width: 540px;
  min-width: 320px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, minmax(40px, 1fr));
  box-shadow: 0 1px 0 #d9d9d9;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-items: flex-start;
  align-items: center;
}

.e-learning-empty-activity {
  display: grid;
  width: 100%;
  max-width: 540px;
  min-width: 320px;
  grid-template-rows: repeat(auto-fit, minmax(40px, 274px));
  grid-template-columns: 1fr;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-items: center;
  align-items: center;
}

.e-learning-activity:first-child {
  padding-top: 0;
}

.completion-completed,
.completion-started,
.activity-date,
.activity-name {
  margin: 0;
}

.activity-name {
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
  margin-left: 18px;
}

.completion-completed,
.completion-started {
  text-transform: capitalize;
  font-size: 12px;
  font-style: italic;
  margin-left: 48px;
}

.completion-completed {
  color: #69db8f;
}
.completion-started {
  color: #04a5d5;
}

.activity-date {
  font-size: 12px;
  color: #4a4a4a;
  justify-self: flex-end;
  margin-right: 28px;
}

.quick-start-button {
  width: 60px;
  height: 25px !important;
  justify-self: flex-end;
  margin-right: 28px !important;
  font-size: 10px !important;
  text-align: center !important;
  color: #426b6f !important;
  letter-spacing: 0.5px !important;
  padding: 0 !important;
}

.e-learning-records-tile-quick-start {
  width: 100%;
  min-width: 500px !important;
  max-width: 540px !important;
  display: grid;
  grid-template-areas:
    'header header header'
    'bar bar  button'
    'description description description'
    'viewAll viewAll viewAll';
  justify-items: center;
  align-items: center;
}

.e-learning-records-tile-quick-start > .ui.selection.dropdown.course-language-selection {
  grid-area: language;
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: 10px !important;
}

.e-learning-records-tile-quick-start > .ui.selection.dropdown.course-language-selection > .dropdown.icon {
  top: 8px !important;
  height: 33px;
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(19% 0% 11% -10%);
}

.e-learning-quick-start-course-record-name {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;
  letter-spacing: 0.8px;
  grid-area: header;
  justify-self: flex-start;
}

.e-learning-quick-start-in-progress-bar-container {
  width: 100%;
  min-width: 226px;
  max-width: 333px;
  grid-area: bar;
  display: inline-block;
  margin: 12px;
}

#quick-start-resume-button {
  justify-self: flex-end !important;
  margin: 0 0 0 12px !important;
  grid-area: button;
}
.ui.tiny.e-learning-quick-start-in-progress-course-progress-bar {
  margin: 0 !important;
}

.ui.tiny.e-learning-quick-start-in-progress-course-progress-bar > .bar {
  background-color: #69db8f;
  height: 10px !important;
}

.ui.tiny.progress.e-learning-quick-start-in-progress-course-progress-bar.hide-progress-bar > .bar {
  background-color: transparent;
}

.e-learning-quick-start-in-progress-course-description {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  grid-area: description;
  margin: 12px 0 12px 0;
  justify-self: flex-start;
}

.e-learning-record-button {
  margin-top: 30px !important;
  grid-area: viewAll;
}

.e-learning-blocked-modal {
  font-size: 14px;
  color: #4a4a4a;
  display: grid;
  grid-template-rows: 1fr 0.5fr;
}
.e-learning-blocked-modal > img {
  place-self: center;
}

.e-learning-blocked-modal > p {
  place-self: center;
  padding: 25px;
}

@media only screen and (max-width: 600px) {
  .e-learning-main-container {
    grid-template-areas:
      'welcome welcome'
      'content content'
      'help help';
  }

  .e-learning-records-tile-quick-start {
    width: 100%;
    min-width: 300px !important;
    display: grid;
    grid-template-areas:
      'header header'
      'bar bar'
      'language button'
      'description  description'
      'viewAll viewAll';
    justify-items: center;
    align-items: center;
  }

  .e-learning-welcome {
    grid-area: welcome;
    height: 90px;
    width: calc(100% - 59px);
    box-shadow: 0 1px 0 #d9d9d9;
    margin-left: 23px;
    white-space: nowrap;
  }

  .e-learning-records-content {
    width: 100%;
    max-width: 450px;
    min-width: 180px;
  }
  .e-learning-activity-modules-container {
    margin-left: 0;
  }
}
