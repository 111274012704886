.exports-main {
  max-width: 100vw;
  padding-top: 20px;
  display: grid;
  grid-template-columns: 260px auto;
  grid-template-areas:
    'profile header'
    'profile filters'
    'profile main';
}

.exports-header {
  font-size: 16px;
  color: #364967;
  font-weight: bold;
  grid-area: header;
}

.exports-profiles {
  grid-area: profile;
}

.exports-filters {
  padding: 20px;
  grid-area: filters;
  display: grid;
  align-items: flex-end;
  grid-template-columns: repeat(5, auto);
  column-gap: 20px;
}

.exports-date-picker-label {
  margin-bottom: 20px;
}
