.reporting-setting-tile {
  max-width: 38em;
  margin-top: 1.5em;
  margin-right: 1.5em;
}

.reporting-body-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px !important;
}

.ui.tiny.header.reporting-body-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px;
  font-weight: 900 !important;
}

.ui.grid > .row.default-reporting-row {
  padding: 0 !important;
}

.inline.fields.reporting-form-group-container {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  grid-template-rows: 1fr;
  align-items: center;
}
.reporting-hr {
  position: relative;
  width: 100% !important;
  bottom: 1em !important;
}
