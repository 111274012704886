.left-rail-CG-client{
    margin-left: 8.5px;
    max-width: 16em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: space-between;
}

.left-rail-CG-client > hr {
    width: 100%;
}


.left-rail-CG-client >  hr:nth-of-type(1){
    align-self: flex-start;
    position: relative;
    top: -7px;
}

.left-rail-CG-client .subTabButton {
    max-width: 14.2em;
    align-self: center !important;
    justify-self: center !important;
}

.left-rail-client-id{
    font-weight: 200;
    font-size: 10px;
    color: #9d9d9d;
    margin: 0px;
    position: relative;
    left: 8px;
}