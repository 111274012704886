
.responsesLink {
    display: inline-block;
    text-align: center;
    margin: 0 1em;
    text-align: left;
}

.responsesLink .link {
    color: #04A5D5;
    font-weight: 400;
}

.responsesLink i {
    margin-right: 0.25em!important;
}

.responsesLink .link:hover {
    cursor: pointer;
}

.responsesLink .time {
    color: gray;
    font-size: 0.9em;
}

.partialAssignButton {
    -webkit-box-shadow: 0 0 0 1px #6ed992 inset!important;
    box-shadow: 0 0 0 1px #6ed992 inset!important;
    color: #6ed992!important;
}

.requestLink {
  float: left;
  text-align: center;
  margin: 0 1em;
}

.requestLink.left {
  text-align: left;
}

.requestLink .link {
  color: #04A5D5;
  font-weight: 400;
}

.requestLink i {
    margin-right: 0.25em!important;
}

.requestLink .link:hover {
  cursor: pointer;
}

.requestLink .time {
  color: gray;
  font-size: 0.9em;
    margin-left: 1em;
}

.requestsModal {

}

.requestsModal .shiftRow{
  font-size: 0.9em;
  font-weight: bold;
  color: gray;
  border-bottom: 1px solid lightgrey!important;
}

.requestsModal .shiftRow .date{
  color: black;
}

.requestsModal .shiftRow:last-child {
    border-bottom: 0!important;
}

.requestsModal .header { font-size: 0.9em!important;}
.requestsModal .header .main {
    font-size: 1em!important;
    font-weight: bold!important;}
.requestsModal .header .sub { color: gray}
.requestsModal .header .caregiver {margin-left: 1em}
