#survey-date-picker {
    font-size: 11px;
    border: none;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    background-image: url('../images/calendar.svg');
    background-repeat: no-repeat;
    background-position: right 5px top 5px;
    width: 120px;
    cursor: pointer;
}

.survey-no-results {
    margin-top: 40%;
    background-image: url('../images/nomatch.svg');
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
}

.survey-no-results h1 {
    font-size: 14px;
    font-weight: bold;
    color: #4A4A4A;
}

.survey-response-grid {
    display: grid;
    grid-template-columns: repeat(6, 25px);
    align-items: center;
}

.client-survey-response-grid {
    display: grid;
    grid-template-columns: repeat(8, 25px);
    align-items: center;
}

.survey-response-label {
    margin: 0 5px 0 5px;
}

.survey-response-pass {
    background-color: #6ed992;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.survey-response-fail {
    background-color: #f16778;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.survey-result-empty {
    margin-left: 10px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid grey;
}

.survey-result-pass {
    margin-left: 10px;
    background-color: #6ed992;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.survey-result-fail {
    height: 30px;
    width: 30px;
    background-image: url('../images/Attention.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.survey-result-grid {
    display: grid;
    grid-template-columns: 35px 40px;
    align-items: center;
}

.survey-export-link {
    font-size: 12px;
    color: #9B9B9B;
    background-image: url('../images/export.svg');
    background-repeat: no-repeat;
    background-position: right;
}

.survey-export-link button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.survey-export-link span {
    margin-right: 30px;
}

.survey-summary-header-grid {
    display: grid;
    grid-template-columns: 75px 285px 150px;
    align-items: center;
    text-align: start;
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
}

.survey-summary-header-grid img {
    margin-left: 15px;
}

.survey-summary-header-grid input {
    text-align: right;
}

.survey-date-header-grid span {
    font-size: 16px;
    color: #4a4a4a;
}

.survey-date-header-grid {
    display: grid;
    grid-template-columns: 5% 10% 45% 25% 10% 5%;
    align-items: center;
    text-align: center;
}

.survey-summary-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
}

.survey-summary-container {
    width: 100%;
}

.survey-summary-container-header {
    padding: 5px;
    background-color: #F1F1F1;
    margin-top: 25px;
    border-top: 1px solid #C8CBD3;
    border-bottom: 1px solid #E3E3E3;
    text-align: center;
}

.survey-summary-container-section-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid #e3e3e3;
    padding-top: 22px;
    padding-bottom: 22px;
}




.survey-summary-container-header-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
    padding-top: 8px;
    padding-bottom: 8px;
}

.survey-summary-results {
    justify-self: flex-start;
    margin-left: 20px;
}

.survey-summary-amount {
    justify-self: flex-end;
    margin-right: 20px;
}

.survey-summary-container-section {
    padding: 5px;
    font-size: 12px;
    color: #4a4a4a;
}

.survey-summary-date-row {
    margin: 1em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: left;
}

.survey-summary-amount-res {
    justify-self: center;
    margin-left: 160px;
}

.survey-summary-date-row > p {
    margin: 0;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: bold;
}

.survey-summary-result-grid {
    display: grid;
    grid-template-columns: 10px 150px;
}

.survey-summary-pass {
    background-image: url('../images/circle-green.svg');
    background-repeat: no-repeat;
    background-position: left 1px top 5px;
}

.survey-summary-fail {
    background-image: url('../images/circle-red.svg');
    background-repeat: no-repeat;
    background-position: left 1px top 5px;
}

.survey-summary-empty {
    background-image: url('../images/circle-white.svg');
    background-repeat: no-repeat;
    background-position: left 0px top 5px;
}

.survey-summary-result-column-attention {
    height: 19px;
    width: 19px;
    background-image: url('../images/Attention.svg');
    background-repeat: no-repeat;
    background-position: right top;
    padding-right: 4em;
    margin-left: 203px;
}

.survey-summary-fail span,
.survey-summary-pass span,
.survey-summary-empty span {
    margin-left: 15px;
}

.survey-summary-actions {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 100%;
    justify-items: right;
}

.survey-summary {
    width: 100%;
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 25px;
    padding: 0 !important;
}

.survey-summary-tile {
    background-color: #fff;
    box-shadow: 0 4px 16px #00000022;
    border-radius: 8px;
    width: 100%;
    max-width: 665px;
    padding: 15px;
}

.survey-summary-loading {
    display: grid;
    height: 100%;
    width: 100%;
    place-items: center;
}

.survey-summary-tile-header {
    margin-top: 1em;
    width: 100%;
    height: 4.5em;
    box-shadow: -15px 0px #ffffff, -15px 1px #e3e3e3, 15px 0px #ffffff, 15px 1px #e3e3e3;
}

.survey-summary-tile-date {
    margin-right: 50px;
}

.survey-cg-header-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 18% 82%;
    font-weight: bold;
    font-size: 16px;
    color: #364967;
    text-transform: uppercase;
    align-items: center;
}

.survey-cg-grid {
    display: grid;
    justify-items: flex-start;
    width: 100%;
}

.survey-cg-tile {
    background-color: #ffffff;
    width: 536px;
    padding: 15px 25px 25px 25px;
    border-radius: 8px;
    box-shadow: 0 0 25px #00000022;
    margin-bottom: 25px;
    float: left;
}

.survey-cg-tile-header {
    margin-top: 0.1em;
    width: 100%;
    height: 4.5em;
    box-shadow: -25px 0px #ffffff, -25px 1px #e3e3e3, 25px 0px #ffffff, 25px 1px #e3e3e3;
}

.survey-cg-date-row {
    width: 100%;
    margin-top: 1em;
    margin-left: 1em;
    display: grid;
    grid-template-columns: 50% 40% 10%;
    justify-items: left;
}

.survey-table-name {
    cursor: pointer;
    color: #4183c4;
}

.survey-covid-icon {
    height: 42px;
    width: 42px;
    margin-top: 5px;
}
