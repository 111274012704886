.fullPageContentGrid {
  padding: 15px 0 0 2vw !important;
  margin: 0 !important;
}

.fullPageContentGrid .headerColumn {
  width: 15em !important;
}

.fullPageContentGrid .tabHeader {
  width: 100%;
  font-size: 0.9em;
  font-weight: normal;
  color: #4A4A4A;
  padding-top: 0.9em;
  padding-bottom: 1.7em;
  display: grid;
  grid-template-columns: auto 1fr 0.5fr;
  align-items: center;
  justify-content: space-between;
  clear: both;
}

.fullPageContentGrid .searchForm {
  padding-top: 0.2em;
  padding-bottom: 0.9em;
}

.fullPageContentGrid .infoText {
  font-size: 1em;
  font-weight: normal;
  color: gray;
}

.fullPageContentGrid .infoTextBold {
  font-size: 1em;
  font-weight: bold;
  color: #364967;
}

.fullPageContentGrid .selectAllButtons {
  float: right;
  align-self: center;
  justify-self: flex-end;
}

.fullPageContentGrid .ui.fitted.checkbox.selected-check-box {
  margin-right: 0px !important;
}

.table.candidatesResults td {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.table.candidatesResults {
  min-width: 100em;
  width: 94.5vw;
}

.table.candidatesResults thead tr:first-child > th:first-child {
  text-align: left !important;
}

.fullPageContentGrid .greenText {
  color: #69db8f;
}

.fullPageContentGrid .redText {
  color: #f16778;
}

.fullPageContentGrid .noResults {
  padding-top: 2em;
  justify-content: center;
}

.fullPageContentGrid .loadMore {
  position: absolute;
  left: 0;
  width: 100%;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

#hr-candidates-tab{
  position: relative !important;
  right: 1.5vw !important;
}

#hr-applicants-tab .ui.bottom.attached.segment.tab,
#hr-candidates-tab .ui.bottom.attached.segment.tab{
  width: 100% !important;
  margin: 0 !important;
}

#hr-applicants-tab .ui.tabular.menu,
#hr-candidates-tab .ui.tabular.menu {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#hr-applicants-tab .ui.segment,
#hr-applicants-tab .ui.attached.tabular.menu,
#hr-candidates-tab .ui.segment,
#hr-candidates-tab .ui.attached.tabular.menu {
  border: none !important;
}

#hr-applicants-tab .ui.tabular.menu .item,
#hr-candidates-tab .ui.tabular.menu .item{
  border-radius: 0 !important;
  padding: 21.5px 19.5px !important;
  border-top: none !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

#hr-applicants-tab .ui.tabular.menu .item:first-of-type,
#hr-candidates-tab .ui.tabular.menu .item:first-of-type {
  border-top-left-radius: 5px !important;
  border-left: none !important;
}

.export-view-button {
  float: right;
  display: grid;
  grid-column: 3/4;
  justify-self: flex-end;
  align-self: center;
}

.complete-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 4em;
  background-color: #ffffff;
  box-shadow: 1em 0 #ffffff, -1em 0 #ffffff, 1em -5px 5px #f3f3f3, -1em -5px 5px #f3f3f3;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: flex-end;
  align-content: center;
}

.ui.checkbox.selected-check-box input:checked~.box:after, .ui.checkbox.selected-check-box input:checked~label:after {
  background-color: #69db8f !important;
  border-radius: .21428571rem;
  border: 1px solid #41750567;
  color:  #417505 !important;
}

#candidate-view-button {
  border: none !important;
  box-shadow: none !important;
  color: #04a5d5 !important;
}

#HR-candidates-head {
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 1.5fr 0.5fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-items: center;
}

#HR-candidates-head > h3 {
  position: relative;
  justify-self: flex-start;
  top: 23px;
}

#HR-candidates-head > span {
  position: relative;
  justify-self: flex-end;
  top: 20px;
}

#HR-candidates-head > form > div {
  top: 25px;
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.1fr 0.2fr;
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-items: center;
}

#HR-candidates-head #clientsSearchPrimaryContactsManagerDropdown .default.text,
#HR-candidates-head #clientsSearchPrimaryContactsDropdown .default.text {
  font-size: 10px;
  position: relative;
  top: 3px;
  margin: 0 0 2px 0 !important;
}

.hr-in-tab-button-container {
  display: flex;
  position: absolute;
  top: 32px;
  right: 14px;
  justify-content: space-between;
  align-content: center;
  z-index: 2;
}

.hr-in-tab-button-container > p {
  margin: 0 8px 0 8px;
  color: #9b9b9b;
}

.hr-in-tab-button-container > .load-more-container {
  width: 100px;
  position: relative;
  top: -8px;
}

.hr-in-tab-button-container > .load-more-container > div > button {
  font-size: 14px !important;
  font-weight: bold !important;
  border: 1.5px solid #04a5d5 !important;
  border-radius: 10px;
  padding: 10px !important;
}

.hr-in-tab-button-container .ui.centered.image.loaderImage {
  position: relative;
  top: -10px;
}
