.fullPageContentGrid {
  padding-left: 2vw!important;
  padding-right: 2vw!important;
  padding-top: 15px!important;
  width: 96vw;
}

.fullPageContentGrid .headerColumn {
  width: fit-content!important;
}

.fullPageContentGrid .tabHeader {
  font-size: 0.9em;
  font-weight: normal;
  color: gray;
  padding-top: 0.9em;
  padding-bottom: 1.7em;
}

.fullPageContentGrid .infoText {
  font-size: 1em;
  font-weight: normal;
  color: gray;
}

.fullPageContentGrid .infoTextBold {
  font-size: 1em;
  font-weight: bold;
  color: #364967;
}

.table.changeLogResults td {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.table.changeLogResults {
  min-width: 100em;
}

.fullPageContentGrid .greenText {
  color: #69DB8F;
}

.fullPageContentGrid .redText {
  color: #F16778;
}

.fullPageContentGrid .noResults {
  padding-top: 2em;
}

.change-logs .ui.tiny.disabled.button.care-green.shift-log-export {
    font-size: 12px;
    background-color: #e3e3e3 !important;
    color: #626262 !important;
}

.change-logs .ui.tiny.button.care-green.shift-log-export {
  color: #346d47 !important;
}

.change-logs .ui.tiny.button.care-green.shift-log-export:hover {
  background-color: #5ec982 !important;
  color: #2f6441 !important;
}

.fullPageContentGrid .loadMore {
  position: absolute;
  left: 0;
  width: 100%;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}

#change-log-tab .ui.tabular.menu {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#change-log-tab .ui.segment,
#change-log-tab .ui.attached.tabular.menu {
  border: none !important;
}

#change-log-tab .ui.tabular.menu .item{
  border-radius: 0 !important;
  padding: 21.5px 19.5px !important;
  border-top: none !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

#change-log-tab .ui.tabular.menu .item:first-of-type{
  border-top-left-radius: 5px !important;
  border-left: none !important;
}

#change-log-tab .ui.tabular.menu .active.item{
  color: #364967 !important;
}

#startDate,
#endDate {
  background-image: url('../images/icon_calendar.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 5px top 5px !important;
  font-size: 11px;
  box-shadow: 0px 1px 2px #c9c9c9 !important;
  border: none;
}

.csv-shift-modal-header.header {
  margin: 2rem 0 1rem 0;
  position: relative;
  text-align: center;
  top: -16px;
  font-family: Lato, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #364866;
}

.csv-shift-modal-text{
  margin: 1rem 0 1rem 0;
  text-align: center;
  font-family: Lato, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #4A4A4A;
  font-size: 12px;
  font-weight: normal;
}

.ui.tiny.button.csv-shift-modal-button {
  color: #426B6F;
  background-color: #69DB8F;
  width: 70px;
  height: 40px;
  position: relative;
  left: 225px;
}