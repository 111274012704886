.alertsContainer {
  top: 20px;
  right: 20px;
  position: fixed;
  z-index: 10000;
  width: 33%;
  font-size: 0.9em;
}

.alertBox {
  z-index: 10000;
  background-color: red;
  color: white;
  -webkit-box-shadow: 2px 2px 3px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 2px 2px 3px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 2px 2px 3px 0px rgba(50, 50, 50, 0.75);
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 0px solid #000000;
  overflow: hidden;
  position: relative;
  margin-top: 0.5em;
}

.alertsContainer .success {
  background-color: #4a4a4a;
  color: #9b9b9b;
}

.alertsContainer .error {
  color: white;
  background-color: #4a4a4a;
}

.alertBox .img {
  position: absolute;
  padding: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
}

.alertBox .img .avatar {
  border: 2px solid white;
  height: 35px !important;
  width: 35px !important;
  object-fit: cover;
}

.alertBox .msg {
  position: relative;
  padding: 1rem;
  float: none;
  overflow: hidden;
  min-height: 50px;
  width: 80%;
}
.alertBox .msg b {
  color: white;
}

.alertBox .img + .msg {
  width: 65%;
  margin-left: 50px;
  min-height: 70px;
}

.alertBox .action {
  position: absolute;
  border-left: 1px solid grey;
  float: right;
  width: 20%;
  max-width: 100px;
  min-width: 80px;
  text-align: center;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.alertBox .action {
  color: white;
}

.alertBox .action .one {
  position: absolute;
  top: 0;
  bottom: 50%;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid grey;
}

.alertBox .action .two {
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.alertBox .action .single {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.alertBox .img div > *,
.alertBox .action .btn > * {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.alertBox .action:hover {
  cursor: pointer;
}

.alertBox .action .btn {
}

.alert-enter {
  opacity: 0.01;
}

.alert-enter.alert-enter-active {
  opacity: 1;
  transition: 700ms;
}

.alert-leave {
  opacity: 1;
  height: 3rem;
}

.alert-leave.alert-leave-active {
  opacity: 0.01;
  height: 0;
  transition: 700ms;
}
