.compliance div.infoPanel {
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
}

.compliance div.listElement {
  position: relative;
  padding: 16px;
  font-size: 12px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: #bbb;
  border-style: solid;
}

.compliance div.listElement .editWorkHistoryItem {
  position: absolute;
  right: 16px;
  top: 16px;
  display: none;
}
.compliance div.listElement:hover .editWorkHistoryItem {
  display: block;
}

.compliance .smallGray {
  font-size: 10px;
  color: #9b9b9b;
  margin: 0 0 0.8em;
}

.compliance .ui.input input {
  font-size: 11px !important;
  color: #4a4a4a;
}

.compliance .small {
  font-size: 10px;
  margin: 0 0 0.8em;
}

.compliance .listElement .large {
  font-size: 14px;
}

.compliance .unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.compliance .iconImage {
  height: 12px !important;
  width: 12px !important;
  margin-right: 12px;
  margin-top: -1px;
}

.compliance .certificationIcon {
  height: 38px !important;
  width: 34px !important;
}

.compliance .checkIcon {
  height: 40px !important;
  width: 40px !important;
}

.compliance .exclusionsImage {
  height: 50px !important;
  width: 50px !important;
  margin-left: auto;
  margin-right: auto;
}

.compliance .center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.compliance .centerVertical {
  vertical-align: middle;
}

.compliance .red {
  color: #f16778;
}

.compliance .green {
  color: #6ed992 !important;
}

.compliance .bold {
  font-weight: bold;
}

.compliance .blue {
  color: rgb(79, 127, 216);
}

.compliance .blue:hover {
  color: rgb(120, 158, 230);
}

.compliance .smallBtn {
  margin: -12px;
}

.compliance .achievementIcon {
  height: 32px !important;
  width: 32px !important;
}

.compliance .mobile-health-container {
  height: 100px;
  width: 100%;
  min-width: 540px;
  background-color: #ffffff;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 177px auto;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
  margin-bottom: -25px;
}

.compliance .mobile-health-logo {
  align-self: center;
  justify-self: flex-start;
  grid-column: 1 /2;
  margin-left: 11px;
  box-shadow: 14px 14px #ffffff, 14px -15px #ffffff, 15px 14px #e3e3e3, 15px -15px #e3e3e3;
}

.compliance .mobile-health-text-container {
  align-self: center;
  justify-self: flex-start;
  grid-column: 2 /3;
  margin-left: 24px;
}

.compliance .mobile-health-text {
  margin: 0 !important;
  padding: 5px;
}

.compliance .tileHeader {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
}

.compliance input {
  padding: 4px !important;
  border-width: 0 0 1px !important;
  border-radius: 0px !important;
  background: transparent !important;
}

.compliance .dateInput input {
  padding: 4px !important;
  border-width: 0 0 1px !important;
  border-radius: 0px !important;
  width: 8rem;
  padding-bottom: 0px;
}

.compliance .errorInput input {
  color: #f16778;
  border-color: #f16778;
  border-width: 1px 1px 1px 1px !important;
  border-style: solid;
  border-radius: 4px !important;
}

.compliance .ui.input.error input {
  color: #f16778;
}

.compliance .not-editable-input {
  color: #000000de;
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
}

.compliance .not-editable-input-error {
  color: #f16778;
  font-size: 10px;
  font-weight: 500;
}

.compliance .input-date {
  color: #000000de;
  font-size: 11px;
  font-weight: 500;
}

.compliance .complianceDocumentsContainer {
  line-height: 100px;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem {
  width: 100px;
  line-height: 100px !important;
  text-align: center !important;
  vertical-align: middle !important;
  float: left;
  margin: 8px;
  background-color: #eee;
  position: relative;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem:hover {
  background-color: #f2f2f2;
  outline: 1px #666 solid;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem.disabled {
  background-color: #dadada;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem p {
  line-height: 100px;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem .documentRemoveButton {
  float: right;
  position: absolute;
  top: -8px;
  right: -12px;
  z-index: 1000;
  display: none;
}

.compliance .complianceDocumentsContainer .complianceDocumentItem:hover .documentRemoveButton {
  display: inline;
}

.compliance .errorDropdown {
  border-color: #f16778 !important;
}

.compliance .compliance-dropdown > i {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 14% -10%);
}

.workHistoryModal {
}

.workHistoryModal .ui.image {
  position: absolute;
  right: -1.4em;
  top: -1.2em;
}

.workHistoryModal .companyNameRow input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  border-color: black;
  text-align: center;
}

.workHistoryModal .row.subHeaderRow {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
}

.workHistoryModal .react-datepicker-wrapper input {
  background-image: url('../images/icon_calendar.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 5px top 5px !important;
}

.workHistoryModal .datesOfEmployment {
  display: flex;
  align-items: baseline;
}

.workHistoryModal .datesOfEmployment > :nth-child(1) {
  width: 30%;
}
.workHistoryModal .datesOfEmployment > :nth-child(2) {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.workHistoryModal .datesOfEmployment > :nth-child(3) {
  width: 30%;
}

.add-service-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  align-content: center;
}

.add-service-training-item-text {
  width: 95%;
  height: 25vh;
  display: grid;
  justify-content: center;
  align-content: center;
  margin-left: 3vw;
}

.add-service-training-item {
  width: 100%;
  padding-left: 100px;
  padding-right: 50px;
  display: table;
}

/* new compliance blocks */

.compliance div.completion-edit-panel {
  background: #f7f7f7;
  font-size: 11px;
  border-radius: 6px;
  margin: 20px 40px 20px 50px;
  letter-spacing: 0.55px;
  line-height: 20px;
}

.compliance div.completionInfoPanel .title {
  background: #f7f7f7;
  font-size: 11px;
  font-style: normal;
  padding: 10px;
  color: #4a4a4a;
  letter-spacing: 0.55px;
  line-height: 20px;
  text-transform: uppercase;
}

.compliance-block-completion-field-text {
  color: #4a4a4a;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.55px;
	line-height: 20px;
	margin: 0;
}

.compliance .ui.grid.completionInfoPanel {
  width: 100% !important;
  font-size: 10px;
}

.compliance-block-completion-field-description,
.compliance div.completionInfoPanel .inputDate {
  font-size: 10px;
  color: #4a4a4a;
  font-weight: 100;
}

#completionDate {
  color: #4a4a4a;
  font-size: 10px;
  font-weight: 100;
}
.compliance div.completionInfoPanel .updateButton {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
}

.update .ui.positive.button {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: normal;
  letter-spacing: 0.43px;
  font-weight: 100;
  text-align: center;
  border-radius: 7px;
}

.compliance .compliance-medium-gray {
  font-size: 11px;
  font-weight: normal;
  color: #9c9b9b;
  margin: 0 0 0 35px;
  padding-bottom: 0.8em;
  letter-spacing: 0.7px;
  font-family: 'Lato';
  line-height: 18px;
}

.completionInfoPanel .ui.form .five.wide.field {
  width: 100%;
}

.compliance div.infoPanelMedicalBlock {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  margin: 20px 0px;
  box-shadow: 0px 4px 16px #00000022;
  border-radius: 10px;
}

.compliance div.infoPanelMedicalBlock.ui.grid > .row {
  padding: 20px 20px 20px 20px;
}

.compliance div.infoPanelMedicalBlock .redTitle {
  color: #f16778;
}

.compliance div.infoPanelMedicalBlock .warningTitle {
  color: #f5a623;
}

.compliance div.infoPanelMedicalBlock.blackTitile {
  color: #000000;
}

.compliance .tinyCloseButton {
  width: 12px;
  height: 12px;
  float: right;
}

.completedLabel {
  font-size: 10px;
}

.ui.image.tinyCloseButton:hover {
  cursor: pointer;
}

.compliance #TileGrid-column-left,
.compliance #TileGrid-column-right {
  min-width: 540px;
}

.compliance .medicalPanels {
  margin-top: -10px;
  margin-left: 14px;
  padding: 5px;
}

.completionInfoPanel .complianceField {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.resultLabel {
  color: #4a4a4a;
  padding-right: 2px;
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.expiredBlockLabel {
  color: #f16778;
  font-size: 10px;
  font-weight: 200;
  padding-right: 2px;
}

.compliance-charcoal-text-color {
  color: #4a4a4a;
}

.removeButton {
  color: #04a5d5;
  cursor: pointer;
  font-size: 10px;
  font-style: italic;
  padding: 5px;
  grid-column: 2/3;
}

div.closeIcon {
  position: absolute;
  margin-top: 120px;
  right: 40px;
}

.compliance-add-decline-text {
  color: #10a9d7;
  font-size: 11px;
  cursor: pointer;
  margin-bottom: 5px;
}

.compliance-add-decline-sub-text {
  font-size: 11px;
  color: #9c9b9b;
}

.compliance-add-completion-panel-header {
  font-size: 11px;
  font-weight: bold;
	color: #4a4a4a;
	text-transform: uppercase;
}

.compliance-header-plus-info-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
}

.compliance-header-plus-info-container > .compliance-add-completion-panel-header {
  margin: 0 1rem 0 0;
}

.block-result-text {
	grid-column: 1/2;
  margin-bottom: 0;
  margin-left: 35px;
  font-size: 11px;
}

.compliance-form-link {
  font-size: 11px;
  color: #10a9d7;
  font-style: italic;
}
