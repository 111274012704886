.careLoaderText {
  text-align: center;
  color: #333;
}

.careLoaderInverted {
  color: #FFF;
}

.emptyStateLoader {
  padding-top: 10em;
  padding-bottom: 10em;
}

.loaderImage {
  height: 40px;
  width: 40px;
}
