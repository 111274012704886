.infoBox {
  transform: translate(-20em, -8.5em) !important;
  height: 6em !important;
  overflow: hidden !important;
  width: 100% !important;
  min-width: 17em;
  max-width: 40em;
}

.map-info-box {
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  align-items: center;
  justify-items: center;
  width: 100%;
  min-width: 17em;
  max-width: 40em;
  position: relative;
}

.map-info-box-container {
  border-radius: 5px;
  padding: 0.5em 1em;
  min-width: 14em;
  max-height: 50px;
  grid-row: 1;
  background-color: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  right: 1em;
}

.map-info-box-pointer {
  position: relative;
  grid-row: 2;
  align-self: flex-start;
  justify-self: center;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #ffffff;
}

.info-box-image {
  position: relative;
  right: 0.7em;
  height: 40px !important;
  clip-path: circle(40% at 50% 50%);
}

.iw-text {
  font-weight: bold;
  position: relative;
  left: 1em;
  padding-right: 1em;
}
