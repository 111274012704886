.nameLink .overlayLink {
  font-size: 12px;
  font-weight: normal;
  color: #4A4A4A !important;
}

.tileAgencyExclusionsContainer .ui.selection.dropdown {
  font-size: 12px;
  font-weight: normal;
  color: #BFBFBF;
  min-height: 34.84px !important;
}

.tileAgencyExclusionsContainer .ui.selection.dropdown.icon {
  content: '\f0d7';
}

.exclusion-list{
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  justify-content: stretch;
  align-items: center;
}

.exclusion-list .ui.divider {
  grid-column: 1 / span 3;
  grid-row: 2;
}

.exclusion-list .overlayLink.assigned-caregiver-shift,
.exclusion-list > span.overlayLink {
  justify-self: flex-start;
  grid-column: 2;
  grid-row: 1;
}

.exclusion-list > span {
  justify-self: flex-end;
  grid-column: 3;
  grid-row: 1;
}