.select-input .ui.selection.dropdown {
  min-width: 0px;
  width: 90px;
  border: none;
  border-bottom: 1px solid #d8d8d8 !important;
  border-radius: inherit !important;
  min-height: 18px !important;
  height: 18px !important;
  padding-bottom: 14px !important;
  padding-top: 11px !important;
}

.select-input .ui.selection.dropdown > .text {
  position: absolute;
  bottom: 4px !important;
}
.select-input .ui.selection.dropdown > .icon::before {
  position: absolute;
  bottom: 1px !important;
}

.capacity p {
  padding-top: 16px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}

.compliance-form .ui.form label {
  color: #aaaaaa;
}
