.ui.table#systemSettingsPreferencesTable .optional-label {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  letter-spacing: 0.05em;
  background-color: #e7e7e7;
  border: none;
  border-radius: 0.2em;
  padding: 0.5em;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  max-width: 125px;
}

.ui.table#systemSettingsPreferencesTable .optional-label::placeholder {
  color: black;
  opacity: 0.3;
}

.ui.table#systemSettingsPreferencesTable .optional-label:-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.ui.table#systemSettingsPreferencesTable .optional-label::-ms-input-placeholder {
  color: black;
  opacity: 0.3;
}

.ui.table#systemSettingsPreferencesTable .optional-label.disabled {
  display: none;
}

.col-trainings-preferences,
.col-trainings-preferences tr {
  background-color: white;
}

.active-info-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #9b9b9b;
}

.active-info-highlight {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #69db8f;
}

.table-item-removed {
  background-color: #fec7c8 !important;
}

.table-item-updated {
  background-color: #fff1c2 !important;
}

.table-item-custom {
  background-color: #eff9fd !important;
}

.ui.table#systemSettingsPreferencesTable tbody tr.table-item-inactive td.name {
  color: #9b9b9b;
}

.ui.toggle.checkbox input ~ .box:before,
.ui.toggle.checkbox input ~ label:before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='22' y='15' fill='gray' font-size='12'>NO</text></svg>");
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #69db8f !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='6' y='15' fill='gray' font-size='12'>YES</text></svg>");
}
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #69db8f !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='6' y='15' fill='gray' font-size='12'>YES</text></svg>");
}

.ui.toggle.checkbox.caregiver-block-shift-matching input ~ .box:before,
.ui.toggle.checkbox.caregiver-block-shift-matching input ~ label:before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='22' y='15' fill='gray' font-size='12'>NO</text></svg>");
}
.ui.toggle.checkbox.caregiver-block-shift-matching input:checked ~ .box:before,
.ui.toggle.checkbox.caregiver-block-shift-matching input:checked ~ label:before {
  background-color: #f16778 !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='6' y='15' fill='black' font-size='12'>YES</text></svg>");
}
.ui.toggle.checkbox.caregiver-block-shift-matching input:focus:checked ~ .box:before,
.ui.toggle.checkbox.caregiver-block-shift-matching input:focus:checked ~ label:before {
  background-color: #f16778 !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><style>.text { font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; }</style><text class='text' x='6' y='15' fill='black' font-size='12'>YES</text></svg>");
}

.ui.table#trainingItemsTable td.name,
.ui.table#systemSettingsPreferencesTable td.name {
  max-width: 160px;
  word-wrap: break-word;
  white-space: initial !important;
  text-align: left;
  font-size: 11px;
  font-family: lato, Arial, Helvetica, sans-serif;
  color: #4a4a4a;
}

.ui.table#systemSettingsPreferencesTable thead tr th {
  overflow-wrap: break-word;
  white-space: pre-line;
  font-weight: 900;
  font-size: 12px;
  font-family: lato, Arial, Helvetica, sans-serif;
  color: #4a4a4a;
}

.ui.table#systemSettingsPreferencesTable tbody tr td {
  white-space: initial;
  word-wrap: break-word;
}

.ui.table#systemSettingsPreferencesTable thead tr th {
  text-align: center;
  border-right: 1px solid #d4d4d4;
  height: 50px;
}

.ui.table#systemSettingsPreferencesTable tbody tr td {
  text-align: center;
}

.ui.table#systemSettingsPreferencesTable thead tr th:first-child {
  text-align: left;
}

.ui.table#systemSettingsPreferencesTable tbody tr td:first-child {
  word-wrap: break-word;
  white-space: initial !important;
  text-align: left;
}

.ui.table#systemSettingsPreferencesTable tbody tr td:nth-of-type(7) {
  max-width: 130px;
}

.ui.table#systemSettingsPreferencesTable tbody tr td:nth-of-type(8) {
  max-width: 130px;
}

.ui.table#systemSettingsPreferencesTable tbody tr td:nth-of-type(4) {
  white-space: nowrap;
}

#system-setting-tab .ui.tabular.menu {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 60px;
}

#system-setting-tab .ui.segment,
#system-setting-tab .ui.attached.tabular.menu {
  border: none !important;
}

#system-setting-tab .ui.tabular.menu .item {
  border-radius: 0 !important;
  padding: 21.5px 19.5px !important;
  border-top: none !important;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  color: #9b9b9b;
  font-weight: 900;
  font-size: 14px !important;
  font-family: Lato, Arial, Helvetica, sans-serif !important;
}

#system-setting-tab .ui.tabular.menu .active.item {
  color: #364866 !important;
}

#system-setting-tab .ui.tabular.menu .item:first-of-type {
  border-top-left-radius: 5px !important;
  border-left: none !important;
}

.table-item-custom .ui.dropdown .text {
  margin-right: 0px !important;
}

.system-settings-header-wrap {
  overflow-wrap: break-word;
  white-space: pre-line;
  font-weight: 900;
  font-size: 12px;
  font-family: lato, Arial, Helvetica, sans-serif;
  color: #4a4a4a;
}

.compliances-icon-col-width {
  width: 2% !important;
}

.compliances-header-style {
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  white-space: pre-line;
  font-weight: 900 !important;
  font-size: 12px !important;
  font-family: lato, Arial, Helvetica, sans-serif;
  color: #4a4a4a;
}

.compliance-centered {
  text-align: center !important;
}

.compliance-description-small {
  font-size: 12px;
  color: #595959;
}

.create-training-name {
  max-width: 317px;
  word-wrap: break-word;
  white-space: initial !important;
  text-align: left;
  color: #4a4a4a;
}


.ui.disabled.dropdown.preference-settings-dropdown > i,
.ui.selection.dropdown.preference-settings-dropdown > i,
.ui.multiple.search.selection.dropdown.preference-settings-dropdown > i,
.field.compliance-centered.preference-settings-dropdown > div > i {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 12% -10%);
}

.field.compliance-centered.preference-settings-dropdown > .ui.multiple.dropdown {
  padding-right: 3em !important;
}

#settings-save-button:disabled {
  color: #9b9b9b !important;
  border: 1px solid #9b9b9b !important;
  background-color: transparent !important;
  border-radius: 2px;
  position: relative;
  bottom: 0.06em;
}

#settings-save-button {
  background-color: #04a5d5 !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #01475c !important;
  position: relative;
  bottom: 0.06em;
  border: 1px solid #9b9b9b !important;
  border-radius: 2px;
}

#settings-save-button:hover {
  color: #073f50 !important;
  background-color: #1293b9 !important;
}

.rate-setting-tile {
  max-width: 38em;
  margin-top: 1.5em;
  margin-right: 1.5em;
}

.rate-body-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px !important;
}

.rate-symbol {
  justify-self: flex-end;
  font-weight: 900;
}

.ui.tiny.header.rate-body-text {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px;
  font-weight: 900 !important;
}

.inline.fields.rate-form-group-container {
  display: grid;
  grid-template-columns: 1fr 0.1fr 0.2fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.field.rate-input-field > div > input {
  background-color: #f7f7f7 !important;
  max-width: 6em;
  margin-left: 1em !important;
}

.ui.grid > .row.default-rate-row {
  padding: 0 !important;
}

.rates-hr {
  position: relative;
  width: 100% !important;
  bottom: 1em !important;
}

.pre-hire-container {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 10px 14px;
  color: #4A4A4A;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
  border-radius: 10px;
  }

.pre-hire-container-tile {
  display: grid;
  grid-gap: 5px 10px;
  background-color: #FFFFFF;
  grid-template-columns: 35px 1fr;
  padding: 15px 15px;
  width: 250px;
  height: 60px;
  text-align: middle;
  cursor: pointer;
  }