.environment-tab-main-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(100px, auto) 1fr;
  width: 345px;
  position: relative;
  right: 30px;
}

.environment-tab-languages-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px minmax(30px, auto);
}
.environment-tab-language-header {
  margin: 0;
}

.environment-tab-language-header,
.environment-tab-header {
  font-size: 12px;
  font-weight: bold;
  justify-self: flex-start;
  color: #4a4a4a;
  grid-row: 1/2;
}
.environment-tab-display-language-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 85px minmax(60px, 1fr);
  white-space: pre-wrap;
}
.environment-tab-language-title {
  font-size: 10px;
  color: #9b9b9b;
  justify-self: flex-start;
  align-self: center;
  grid-column: 1/2;
  grid-row: 1/2;
  margin: 0 0 0 25px;
}
.environment-tab-language {
  display: inline-block;
  align-self: center;
  justify-self: flex-start;
  margin: 0 0 0 8px;
  grid-row: 1/2;
}
.environment-tab-option-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(17px, 1fr));
  margin-bottom: 1em;
}
.environment-tab-selected-option-container {
  margin-top: 1em;
  border-top: 1px solid #9b9b9b33;
  padding: 10px 10px 0 10px;
}
.environment-tab-language,
.environment-tab-additional-language,
.environment-tab-selected-option {
  font-weight: normal !important;
  white-space: pre-wrap;
  font-size: 12px;
  color: #4a4a4a;
}
