.e-learning-user-filter-container {
  width: 300px;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 25px;
}

.e-learning-user-filter-input {
  width: 100%;
  height: 30px;
  align-self: center;
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  padding-left: 20px;
}

.e-learning-user-filter-button {
  width: 30px;
  height: 30px;
  align-self: center;
  border: none;
  background-color: #04a5d5;
  border-radius: 50%;
  display: grid;
  padding: 0;
  cursor: pointer;
  font-family: Lato;
  color: #01475c ;
  font-size: 14px;
  font-weight: 800;
}

.e-learning-user-filter-button:hover {
  background-color: #1293b9;
  color: #073f50;
}

.e-learning-user-filter-button:focus,
.e-learning-user-filter-input:focus {
  text-decoration: none;
  outline: none;
}
