div [class*='left floated'] {
  float: left;
  margin-left: 0.25em;
}

div [class*='right floated'] {
  float: right;
  margin-right: 0.25em;
}

.applied thead th {
  background-color: #e1f8e9 !important;
  opacity: 0.8;
}
.applied thead th.sorted {
  background-color: #69db8f !important;
}

.declined thead th {
  background-color: #fce1e4 !important;
  opacity: 0.8;
}
.declined thead th.sorted {
  background-color: #f16778 !important;
}

.header.caregivers {
  margin-right: 25px;
  padding-top: 0 !important;
  padding-bottom: 13px !important;
}

.header.caregivers.border {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 1em;
  padding-top: 0 !important;
}

.shiftDetailsContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  width: 100%;
  padding: 0 !important;
  align-items: stretch;
}

.shiftDetailsContainer .ui.fluid.container.medium-grey-bg.shiftDetailsBar {
  width: calc(100% + 21px);
  display: grid !important;
  grid-template-columns: 0.1fr 1fr;
  grid-template-rows: 1fr;
  align-items: stretch;
}

.shiftDetailsContainer
  .ui.fluid.container.medium-grey-bg.shiftDetailsBar
  .field.care-blue.viewButton {
  justify-self: flex-end;
}

.shiftDetailsContainer .floatTopRight {
  position: relative !important;
  align-self: flex-start !important;
  justify-self: flex-end !important;
  right: 10px !important;
}

.ui.container.shiftDetailsBar {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.shiftDetailsBar .mainContent {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 1em;
}

.shiftDetailsBar .fields {
  margin-top: 1em !important;
}

.shiftDetailsBar .divided {
  border-right: 1px solid #d9d9d9;
}

.shiftDetailsBar .viewButton {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftDetailsBar i {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.shiftDetailsBar .detailItem label {
  margin: 0 !important;
  color: #9b9b9b !important;
  letter-spacing: 0.1em;
}

.shiftDetailsBar .detailItem .input {
  margin-bottom: 1em !important;
  font-size: 0.83rem !important;
}

.shiftDetailsBar .button {
  padding-left: 3em;
  padding-right: 3em;
  margin: 1em;
}

.shiftDetailsBar .react-datepicker-wrapper {
  width: 100px !important;
}

.shiftDetailsBar .field.timeInput {
  width: 90px !important;
}

.shiftDetailsBar .saveButton {
  margin: 0 !important;
  margin-top: 0.2em !important;
}

.shiftDetailsBar .resetButton {
  margin: 0 !important;
  margin-top: 0.2em !important;
  margin-left: 2em !important;
  font-size: 0.9em;
  color: blue;
}

.shiftDetailsBar .resetButton:hover {
  cursor: pointer;
}

.shiftDetailsBar .leftHeader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftDetailsBar .greyColor {
  color: #4a4a4a !important;
  background-color: #d9d9d9;
}

.shiftDetailsBar .blueColor {
  color: white !important;
  background-color: #2f4864;
}

.shiftDetailsBar .leftHeader i {
  margin: 0 !important;
}

.table.caregiverResults td {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #9b9b9b;
  font-weight: normal;
  font-size: 12px;
}

.table.caregiverResults td .circle.icon {
  margin-right: 1em;
}

.info-status {
  margin-right: 5px !important;
  padding: 0 5px !important;
}

.table.caregiverResults td .button {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.avatarWithName img {
  width: 2rem !important;
  height: 2rem !important;
  object-fit: cover;
}

.avatarCgDetails > img {
  width: 4.6875rem !important;
  min-width: 4.6875rem !important;
  height: 4.6875rem !important;
  object-fit: cover;
}

.menuTitleCounter {
  color: grey;
}

.menuDropdown {
  margin-bottom: 0.8em;
  width: 100%;
  max-width: 500px;
  padding-right: 40px !important;
}

.menuDropdown label {
  font-weight: bold !important;
  color: gray !important;
  font-size: 0.8em !important;
  margin-bottom: 0.8em !important;
}

.menuDropdown .ui.label {
  margin-top: 0.3rem !important;
  font-size: 0.8rem !important;
}

.menuDropdown img.coin {
  height: 1rem !important;
  vertical-align: top !important;
}

.menuDropdown .item img.coin {
  vertical-align: middle !important;
}

.gm-style-iw + div {
  display: none;
}

.gm-style-iw {
  text-align: center;
}

.gm-style-iw .avatar {
  object-fit: cover;
  width: 35px !important;
  height: 35px !important;
}

.gm-style-iw .iw-text {
  font-weight: bold;
  color: #4a4a4a !important;
  padding-left: 1em;
  padding-right: 1em;
}

.caregiverResults i.icon {
  font-size: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  position: relative;
  color: #4a4a4a;
}

.warnIcon {
  color: orange;
}

.ui.small.left.floated.header.preferenceTitle {
  text-transform: capitalize;
}

.partiallyMatchedCaregivers {
  color: red !important;
}

.matchedCaregivers {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.matchedCaregivers img {
  float: left;
  margin: 0.3em 0.4em;
}

.matchedCaregivers .textContainer {
  float: left;
}

.matchedCaregivers .header {
  float: left;
  font-size: 1em;
  text-transform: uppercase;
}

.matchedCaregivers .content {
  float: left;
  font-size: 0.7em;
  font-weight: bold;
  color: gray;
}

.matchedCaregivers .table-assign-caregiver-check-all {
  float: right;
}

.invite-assign-bottom-bar-fade-in{
  opacity: 0;
  animation: fadeIn 200ms ease-out forwards;
  padding: 0 !important;
}

@keyframes fadeIn {
  0%      {transform: translate(0, 20px); opacity: 0;}
  100%    {transform: translate(0, 0); opacity: 1;}
}

.invite-assign-bottom-bar-fade-out {
  opacity: 1;
  animation: fadeOut 200ms ease-in forwards;
}

@keyframes fadeOut {
  0%      {transform: translate(0, 0); opacity: 1;}
  25%      {transform: translate(0, 5px); opacity: 1;}
  75%      {transform: translate(0, 15px); opacity: 0.5;}
  100%    {transform: translate(0, 20px); opacity: 0;}
}

.matchedCaregivers .count {
  font-size: 0.9em;
  font-weight: normal;
  color: gray;
}

.invitationRespHeaderTabs {
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 0.5em;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.2fr 1fr;
  justify-items: flex-start;
  align-items: center;
  font-size: 1.1em;
}

.invitationRespHeaderTabs div {
  display: inline-block;
}

.invitationRespHeaderTabs .ui.buttons .ui.circular.button {
  margin: .2em .5em;
  background-color: transparent;
  color: #7b7b7b;
  border: 1px solid #d3d3d3;
}

.invitationRespHeaderTabs .ui.buttons .ui.circular.button.invitation-response-active {
  margin: .2em .5em;
  background-color: transparent;
  color: #00000099;
  border: 1px solid #00000099;
}

.invitationRespHeaderTabs .ui.circular.button, .ui.circular.buttons .button {
  min-width: 2em;
  min-height: 2em;
  padding: .7em !important;
  line-height: 1em;
  text-align: center;
  border-radius: 500rem;
}

.invitationRespHeaderTabs .ui.button:first-child {
  margin-left: 2em;
}


.floatTopRightMap {
  position: absolute;
  top: 4px;
  right: 15px;
}

.conflictPadding td {
  padding-bottom: 3em !important;
  position: relative;
}

table tr:last-child {
  border-bottom: 0;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f36676;
  position: absolute;
  left: 20px;
  bottom: 19px;
}

.tableBottomBar {
  position: absolute;
  width: calc(100vw - 328px);
  min-width: 788px;
  background: #f36676;
  bottom: 0;
  left: 0;
  color: #fddee2;
  height: 19px;
  z-index: 5;
}

@media only screen and (max-width: 1417px) {
  .tableBottomBar {
    width: calc(100vw - 317px);
    min-width: 780px;
  }
}

.tableBottomBar > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  max-width: 1171px;
}

.hiddenBox {
  display: none;
  visibility: hidden;
}

body ::-webkit-scrollbar {
  width: 10px !important;
}

body ::-webkit-scrollbar-thumb {
  width: 10px !important;
  min-height: 40px;
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
  background-clip: padding-box !important;
}

body ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

body ::-webkit-scrollbar-corner {
  background-color: transparent;
}

.subTabButton:focus {
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
}

.subTabButton:hover span {
  color: #9b9b9b !important;
}

.subTabButton:last-of-type span {
  color: #9b9b9b !important;
}

.subTabButton:hover:last-of-type span {
  color: #4a4a4a !important;
}

.caregiverRecords .react-datepicker-wrapper input {
  background-image: none !important;
}

.avatarCgRecords > img {
  width: 2.5rem !important;
  min-width: 2.5rem !important;
  height: 2.5rem !important;
  object-fit: cover;
}

.invitationModel h2.ui.header {
  font-size: 18px;
  font-weight: normal;
  margin-top: 5px;
  color: #364866;
}

.caregiver-invitation-close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 555px;
  top: -15px;
  width: 30px;
  height: 30px;
}

.invitationModel .header {
  height: 65px;
  border: none !important;
}

.invitationModel .ui.divider {
  margin: 0 20px;
}

.invitationModel .ui.basic.segment {
  font-size: 14px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
}
.invitationModel .actions {
  border: none !important;
}

.invitationModel .ui.form textarea {
  background: #ededed;
  height: 110px !important;
  border: none;
}

.assignedHeader h2.ui.header {
  color: #672c33 !important;
  font-size: 18px;
  font-weight: normal;
}

#notification-recipients-field {
  border-radius: 20px !important;
}

.shiftDetailsBar .ui.labeled.input > .ui.dropdown > .dropdown.icon {
  float: left;
  margin-left: 0px;
  bottom: 10px;
  padding: 2px;
}

.shiftDetailsBar .ui.labeled.input > .ui.dropdown > .menu {
  width: 119px !important;
}

.caregivers-search-time-picker-container {
  width: 100%;
  margin-left: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.2fr 1fr;
  grid-column-gap: 2em;
  justify-content: space-evenly;
  align-items: center;
}

.caregivers-search-time-picker-container > .ui.fluid.search.selection.dropdown {
  position: relative;
  max-height: 33px !important;
  height: 33px;
  min-width: 115px !important;
  align-self: flex-start;
}

.start-label {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.end-label {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.extra-cost-text {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 4px;
  color: #4a4a4a;
  font-weight: 900;
  font-size: 10;
}
