
.announcementButton {
    color: #A9ADB4!important;;
    background-color: #E5E5E5!important;;
    padding: 3px!important;;
    padding-right: 0.5em;
    border-radius: 20px!important;
    font-size: 12px!important;
}

.announcementButton .content{
    display: flex!important;
    flex-direction: row;
    align-items: center;
    font-size: 11px;
    font-weight: normal !important;
    letter-spacing: 0.05em!important;
}

.announcementButton .content div {
    padding: 0 0.5em;
}

.announcementButton .content img{
    height: 15px;
}


.announcements {
    text-align: left;
    font-weight: normal;
    text-transform: none;
}

.announcements .mainControl{
    background-color: rgba(255,255,255,1);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 80px;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.announcements .clickable:hover {
    cursor: pointer;
}

.announcements .audienceLoader{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.announcements .item {
    align-self: center;
    width: 600px;
}

.announcements .item.box {
    border: 1px solid #ECECEC;
    margin-bottom: 0.5em;
}

.announcements .topbar {
    font-size: 18px;
    color: #4A4A4A;
    text-align: center;
    padding: 1em;
}

.announcements .controlFooter {
    z-index: 999;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    justify-content: center;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 -2px 10px 0 rgba(0,0,0,0.20);
}

.announcements .controlFooter .leftSide{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    margin-left: 150px;
}
.announcements .controlFooter .leftSide p {
    text-align: right;
    margin-right: 0.5em;
}

.announcements .controlFooter .leftSide button
{
    background-color: #69db8f !important;
    color: #417505 !important;
}

.announcements .item .itemHeader {
    width: 100%;
    background-color: #4A4A4A;
    font-size: 10px;
    color: white;
    padding: 0.9em;
}

.announcements .item .itemContent {
    width: 100%;
    padding: 0.9em;
}

.announcements .item .itemContent textarea{
    background-color: #F3F3F3;
    border: 1px solid #979797;
    font-size: 14px;
    color: #4A4A4A;
}

.announcements .item .itemContent textarea:focus{
    border: 1px solid #979797
}


.announcements .message .infoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    color: #9B9B9B;
    margin-top: 1em;
}

.announcements .audience .ui.grid {
    margin: 0!important;
}

.announcements .audience .matchCountPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.announcements .audience .matchCountPanel img {
    padding: 1em;
}
.announcements .audience .matchCountPanel .mcpHeader {
    font-size: 12px
}

.announcements .audience .matchCountPanel .mcpFooter {
    color: #9B9B9B;
    font-size: 10px;
    margin: 1em;
}

.announcements .audience .matchCountPanel .mcpCount {
    font-size: 16px;
    padding: 0.5em;
}
.announcements .audience .matchCountPanel button {
    box-shadow: none !important;
    background-color: transparent !important;
    border: 1px solid #69db8f !important;
    color: #69db8f !important;
    font-weight: 400 !important;
    letter-spacing: 0.1em;
    padding: 0.5em!important;
    margin: 0.5em;
}

.announcements .audience .filterBlock {
    background-color: #F3F3F3;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    color: #9B9B9B;
}

.announcements .audience .addRule {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    width: 100%;
}

.announcements .audience .addRule span {
    margin-bottom: 0.4em;
}

.announcements .audience .filterSepartor {
    text-align: center;
    font-size: 10px;
    padding: 0.3em;
}


.announcements .audience .addRule .addRuleBtn {
    font-size: 10px;
    border: 1px solid #D9D9D9;
    padding: 1em;
    width: 100%;
    text-align: center;
}

.announcements .audience .filterBlock p {
    font-size: 10px;
    padding: 1em;
}

.announcements .audience .filterPicker {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
    font-size: 10px;
    color: #4A4A4A;
    width: 100%;
    padding: 1em;
    position: relative;
}

.announcements .audience .filters {
    width: 100%;
    margin-bottom: 0.5em;
}

.announcements .audience .editFilter {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
    font-size: 10px;
    color: #4A4A4A;
    width: 100%;
    padding: 1em;
    position: relative;
}

.announcements .audience .removeFilter {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
}

.announcementModals .content {
    text-align: center;
}

.announcementModals > .ui.image {
    position: absolute;
    right: -1.4em;
    top: -1.2em;
}

.announcementModals h1 {
    color: #4A4A4A;
    font-size: 14px;
    margin: 1em;
}

.announcementModals p {
    color: #9B9B9B;
    font-size: 12px;
    margin: 1em;
}

.announcements .audienceList {
    font-size: 10px;
}

.announcements .audienceList .hideBar {
    text-align: center;
    color: #04A5D5;
    font-weight: bold;
}

.announcements .audienceList .hideBar span:hover{
    cursor: pointer;
}

.announcements .audienceList .headerBar{
    padding: 0.5em;
    background-color: #F0F0F0;
}

.announcements .audienceList .cgRow{
    padding: 0.5em;
}

.announcements .audienceList .noAudienceRow {
    padding: 0.5em;
    padding-top: 2em;
    text-align: center;
}

.announcements .ui.selection.dropdown {
    font-size: 10px!important;
    width: 100%!important;
}
.announcements .ui.active.visible.selection.dropdown .item{
    font-size: 10px!important;
}
