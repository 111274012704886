.input-with-delete .ui.button {
  background: none;
  width: 0.8rem;
  padding: 0;
}

.ui.button.borderlessBlueButton {
  display: inherit;
  background: none;
  color: #04a5d5;
  float: right;
  font-weight: normal;
  margin-left: auto;
}

.ui.button.clientborderlessBlueButton {
  display: inherit;
  background: none;
  color: #04a5d5;
  float: left;
  font-weight: normal;
  margin-left: -7px;
}

.Chevron {
  position: relative;
  display: block;
  height: 20px; /*height should be double border*/
}
.Chevron:before,
.Chevron:after {
  position: absolute;
  display: block;
  content: '';
  border: 10px solid transparent; /*adjust size*/
}
/* Replace all text `top` below with left/right/bottom to rotate the chevron */
.Chevron:before {
  top: 5px;
  border-top-color: #04a5d5; /*Chevron Color*/
}
.Chevron:after {
  top: -10px; /*adjust thickness*/
  border-top-color: #fff; /*Match background colour*/
}

.ChevronUp {
  position: relative;
  display: block;
  height: 20px; /*height should be double border*/
}
.ChevronUp:before,
.ChevronUp:after {
  position: absolute;
  display: block;
  content: '';
  border: 10px solid transparent; /*adjust size*/
}
/* Replace all text `top` below with left/right/bottom to rotate the chevron */
.ChevronUp:before {
  bottom: 10px;
  border-bottom-color: #04a5d5; /*Chevron Color*/
}
.ChevronUp:after {
  bottom: -10px; /*adjust thickness*/
  border-bottom-color: #fff; /*Match background colour*/
}

.form-client-status-dot.circle.small.icon::before {
  position: absolute !important;
  left: 15px !important;
  top: 33px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  z-index: 11 !important;
}

#form-agency-client-status-dot.circle.small.icon::before {
  position: relative;
  left: 22px;
  top: -1px;
  margin-left: 5px;
  margin-right: 5px;
  z-index: 11;
}

.dhjuEx .ui.form .field > label {
  font-size: 12px;
  color: #a9adb4;
}

.detail-input div {
  margin-bottom: 20px;
}

.detail-input label {
  margin-bottom: 10px !important;
}

#form-client-details-first-name {
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}

#form-client-details-middle-name,
#form-client-details-nick-name,
#form-client-details-last-name {
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}

#form-client-details-last-status {
  position: relative;
  top: -20px;
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
  padding-left: 10px;
}

#form-staff-details-last-status {
  position: relative;
  top: -20px;
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
  padding-left: 20px;
}

.vertical-line.ui.selection.dropdown > .dropdown.icon {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 12% -10%);
}

.ui.selection.visible.dropdown > .text:not(.default) {
  font-weight: 900;
  color: #4a4a4a;
}

.form-client-details-last-since {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #4a4a4a !important;
}

#client-details-last-since {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #4a4a4a !important;
}

#form-caregiver-ethnicity,
#form-client-gender {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
}

#form-caregiver-gender,
#form-caregiver-marital {
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
}

#avatar-gender-record {
  width: 40px !important;
  height: 40px !important;
}

.address-home-address {
  font-size: 10px;
  color: #a9adb4;
  font-weight: normal;
}

#client.address {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: normal;
}

#client-address-map-button {
  font-size: 12px;
  color: #04a5d5;
  font-weight: normal;
}

#client-contact-primary-phone {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: normal;
}

#client-contact-email {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: normal;
}

.detailsBox .ui.selection.dropdown > .dropdown.icon {
  box-shadow: -1px 0px #a9adb4;
  clip-path: inset(10% 0% 12% -10%);
}

.detailsBox .ui.disabled.selection.dropdown > .dropdown.icon {
  box-shadow: none;
}

.detailsBox .ui.selection.dropdown.disabled > .dropdown.icon {
  border-left: none;
}

.detailsBox .ui.selection.dropdown.disabled div {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 2px;
}

/* Client req tag in client tabs */

/* Client req tag in client tabs */
.caregiverRecords {
  width: 100vw !important;
}

.caregiverRecords .ui.label > .delete.icon {
  cursor: pointer;
  position: relative;
  top: 1px;
  left: 2px;
  margin-right: 5px;
}

.caregiverRecords .ui.multiple.dropdown > .label {
  font-size: 12px;
  color: #4a4a4a;
  font-family: Lato;
  font-weight: bold;
  background-color: #ededed;
}

.caregiverRecords .hireDateInput input {
  background-color: transparent !important;
}

.caregiverRecords .hireDateInputDisable input {
  background-color: transparent !important;
  border: none !important;
}

.caregiverRecords .activationDateInput input {
  background-color: transparent !important;
  border: none !important;
}

.row.noVerticalPadding.caregiverRecords > div > div > div > .fullPageContentGrid {
  max-width: 83vw;
  position: relative;
  right: 2vw;
}

.row.noVerticalPadding.caregiverRecords
  > div
  > div
  > div
  > .fullPageContentGrid
  > .row
  > #change-log-tab {
  max-width: 80vw;
}

#addressSelector,
#addressSelector .menu {
  min-width: 126.95px !important;
}

#form-client-suffix,
#form-client-suffix .menu {
  min-width: 93.88px !important;
}

#form-caregiver-discipline,
#form-caregiver-discipline .menu {
  min-width: 211.36px !important;
}

#form-caregiver-ethnicity,
#form-caregiver-ethnicity .menu {
  min-width: 171.44px !important;
}

#form-caregiver-marital,
#form-caregiver-marital .menu {
  min-width: 120px !important;
}

#form-caregiver-gender,
#form-caregiver-gender .menu {
  min-width: 100px !important;
}

#note-type,
#note-type.menu {
  min-width: 212px !important;
}

#note-sub-type,
#note-sub-type .menu {
  min-width: 212px !important;
}

.detailsBox .details-box-wide-grey {
  margin-left: -85px;
  background-color: #f5f5f5;
  border-top: 1px solid #a9adb4;
}

.detailsBox .details-box-wide {
  margin-left: -85px;
}

.ui.grid > .row.details-box-wide,
.ui.grid > .row.details-box-wide-grey {
  width: 120% !important;
}

.detailsBox .details-box-wide .details-note {
  width: 95%;
}

.hbjzva .ui.input.social-media-field input {
  max-width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.detailsBox .ui.form .details-note > textarea {
  background-color: #f7f7f7;
  margin: 0 12px 12px 12px;
}

.emContactDetailsBox {
  padding-top: 50px;
}
.emContactDetailsBox .cRogIh.ui.tiny.header {
  color: #000000de;
  font-weight: bold;
  font-size: 12px;
}
.emContactDetailsBox .details-box-wide-grey {
  margin-left: -85px;
  background-color: #f5f5f5;
  border-top: 1px solid #a9adb4;
}
.emergencyContact .ui.input input {
  background-color: #f5f5f5;
  border-bottom: 1px solid #a9adb4 !important;
  border-radius: 0px;
}
.emContactDetailsBox .header h3 {
  font-weight: bold;
}

.emContactDetailsBox .ui.button.emergency-contact-clear-input-button {
  background: none;
  height: 1.536rem;
  width: 0.768rem;
  padding: 0;
  margin-right: -0.1785rem;
}

.emContactDetailsBox .cRogIh .ui.form .field > label {
  font-weight: bold;
}
.emContactDetailsBox div.ui.input {
  background: transparent;
}
.emContactDetailsBox .ui.input input[type='text'] {
  background-color: #f5f5f5;
  color: #4a4a4a;
  font-size: 14px;
}
.emContactDetailsBox .ui.header > .image:not(.icon),
.ui.header > img {
  margin-top: 0px;
  width: 18px;
  height: auto;
}
