.profile .pContent {
} 

.profileMenu {
  padding-top: 0 !important;
}

.profileMenu .profileMenuText {
  font-size: 12px;
}

.profileMenu .ui.header {
  color: #4a4a4a;
  font-size: 14px;
  margin-left: 1em;
}

.profileMenu .menu-item {
  margin-bottom: 28px;
}

.profileMenu .menu-item .item-title {
  margin-left: 5px;
}

.profileMenu .active .menu-item {
  background-color: #ececec !important;
  color: #9b9b9b;
}

.user-modal-close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  left: 1125px;
  top: -15px;
  width: 30px;
  height: 30px;
}

#user-directory-table {
  position: relative;
  top: -30px;
}
.profileMenu .ui.segment {
  color: #4a4a4a;
}

.profile .ui.grid.nameAndPic {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
}

.profile .ui.grid.nameAndPic .imageContainer {
  position: relative;
  width: 100px !important;
  height: 100px !important;
  padding: 0;
  margin: 20px;
}

.profile .ui.grid.nameAndPic .imageContainer .dropdown {
  position: absolute;
  right: 0em;
  top: 0em; 
} 

.profile .ui.grid.nameAndPic .imageContainer button {
  position: absolute;
  right: 0.1em;
  top: 0.1em;
  z-index: 10;
  background-color: #6ed992 !important;
  border: 2px solid white;
  height: 22px;
  width: 22px;
  font-size: 12px; 
}

.profile .ui.grid.nameAndPic .imageContainer button i.icon {
  font-size: 14px;
  position: relative;
  right: 9px;
  bottom: 6px;
  height: 20px;
  width: 20px;
}

.profile .ui.grid.nameAndPic .segment {
  border-left: 0 !important;
}

.profile .ui.grid.nameAndPic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile .ui.grid.nameAndPic input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  padding: 0.4rem !important;
  padding-left: 0.1rem !important;
  font-size: 18px !important;
  width: 100% !important;
  color: #4a4a4a;
}
.profile .ui.grid.nameAndPic .ui.form {
  margin-top: 20px;
}
.profile .ui.grid.nameAndPic .field label,
.profile .ui.grid .detailsBox .field label {
  color: #a9adb4;
  font-size: 12px;
  font-weight: normal;
  padding-bottom: 5px;
}

.profile .role {
  color: #4a4a4a;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 12px;
}

.profile .ui.grid.detailsBox .medium.header {
  padding: 0 1em;
  color: #364967;
  font-size: 14px;
  margin: 10px 15px;
}

.profile-e-learning-progress-container,
.profile .ui.grid.detailsBox {
  margin-top: 1rem !important;
  background-color: white;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
}

.profile .ui.grid .detailsBox .field {
  margin: 15px;
}

.profile .ui.grid .detailsBox input {
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}

.profile .ui.grid.detailsBox .headerRow.row {
  border-bottom: 1px solid #d9d9d9;
}

.profile .ui.grid.detailsBox .segment.compact {
  padding: 0.5rem !important;
  margin: 0 15px !important;
  font-size: 12px;
}

.profile .ui.grid.detailsBox .ui.medium.header {
  color: #364967;
}

.rootUserInfo {
  margin-top: 4em !important;
}

.profileTop .name {
  font-weight: bold;
  font-size: 1.1em;
  margin-left: 3em;
  color: #364967;
  padding: 0.7em 0;
  text-transform: uppercase;
}

.profileTop .saveButton {
  margin-left: 1.5em !important;
}

.profileTop .addButton {
  margin-left: 0.5em !important;
}

.profileWelcomeTop {
  letter-spacing: 0.1em;
}

.profileWelcomeTop .name {
  font-weight: bold;
  font-size: 1.1em;
  color: #364967;
  padding: 0.7em 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.profileWelcomeTop .welcome {
  font-weight: bold;
  font-size: 1.1em;
  color: #a9adb4;
  padding: 0.7em 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.profileWelcomeTop .saveButton {
  margin-left: 1.5em !important;
  font-size: 12px;
  font-weight: normal;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  border-radius: 2px;
  position: relative;
  bottom: 0.06em;
}

#profile-save-button:disabled {  
  margin-left: 1.5em !important;
  font-size: 12px;
  font-weight: normal;
  color: #9b9b9b !important;
  border: 1px solid #9b9b9b !important;
  background-color: transparent !important;
  border-radius: 2px;
  position: relative;
  bottom: 0.06em;
  min-height: 34px;
  min-width: 110px !important;
}


#profile-save-button {
  background-color: #04a5d5 !important;
  margin-left: 1.5em !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #01475c !important;
  position: relative;
  bottom: 0.06em;
  border: 1px solid #9b9b9b !important;
  border-radius: 2px;
}

#profile-save-button:hover {
  color: #073f50 !important;
  background-color: #1293b9 !important;
}

.profileWelcomeTop .addButton {
  margin-left: 0.5em !important;
}

.profileWelcomeTop .add-button-with-label,
.profileTop .add-button-with-label {
  font-size: 12px;
  color: #9b9b9b;
}

.profileWelcomeTop .care-round-button,
.profileTop .care-round-button {
  display:inline-block;
  width:30px;
  height:30px;
  line-height:30px;
  border-radius: 50%;
  color:#364866;
  text-align:center;
  text-decoration:none;
  background-color: #04A5D5;
  font-size:20px;
  font-weight:bold;
  cursor: pointer;
  vertical-align: sub;
}

.green-button {
  display:inline-block;
  width:30px;
  height:30px;
  line-height:30px;
  border-radius: 50%;
  color:#417505 !important;
  background-color: #69db8f !important;
  text-align:center;
  text-decoration:none;
  font-size:20px;
  font-weight:bold;
  cursor: pointer;
  vertical-align: sub;
  margin-left: 10px;
  margin-right: 10px;
}

.green-button > p {
  padding-left: 1px;
}

.profileWelcomeTop .care-round-button > p,
.profileTop .care-round-button > p {
  padding-left: 1.5px;
}

.inviteModal .ui.table {
  margin: 0 !important;
}

.inviteModal .ui.table .emailCol {
  min-width: 250px;
}

.inviteModal .ui.table .accessCol {
  min-width: 180px;
}

.accesscol.centered.row.emptyStateRow {
  margin-right:auto;
}

.UserDirectoryTable .inline.fields {
  margin: 0 !important;
}

.UserDirectoryTable .name-cell:hover {
  cursor: pointer;
}

.UserDirectoryTable .delete-container {
  color: #d9d9d9;
}

.UserDirectoryTable .delete-container:hover {
  cursor: pointer;
  color: #4a4a4a;
}

.UserDirectoryTable .delete-placeholder {
  color: transparent;
}

.UserDirectoryTable .delete-placeholder ::selection {
  color: transparent;
  background-color: transparent;
}

.UserDirectoryTable .delete-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.UserDirectoryTable .delete-text {
  text-align: center;
  margin-top: 0.2em;
}

.menu-item {
  color: #c8cbd3;
  background-color: #f5f5f5;
  padding: 5px;
  padding-right: 0.5em;
  border-radius: 20px;
  position: relative;
}

.menu-item .icon {
  color: #c8cbd3;
  margin: 0;
  background-color: #f7f7f7;
  border-radius: 50%;
  padding: 1px;
  height:22px;
  width: 22px;
  position: relative;
}

.menu-item i.icon::before {
  position: relative;
  align-content: center;
  top: 0.08em;
  left: 0.02em;
  font-size: 18px;
}

.profileMenu .active .menu-item .icon {
  color: #04a5d5;
}

.ui.fluid.multiple.selection.dropdown.role-dropdown > .icon{
  box-shadow: -1px 0px #a9adb4 !important;
  clip-path: inset(10% 0% 21% -10%) !important; 
  padding: 9px 5px 10px 5px!important;
}

.role-dropdown {
  min-width: 10em !important;
}
.overviewTitle .column {
  padding-left: 28px !important;
  padding-top: 15px !important;
}

.overviewTitle .column .header {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}

.overviewTitle .column p {
  margin-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #a9adb4;
}

.detailsBox .note p {
  font-size: 12px;
  color: #a9adb4;
  margin-top: 10px;
}

.profile  .ui.multiple.dropdown > a {
  font-size: 12px;
  font-weight: bold;
  color: #4A4A4A;
 }

 .profile  .ui.multiple.dropdown > .default.text {
  color: #9b9b9b !important;
  font-weight:normal !important;
  font-size: 12px !important;
  padding-top: 2px;
}

.profile  .ui.multiple.dropdown .menu > .item {
  font-size: 12px;
}

.phone-number-display {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-top: 18px !important;
}

.profile-e-learning-assignment-container {
  border-top: 1px solid #d9d9d9;
  padding-bottom: 25px;
}

.profile-e-learning-assignment-text {
  font-size: 12px;
  font-weight: normal;
  color: #4A4A4A;
  margin-left: 25px;
  margin-bottom: 25px;
  padding-top: 25px;
}

.profile-hide-progress-bar > .bar {
  background-color: transparent !important;
}

.profile-e-learning-course {
  padding: 1rem 0px !important;
  margin: 0px 1rem !important;
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: center;
}

.profile-e-learning-course-empty {
  padding: 1rem 0px !important;
  margin: 0px 1rem !important;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100px;
  align-items: center;
  justify-items: center;
}

.profile-e-learning-course-name {
  font-size: 12px;
  color: #4A4A4A;
  font-weight: bold;
  margin: 0 0 0 25px;
}

.profile-e-learning-course-status-completed,
.profile-e-learning-course-status-expired,
.profile-e-learning-course-status-pending,
.profile-e-learning-course-status-started,
.profile-e-learning-course-status-invisible {
  text-transform: capitalize;
  font-size: 10px;
  font-style: italic;
}

.profile-e-learning-course-status-completed {
  color: #69db8f;
}

.profile-e-learning-course-status-started {
  color: #04a5d5;
}

.profile-e-learning-course-status-pending {
  color: #d9d9d9;
}

.profile-e-learning-progress-container {
  width: 545px;
  margin: 0 0 15px 0 !important;
}

.profile-e-learning-progress-header {
  margin: 0 0 20px 31px;
  padding-top: 20px;
  display: inline-block;
  color: #364967;
  font-size: 14px;
}

.profile-e-learning-progress-launch-button {
  float: right;
  margin-top: 20px !important;
  margin-right: 31px !important;
  width: 103px !important;
  height: 20px !important;
  border-radius: 5px !important;
  font-size: 10px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  line-height: 14px !important;
  padding: 0 !important;
  color: #426B6F !important;
}

.profile-e-learning-progress-bra-container {
  width: 486px;
  margin-left: 25px;
  padding-top: 21px;
  box-shadow: -25px 0 0 #ffffff, -25px -1px 0 #d9d9d9, 25px 0 0 #ffffff, 25px -1px 0 #d9d9d9;
}

.ui.tiny.progress.profile-e-learning-progress-bar > .bar {
  background-color: #69db8f;
  height: 10px !important;
}

.ui.tiny.progress.profile-e-learning-progress-bar.hide-progress-bar > .bar {
  background-color: transparent;
}


.profile-e-learning-progress-count {
  text-align: center;
  font-style: italic;
  color: #ADB1B8;
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-top: 13px;
  padding-bottom: 21px;
}
.ui.padded.grid.detailsBox.profile {
  margin-bottom: 20px !important;
}